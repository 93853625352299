import React, { Component } from 'react';
import { Row, Col, Card, CardHeader, CardBody, Button , Container} from "shards-react";
import Firebase from 'firebase';
import { Link } from 'react-router-dom';
import { StaticRouter } from 'react-router';
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
class ShopsIndex extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            meatActive:false,
            fishActive:false,
            milkActive:false,
            drinksActive:false,
            breadActive:false,
            patatoActive:false,
            dryActive:false,
            iceActive:false,
            items:[],
            images:[],
            filterItem:'',
            overlaySave:false,
            errorForm:false
        };
    }

    componentDidMount(){
       
       
        window.scrollTo(0, 0)
       
        
    }

    sendForm(){
        this.setState({
            loader:true,
            sendingform:true
        })
        if(this.state.nameChanger != '' && this.state.mailChanger != '' && this.state.phoneChanger != ''){
        let headers = new Headers();
        headers.append('Content-Type', 'application/json');
        headers.append('Accept', 'application/json');
        headers.append('Origin','https://streekproducten-limburg.nl');
        //headers.append('Origin','https://frontend-test-bbo.web.app/')
        headers.append('Access-Control-Allow-Methods', "POST, GET, OPTIONS, DELETE, PUT")
        fetch("https://us-central1-web-app-bbo-prod.cloudfunctions.net/getAttention", {
        method: "POST",
        headers: headers,
        body: JSON.stringify({name:this.state.nameChanger,email:this.state.mailChanger,phone:this.state.phoneChanger})
      })
      .then(res => {
          if(res.status === 201){
        
            this.setState({
              nameChanger:'',
              mailChanger:'',
              phoneChanger:'',
              overlaySave:false,
              changeData:false,
              sendingform:false,
              errorForm:false
                
            })
          }
      });}
      else{
        this.setState({
          errorForm:true,
          sendingform:false
        })
      }
    }


    

    render() {

        return (
        <Container fluid>
            

            <Row className="participant-row">
                <Col sm={12}>
                    <Container>
                        <Col sm={12} className="participant-title">
                                <h2>Winkels</h2>
                                <hr/>
                                <p>Onderstaande bedrijven zijn inmiddels voorzien van een online webshop voor het verkorte van de keten richting de klanten. Samen met deze bedrijven wordt het intiatief van het programma LOF in leven gehouden en doorgezet in de praktijk.</p>
                        </Col>
                       {/*} <Col sm={12} className="filter">
                                <h4>Filter:</h4>
                                <Button onClick={()=>this.setState({ meatActive:true,fishActive:false,milkActive:false,drinksActive:false,breadActive:false,patatoActive:false,dryActive:false,iceActive:false,filterItem:'Vlees, wild gevogelte'})} className={this.state.meatActive ? 'active-category':null}>Vlees, wild & gevogelte</Button>
                                <Button onClick={()=>this.setState({ meatActive:false,fishActive:true,milkActive:false,drinksActive:false,breadActive:false,patatoActive:false,dryActive:false,iceActive:false,filterItem:'Vis'})} className={this.state.fishActive ? 'active-category':null}>Vis</Button>
                                <Button onClick={()=>this.setState({ meatActive:false,fishActive:false,milkActive:true,drinksActive:false,breadActive:false,patatoActive:false,dryActive:false,iceActive:false,filterItem:'Zuivel, kaas & eieren'})} className={this.state.milkActive ? 'active-category':null}>Zuivel, kaas & eieren</Button>
                                <Button onClick={()=>this.setState({ meatActive:false,fishActive:false,milkActive:false,drinksActive:true,breadActive:false,patatoActive:false,dryActive:false,iceActive:false,filterItem:'Dranken'})} className={this.state.drinksActive ? 'active-category':null}>Dranken</Button>
                                <Button onClick={()=>this.setState({ meatActive:false,fishActive:false,milkActive:false,drinksActive:false,breadActive:true,patatoActive:false,dryActive:false,iceActive:false,filterItem:'Brood & banket'})} className={this.state.breadActive ? 'active-category':null}>Boord & Banket</Button>
                                <Button onClick={()=>this.setState({ meatActive:false,fishActive:false,milkActive:false,drinksActive:false,breadActive:false,patatoActive:true,dryActive:false,iceActive:false,filterItem:'Aardappelen, groente, fruit'})} className={this.state.patatoActive ? 'active-category':null}>Aardappelen, Groente & fruit</Button>
                                <Button onClick={()=>this.setState({ meatActive:false,fishActive:false,milkActive:false,drinksActive:false,breadActive:false,patatoActive:false,dryActive:true,iceActive:false,filterItem:'Droogwaren'})} className={this.state.dryActive ? 'active-category':null}>Droogwaren</Button>
                                <Button onClick={()=>this.setState({ meatActive:false,fishActive:false,milkActive:false,drinksActive:false,breadActive:false,patatoActive:false,dryActive:false,iceActive:true,filterItem:'Ijs'})} className={this.state.iceActive ? 'active-category':null}>Ijs</Button>
        </Col>*/}

                        <Row>
                          
                                <Col sm={4} className="particioant-col">
                                <div className="participant">
                                    <img src={"https://firebasestorage.googleapis.com/v0/b/web-app-bbo-prod.appspot.com/o/Streekproductengids%2Fimages%2FInnofruit%2Finnofruit_1920x1080.jpg?alt=media&token=53ba5038-76a1-494a-8cf4-e9f880b529bc"} />
                                    <div className="participant-content">
                                        <i class="fas fa-map-marker-alt"></i> <span className="participant-location">Sint Geertruid</span>
                                    
                                    <span className="participant-category">Categorie: Aardappelen, groente, fruit</span>
                                    <h3>Innofruit</h3>
                                    <p>
                                        Innofruit is een specialist in de teelt en verwerking van fruit. Innofruit is een initiatief van Gerard Mertz en Gaston Curfs, opgericht in juli 2014. Gerard Mertz is een klein fru......
                                    </p>
                                    <a target="_blank" href="https://inno-fruit.beterbijons.nl/">Bekijk webshop</a>
                                    
                                    
                                    </div>
                                </div>

                            </Col>
                            <Col sm={4} className="particioant-col opacity">
                                <div className="participant">
                                    <img src={"https://firebasestorage.googleapis.com/v0/b/web-app-bbo-prod.appspot.com/o/Streekproductengids%2Fimages%2FVers%20van%20Mertz%2FNJMle9EGxw3PMertz-4_1920x1080.jpg?alt=media&token=107eff8e-635b-48b3-b4e8-369099442ed6"} />
                                    <div className="participant-content">
                                        <i class="fas fa-map-marker-alt"></i> <span className="participant-location">Sint Geertruid</span>
                                    
                                    <span className="participant-category">Categorie: Aardappelen, groente, fruit</span>
                                    <h3>Vers van Mertz</h3>
                                    <p>
                                    Vers van Mertz: uw vertrouwde leverancier voor het leveren van eigen geteelde groente en fruit. De basis van de verswinkel zoals we die nu kennen is gelegd in 2017. 
                                    </p>
                                    <a>Coming Soon</a>
                                    
                                    
                                    </div>
                                </div>

                            </Col>
                            <Col sm={4} className="particioant-col">
                                <div className="participant">
                                    <img src={"https://firebasestorage.googleapis.com/v0/b/web-app-bbo-prod.appspot.com/o/Streekproductengids%2Fimages%2FVers%20van%20Mertz%2FIllustratie-webshop-01_1920x1080.jpg?alt=media&token=a3f4b97f-4670-4af7-9b1e-ab317fa8f5b8"} />
                                    <div className="participant-content">
                                        {/*<i class="fas fa-map-marker-alt"></i> <span className="participant-location">Sint Geertruid</span>
                                    
    <span className="participant-category">Categorie: Aardappelen, groente, fruit</span>*/}
                                    <h3>Hier kan uw streekproductenwinkel staan?</h3>
                                    <p>
                                        Is een eigen streekproductenwinkel voor u interessant? Dan bieden wij u een laagdrempelig digitaal platform om uw streekproducten online aan te bieden.<br/><br/>
                                        Neem dan contact op via onderstaande knop.
                                    </p>
                                    <a onClick={()=>this.setState({overlaySave:true})}>Contact opnemen</a>
                                    
                                    
                                    </div>
                                </div>

                            </Col>
                      
                        </Row>
                        {this.state.overlaySave?
                <Container fluid className="save-changes">
            <Row className="overlay-row-home">
         <Col sm={12} className="overlay-col-home">
                    <h2>Laat je gegevens achter</h2>
                    <p>Als je interesse hebt om ook je producten eenvoudig online te verkopen laat dan hierbeneden je gegevens achter en wij nemen zo snel mogelijk contact met je op!</p>
                    <form>
                    <label>Naam</label><br/>
                    <input  required type="text" name="name" value={this.state.nameChanger} onChange={(event)=>this.setState({nameChanger: event.target.value,errorForm:false})}/>
                    <br/>  <label>E-mail</label><br/>
                    <input required type="text" name="mail" value={this.state.mailChanger} onChange={(event)=>this.setState({mailChanger: event.target.value,errorForm:false})}/>
                    <br/> <label>Telefoon</label><br/>
                    <input required type="text" name="phone" value={this.state.phoneChanger} onChange={(event)=>this.setState({phoneChanger: event.target.value,errorForm:false})}/>
                    </form>
                   {this.state.errorForm ? <span className="error-form">Let op! Alle velden zijn verplicht</span>:null}
                    <Button onClick={()=>this.setState({overlaySave:false})} className="close-button">Annuleren</Button>
                   {this.state.sendingform ? <Button className="save-button"><Loader className="send-form" type="ThreeDots" color="#fff" height={10} width={100} /*timeout={3000}*/  /> </Button>: <Button onClick={()=>this.sendForm()} className="save-button">Versturen</Button>}
               </Col>
               </Row>
               </Container>:null}
                    
                        
                    </Container>
                </Col>
            </Row>

            
        
            
        </Container>

        );
    }
};

export default ShopsIndex;
import React, { Component } from 'react';
import {Map, InfoWindow, Marker, GoogleApiWrapper} from 'google-maps-react';
 import IconBrood from '../../assets/images/streekproductengids/icons/markers/Brood.svg'
 import IconDranken from '../../assets/images/streekproductengids/icons/markers/Dranken.svg'
 import IconDroog from '../../assets/images/streekproductengids/icons/markers/Droogwaren.svg'
 import IconGroente from '../../assets/images/streekproductengids/icons/markers/Groente.svg'
 import IconIjs from '../../assets/images/streekproductengids/icons/markers/Ijs.svg'
 import IconVis from '../../assets/images/streekproductengids/icons/markers/Vis.svg'
 import IconVlees from '../../assets/images/streekproductengids/icons/markers/Vlees.svg'
 import IconZuivel from '../../assets/images/streekproductengids/icons/markers/Zuivel.svg'
import { useHistory } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { Redirect } from 'react-router-dom';

const containerStyle = { 
    width: '75%',
    height: '40em',
  }
export class MapContainer extends Component {
    constructor(props) {
        super(props);
   this.state = {
        redirect: false,
        chosenId:''
    }
}
    redirectHandler = (id) => {
        console.log('rediretHandler'+id)
        let chosenId= id;
        this.setState({ redirect: true,chosenId:id })
        this.renderRedirect();
    }
    renderRedirect = (id) => {
        console.log('renderRedirect'+id)
        if (this.state.redirect) {
            return <Redirect to={'/deelnemers/details?id='+this.state.chosenId} />
        }
    }

    checkIcon(cat){
      if(cat ==='Vlees, wild gevogelte'){
          return IconVlees
      }else if(cat ==='Vis'){
        return IconVis
      }
      else if(cat ==='Zuivel, kaas & eieren'){
        return IconZuivel
      }
      else if(cat ==='Dranken'){
        return IconDranken
      }
      else if(cat ==='Brood & banket'){
        return IconBrood
      }
      else if(cat ==='Aardappelen, groente, fruit'){
        return IconGroente
      }
      else if(cat ==='Droogwaren'){
        return IconDroog
      }
      else if(cat ==='Ijs'){
        return IconIjs
      }else{
        return IconGroente
      }
    }

  render() {
    return (
       this.state.redirect? this.renderRedirect():
      <Map 
      initialCenter={{
        lat: 50.987255499999996,
        lng: 5.7698054
      }}
      style={containerStyle} google={this.props.google} zoom={8}>
 
        <Marker onClick={()=>console.log('clieck')}
                name={'Current location'} />
                 <Marker onClick={()=>console.log('clieck')}
                name={'Current location'} />

                {this.props.data.filter((item)=> this.props.filter ?item.category === this.props.filter: item.category === item.category).map((item)=>(
                    <Marker
                        title={item.companyName}
                        name={item.companyName}
                        position={{lat: item.lat, lng: item.lng}} onClick={()=>this.redirectHandler(item.id)}
                        icon={{
                            url: this.checkIcon(item.category),
                            anchor: new this.props.google.maps.Point(10,10),
                            scaledSize:new this.props.google.maps.Size(50,50)
                        }}
                        />
                ))}


 
        <InfoWindow onClose={this.onInfoWindowClose}>
            <div>
              <h1>hello world</h1>
            </div>
        </InfoWindow>
      </Map>
     
    );
  }
}
 
export default GoogleApiWrapper({
  apiKey: ("AIzaSyB7G-vcpfhNmIdFl-RkodKDYsDY1VTQZV0")
})(MapContainer)
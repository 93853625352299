import React, { Component } from 'react';
import { Row, Col, Card, CardHeader, CardBody, Button } from "shards-react";
import Firebase from 'firebase';
import { Link } from 'react-router-dom';
import { keys } from '@material-ui/core/styles/createBreakpoints';
import { Redirect, useHistory } from 'react-router';



class SignOnComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            goverment: '',
            companyName:'',
            contactperson:'',
            phoneNumber:'',
            email:'',
            password:'',
            passwordCheck:'',
            agreement:false,
            userAgreement:false,
            processorAgreement:false,
            errorMessage:'',
            error:false,
            redirect:false,

        };
    }

  


    handleAgreement(event){
        if(this.state.agreement === true){
            this.setState({
                agreement: false
            })
        }else if(this.state.agreement === false){
            this.setState({
                agreement: true
            })
        }
    }

    handleUseAgreement(event){
        if(this.state.userAgreement === true){
            this.setState({
                userAgreement: false
            })
        }else if(this.state.userAgreement === false){
            this.setState({
                userAgreement: true,error:false, errorMessage:''
            })
        }
    }

    handleProseccorAgreement(event){
        if(this.state.processorAgreement === true){
            this.setState({
                processorAgreement: false,
                error:false, errorMessage:''
            })
        }else if(this.state.processorAgreement === false){
            this.setState({
                processorAgreement: true,
                error:false, errorMessage:''
            })
        }
    }

    startOnboarding(){

        
        if(this.state.goverment === ''){
            this.setState({
                error: true,
                errorMessage:'Selecteer een deelnemende gemeenten.'
            })

        }else if(this.state.companyName === ''){
            this.setState({
                error: true,
                errorMessage:'Vul uw bedrijfsnaam in.'
            })
        }else if(this.state.contactperson === ''){
            this.setState({
                error: true,
                errorMessage:'Er is geen contactpersoon ingevoerd.'
            })
        }
        else if(this.state.phoneNumber === ''){
            this.setState({
                error: true,
                errorMessage:'Er is geen telefoonnummer ingevoerd.'
            })
        }else if(this.state.email === ''){
            this.setState({
                error: true,
                errorMessage:'Er is geen email-adres ingevoerd.'
            })
        }else if(this.state.password === ''){
            this.setState({
                error: true,
                errorMessage:'Er is geen wachtwoord ingevoerd.'
            })
        }
        else if(this.state.password.length < 7){
            this.setState({
                error: true,
                errorMessage:'Het wachtwoord moet minimaal 7 karakters zijn.'
            })
        }else if(this.state.passwordCheck === ''){
            this.setState({
                error: true,
                errorMessage:'Er is geen herhaald wachtwoord ingevoerd.'
            })
        }else if(this.state.passwordCheck.length < 7){
            this.setState({
                error: true,
                errorMessage:'Het wachtwoord moet minimaal 7 karakters zijn.'
            })
        }else if( this.state.password != this.state.passwordCheck){
            this.setState({
                error: true,
                errorMessage:'De wachtwoorden komen niet overeen.',
                password:'',
                passwordCheck:''
            })
        }
        
        else if(this.state.agreement === false){
            this.setState({
                error: true,
                errorMessage:'Ga akkoord met de algemene voorwaarden.'
            })
        }else if(this.state.userAgreement === false){
            this.setState({
                error: true,
                errorMessage:'Ga akkoord met de gebruikers voorwaarden.'
            })
        }else if(this.state.processorAgreement === false){
            this.setState({
                error: true,
                errorMessage:'Ga akkoord met de verwerkersovereenkomst.'
            })
        }else{
            Firebase.auth().createUserWithEmailAndPassword(this.state.email, this.state.password).then((userCredential) => {
         
                var user = userCredential.user;
                console.log(user.uid)
                var key = Firebase.database().ref().child('/users/').push().key;
                Firebase.database().ref('/users/'+user.uid).set({
                    id: user.uid,
                    email: this.state.email,
                    city:this.state.goverment,
                    userUID: user.uid,
                    phone:this.state.phoneNumber,
                    name: this.state.contactperson,
                    companyName: this.state.companyName

            
                }).then((data)=>{
         
                    Firebase.database().ref('/cities/'+this.state.goverment+'/shops/'+user.uid).update({
                        id: user.uid,
                        email: this.state.email,
                        phone:this.state.phoneNumber,
                        userUID: user.uid,
                        name: this.state.contactperson,
                    companyName: this.state.companyName

    
                
                    }).then((data)=>{

                       
                        this.setState({redirect: true})
                       
                       
    
                    }).catch((error)=>{
                        //error callback
                        console.log('error ' , error)
                    })

                }).catch((error)=>{
                    //error callback
                    console.log('error ' , error)
                })
            }).catch((error) => {
                var errorCode = error.code;
                var errorMessage = error.message;

              });
             
        }
    }
    

    render() {
        return (
            this.state.redirect?

            <Redirect to='/aanmelden-compleet'/>

            :
            <div>
            <Row >
                <Col sm={2}></Col>

<Col lg="8" className="sign-on-col">
<img src={require("../../assets/images/logo.svg")}  className="logo-login"/>
    <h3>Beter bij ons</h3>
    {/**<h4>Q-sort</h4> */}
    <p>Leuk dat je deel wilt uitmaken van de online winkelstraat in jouw gemeente.</p>
    <p>Om van start te gaan hebben wij een aantal gegevens van jou nodig. Ga hieronder van start!</p>
    <h4>Selecteer deelnemende gemeente</h4>
    <select value={this.state.selectedGov} onChange={(event)=>this.setState({goverment: event.target.value,error:false, errorMessage:''})}>
        <option selected="selected" disabled="disabled">Selecteer een deelnemend dorp</option>
        <option value="-ifweobfwefbjjwelfn">Gemert , Noord-Brabant</option>
    </select>
    <h4>Algemeen</h4>
    <form /*onSubmit={}*/>
   

    <input style={{padding: 10, marginTop: 10,}} name="Bedrijfsnaam" type="name" placeholder="Bedrijfsnaam" value={this.state.companyName} onChange={(event)=>this.setState({companyName:event.target.value,error:false, errorMessage:''})}/>
    <input style={{padding: 10, marginTop: 10,}} name="contactpersoon" type="name" placeholder="Contactpersoon" value={this.state.contactperson} onChange={(event)=>this.setState({contactperson:event.target.value,error:false, errorMessage:''})}/>
    <input style={{padding: 10, marginTop: 10,}} name="Telefoonnummer" type="name" placeholder="Telefoonnummer" value={this.state.phoneNumber} onChange={(event)=>this.setState({phoneNumber:event.target.value,error:false, errorMessage:''})}/>
    <input style={{padding: 10, marginTop: 10,}} name="e-mail" type="name" placeholder="E-mail" value={this.state.email} onChange={(event)=>this.setState({email:event.target.value,error:false, errorMessage:''})}/>
    <input style={{padding: 10, marginTop: 10,}} name="wachtwoord" type="password" placeholder="Wachtwoord" value={this.state.password} onChange={(event)=>this.setState({password:event.target.value,error:false, errorMessage:''})}/>
    <input style={{padding: 10, marginTop: 10,}} name="wachtwoord" type="password" placeholder="Herhaal wachtwoord" value={this.state.passwordCheck}  onChange={(event)=>this.setState({passwordCheck:event.target.value,error:false, errorMessage:''})}/>

    <span className="input-check-span"><input className="input-check" checked={this.state.agreement} style={{padding: 10, marginTop: 10,}} name="KVK" type="checkbox" placeholder="KVK-nummer"  onChange={(event)=>this.handleAgreement(event.target.value)}/><br/><label className="input-check-label">Ik ga akkoord met de <a href="https://firebasestorage.googleapis.com/v0/b/web-app-bbo-prod.appspot.com/o/Documenten%2FAlgemene_Voorwaarden_Beter_Bij_Ons%20v%201.0%2024032021.pdf?alt=media&token=fbd4bdf2-79b6-49b4-bafb-d6d75424a649" target="_blank">algemene voorwaarden</a></label></span><br/>
    <span className="input-check-span"><input className="input-check" checked={this.state.userAgreement}  style={{padding: 10, marginTop: 10,}} name="KVK" type="checkbox" placeholder="KVK-nummer" onChange={(event)=>this.handleUseAgreement(event.target.value)}/><br/><label className="input-check-label">Ik ga akkoord met de <a href="https://firebasestorage.googleapis.com/v0/b/web-app-bbo-prod.appspot.com/o/Documenten%2FGebruikers_Voorwaarden_Beter_Bij_Ons%20v%201.0%20%2024032021.pdf?alt=media&token=cc1a8f17-3c5d-4821-8b75-f581e10acb07" target="_blank">gebruikers voorwaarden</a></label></span><br/>
    <span className="input-check-span"><input className="input-check" checked={this.state.processorAgreement}  style={{padding: 10, marginTop: 10,}} name="KVK" type="checkbox" placeholder="KVK-nummer" onChange={(event)=>this.handleProseccorAgreement(event.target.value)}/><br/><label className="input-check-label">Ik ga akkoord met de <a href="https://firebasestorage.googleapis.com/v0/b/web-app-bbo-prod.appspot.com/o/Documenten%2FVerwerkersovereenkomst_Beter_Bij_Ons.pdf?alt=media&token=f37b108e-f036-4d0e-99bb-a3b2470395a2" target="_blank">verwerkersovereenkomst</a></label></span><br/>
    
    <Button className="sing-on-button"  onClick={()=>this.startOnboarding()}>Aanmelden</Button>
    {this.state.error ?
    <div className="error-message">
        <p>{this.state.errorMessage}</p>
    </div>
    
:null}
     {/** <div className="loading-dots">
      <Loader type="ThreeDots" color="#00BFFF" height={50} width={50} timeout={10000}  />
</div>*/}
</form>
</Col>

</Row>
            </div>



        );
    }
};

export default SignOnComponent;
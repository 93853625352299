import React from "react";
import { Container, Row, Col } from "shards-react";

import PageTitle from "../../components/common/PageTitle";

import ChangeProductsComponent from "../../components/products/changeProduct";



const ChangeProductsView = (props) => (
  <Container fluid className="main-content-container px-4">
    <Row noGutters className="page-header py-4">
      <PageTitle title="Product aanpassen" subtitle="Beter bij ons" md="12" className="ml-sm-auto mr-sm-auto" />
    </Row>
    <ChangeProductsComponent  data={props.location.state} />
    
  </Container>
);

export default ChangeProductsView;

import React, { Component } from 'react';
import { Row, Col, Card, CardHeader, CardBody, Button , Container} from "shards-react";
import Firebase from 'firebase';
import { Link } from 'react-router-dom';
import { StaticRouter } from 'react-router';

class BlogDetailsIndex extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            meatActive:true,
            fishActive:false,
            milkActive:false,
            drinksActive:false,
            breadActive:false,
            patatoActive:false,
            dryActive:false,
            iceActive:false
        };
    }

componentDidMount(){
    window.scrollTo(0, 0)
}   

    render() {

        return (
        <Container fluid>
            

            <Row className="recepts-details-row">
                <Col sm={12} >
                <img src={'https://firebasestorage.googleapis.com/v0/b/web-app-bbo-prod.appspot.com/o/Streekproductengids%2Faan-het-woord.jpg?alt=media&token=5cb9a07d-e881-4926-a0be-740c06fa03b9'} />
                <span className="title-recepts-detail left-title">Aan ’t woord<br/><hr/></span>
                </Col>
            </Row>

            <Container className="recept-details-container">
                <Row>
                    <Col sm={8} className="recept-item-image">
                        <h2>Programmamanager LOF, Bert Vliex</h2>
                        <img src={'https://firebasestorage.googleapis.com/v0/b/web-app-bbo-prod.appspot.com/o/Streekproductengids%2Ffoto-bert.jpg?alt=media&token=a93de06e-11b0-46b8-a0d0-6bb259325994'} />
                    </Col>
                    
                    <Col sm={4} className="archive-item-sidebar">
                        <h5>Ook aan ’t woord</h5>
                        <ul>
                            <Link to={{pathname:'../aan-het-woord'}}><li>Lonneke Brouns, Brouwerij de Fontein</li></Link>
                            <Link to={{pathname:'../aan-het-woord/'}}><li>Paul Debey, Met fruit erop uit</li></Link>
                        </ul>
                    
        </Col>
                </Row>
                <Row>

                    <Col sm={12} className="recept-getting-ready green-title">
                         <h2>Doorkijk naar de toekomst</h2>
                         <p>
                         De voedselketens transparanter en korter maken door meer streekproducten te gebruiken en lokale producten een eerlijke prijs bieden, dat was het doel van het programma LOF. Een doel waar we mooie stappen in hebben gezet, maar we zijn er nog niet. De grootste uitdaging waar wij nu voor staan, is om te zorgen dat alles wat vanuit LOF is ontstaan ook na LOF wordt voortgezet.
                         <br/><br/>
 Verbinden en samenwerken zijn hier de centrale thema’s. Het is essentieel dat we van elkaar weten met welke initiatieven we bezig zijn. Dit zijn er talloze en niet altijd weten we van elkaars bestaan. 
 <br/><br/>
 Dat was de reden om op 23 juni 2021 deze website Streekproducten-Limburg.nl en de bijbehorende Streekproductengids te lanceren.
 <br/><br/>

 Een website en gids die het verhaal van onze Streekproducenten vertelt, Deelname is gratis. <br/>
 Wel zul je zelf de tijd moeten nemen om jouw bedrijf te presenteren. Download het formulier, vertel ons wie je bent, wat je doet en waarom je bijzonder bent. Voeg verder een representatieve foto toe en upload vervolgens je formulier. Dit wordt vervolgens opgenomen in de Streekproductengids en de website.
 <br/><br/>
 En voor degenen die hun producten willen verkopen bieden wij aanvullend de mogelijkheid om gebruik te maken van ons digitale platform tegen een bijzonder laag tarief. Informeer gerust naar de mogelijkheden.
  
 
                         </p>
                         <img className="blog-image" src={'https://firebasestorage.googleapis.com/v0/b/web-app-bbo-prod.appspot.com/o/Streekproductengids%2Fimage011.png?alt=media&token=94f411f7-6ed7-4495-a378-b2a39341f64f'}/>
                         <h3>Regionaal Voedselsysteem in Zuid-Limburg</h3>
                         <p>
                         Zo zie ik kansen voor de bestaande initiatieven van De Reusch, Plateau Hub en Kiempunt-Limburg binnen het programma “het Regionaal Voedselsysteem in Zuid-Limburg” van de Rabobank. De visie van dit programma voor 2025 sluit mooi aan bij hetgeen we vanuit LOF ook beogen: <i>“Voor de 7 grootste productstromen wordt een substantieel deel van de productie regionaal afgezet tegen een eerlijke prijs voor alle partijen in de keten. Daarbij worden zo veel mogelijk rest- en afvalstromen tussen ketens hergebruikt en is distributiewaar mogelijk duurzaam. Zuid-Limburgers, jong & oud en toeristen weten en waarderen dit en herkennen de streekproducten in de winkel, webshop, groothandel, foodhal en markt!”</i>
                        </p>
                        <h3>Groene long Zuid-Limburg</h3>
                         <p>
                         Kijk ook naar de ontwikkelingen in het NOVI-gebied Zuid-Limburg. Ga hier vooral ook een actieve verbinding/ samenwerking mee aan. Een van de speerpunten van NOVI in Zuid-Limburg is bijvoorbeeld samenwerken aan een grensoverschrijdende innovatie en circulaire economie én de ambitie om het Nationaal Landschap te versterken als groene long voor Zuid-Limburg. Ook binnen NOVI is er dus volop aandacht voor de ontwikkeling van een duurzame voedsel- en agroproductie.
                        </p>
                        <h3>Zet MVI op de kaart</h3>
                         <p>
                         En tot slot; heb aandacht voor de inbedding van een en ander in de internationale duurzaamheidsdoelstellingen. Zorg dat aanbestedende diensten innovatief gaan inkopen, zorg dat we in onze regio Maatschappelijk Verantwoord Inkopen op de kaart zetten.
 <br/><br/>
We zijn er klaar voor, laten we deze transitie samen realiseren!

                        </p>
                    </Col>
                    <Col sm={12} className="archive-item-sidebar-mobile">
                        <h5>Ook aan ’t woord</h5>
                        <ul>
                            <Link to={{pathname:'../aan-het-woord/bert-vliex'}}><li>Bert Vliex, Programmamanager LOF</li></Link>
                            <Link to={{pathname:'../aan-het-woord/'}}><li>Paul Debey, Met fruit erop uit</li></Link>
                        </ul>
                    
        </Col>

                </Row>
            </Container>

            
        
            
        </Container>

        );
    }
};

export default BlogDetailsIndex;
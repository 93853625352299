import React, { Component } from 'react';
import { Row, Col, Card, CardHeader, CardBody, Button } from "shards-react";
import Firebase from 'firebase';
import toast, { Toaster } from 'react-hot-toast';


class signupForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            companyName:'',
            companyAdres:'',
            companyPostal:'',
            companyContact:'',
            companyFunction:'',
            companyPhone:'',
            companyMail:'',
            companySite:'',
            companyFb:'',
            companyIg:'',
            companyTw:'',
            companyLi:'',
            mvo:'',
            desc:'',
            product:'',
            price:'',
            period:'',
            employees:'',
            promo:'',
            order:'',
            delivery:'',
            extraInfo:'',
            imageCompanyLogo:'',
            images:[],
            imageSecundaire:'',
            imagesSecundaireTwo:[],
            imageAvailableSec:false,
            imageAvailablePrimaire:false,
            imagePrimaireSet:'',
            imageAvailableCompany:false,
            imagesCompany:'',
            changes:true,
            imagesSecundaireNames:[],
            imagesPrimaireName:'',
            imagesCompanyName:'',
            showuploadSec:true,
            showuploadPri:true,
    showuploadlog:true,
    companynameisset:false,
    category:'Selected'


        };
    }

    



    componentDidMount() {
 

        
    }
    makeid(length) {
        var result           = '';
        var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        var charactersLength = characters.length;
        for ( var i = 0; i < length; i++ ) {
           result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
     }

    handleChangeCompanyLogo(e){
 
        this.setState({
         imageCompanyLogo: e.target.files[0],
          errorMessageLogo:''
        })
    
    }
    handleUploadCompanyLogo(){
        const uniqueId =this.makeid(12);
      const {imageCompanyLogo} = this.state;
      this.setState({
        uploaded:true
      })



      if(imageCompanyLogo != null){
          const uploadTask = Firebase.storage().ref(`Streekproductengids/images/${this.state.companyName}/${uniqueId}${imageCompanyLogo.name}`).put(imageCompanyLogo);
          uploadTask.on(
            'state_changed',
            snapshot => {
            },
            error => {
              console.log(error);
            },
            () => {
              Firebase.storage()
                .ref('Streekproductengids/images/'+this.state.companyName+'/')
                .child(uniqueId+imageCompanyLogo.name)
                .getDownloadURL()
                .then(url => {
                  toast.success('Afbeelding opgeslagen');
                    
                  this.setState(prevState =>({
                    imagesCompany:url,
                    imagesCompanyName:uniqueId+imageCompanyLogo.name,
                    imageAvailableCompany:true,
                    uploading: true,
                    changes:true,
                    showuploadlog:false
                  }))
                });
            }
          );
        }else{
        this.setState({
          errorMessageLogo: 'Er is geen foto geselecteert.',
          uploaded: false,
          uploading: false
        })
        
      }
  
      
    }

    handleChangeCompanyPrimaire(e){
  
        this.setState({
         imagePrimaire: e.target.files[0],
          errorMessageLogo:''
        })
    
    }
    handleUploadCompanyPrimaire(){
        const uniqueId =this.makeid(12);
      const {imagePrimaire} = this.state;
      this.setState({
        uploaded:true
      })



      if(imagePrimaire != null){
          const uploadTask = Firebase.storage().ref(`Streekproductengids/images/${this.state.companyName}/${uniqueId}${imagePrimaire.name}`).put(imagePrimaire);
          uploadTask.on(
            'state_changed',
            snapshot => {
            },
            error => {
              console.log(error);
            },
            () => {
              Firebase.storage()
                .ref('Streekproductengids/images/'+this.state.companyName+'/')
                .child(uniqueId+imagePrimaire.name)
                .getDownloadURL()
                .then(url => {
                  toast.success('Afbeelding opgeslagen');
                    
                  this.setState(prevState =>({
                    imagePrimaireSet: url,
                    imagesPrimaireName:uniqueId+imagePrimaire.name,
                    imageAvailablePrimaire:true,
                    showuploadPri:false
                  }))
                });
            }
          );
        }else{
        this.setState({
          errorMessageLogo: 'Er is geen foto geselecteert.',
          uploaded: false,
          uploading: false
        })
        
      }
  
      
    }

    handleChangeSecundaire(e){

     
            this.setState({
                imageSecundaire: e.target.files[0],
                 errorMessageLogo:''
               })


      
    }
    handleUploadSecundaire(){
        const uniqueId =this.makeid(12);
      const {imageSecundaire} = this.state;
      this.setState({
        uploaded:true
      })



      if(imageSecundaire != null){
          const uploadTask = Firebase.storage().ref(`Streekproductengids/images/${this.state.companyName}/${uniqueId}${imageSecundaire.name}`).put(imageSecundaire);
          uploadTask.on(
            'state_changed',
            snapshot => {
            },
            error => {
              console.log(error);
            },
            () => {
              Firebase.storage()
                .ref('Streekproductengids/images/'+this.state.companyName+'/')
                .child(uniqueId+imageSecundaire.name)
                .getDownloadURL()
                .then(url => {
                  toast.success('Afbeelding opgeslagen');
                    
                  this.setState(prevState =>({
                    imagesSecundaireTwo: [...prevState.imagesSecundaireTwo,url],
                    imagesSecundaireNames:[...prevState.imagesSecundaireNames,uniqueId+imageSecundaire.name],
                    imageAvailableSec:true,
                  }))

                  if((this.state.imagesSecundaireNames.length+1) >2){
                    this.setState({
                        showuploadSec:false
                    })
                  }
                });
            }
          );
        }else{
        this.setState({
          errorMessageLogo: 'Er is geen foto geselecteert.',
          uploaded: false,
          uploading: false
        })
        
      }
  
      
    }



    saveItem(){
        if(this.state.productName != '' ){
            this.setState({
                changes:false
            })
        var key = Firebase.database().ref().child('/streekproducten/').push().key;
            Firebase.database().ref('/streekproducten/'+key).set({
                id: key,
                companyName: this.state.companyName,
                companyAdres:this.state.companyAdres,
                companyPostal:this.state.companyPostal,
                companyContact:this.state.companyContact,
                companyFunction:this.state.companyFunction,
                companyPhone:this.state.companyPhone,
                companyMail:this.state.companyMail,
                companySite:this.state.companySite,
                companyFb:this.state.companyFb,
                companyIg:this.state.companyIg,
                companyTw:this.state.companyTw,
                companyLi:this.state.companyLi,
                mvo:this.state.mvo,
                desc:this.state.desc,
                product:this.state.product,
                price:this.state.price,
                period:this.state.period,
                employees:this.state.employees,
                promo:this.state.promo,
                order:this.state.order,
                delivery:this.state.delivery,
                extraInfo:this.state.extraInfo,
                category:this.state.category,

     
                imagesPrimaireName:this.state.imagesPrimaireName,
                imagesCompanyName:this.state.imagesCompanyName

            }).then((data)=>{


                for(let m = 0; m< this.state.imagesSecundaireNames.length; m++){
                    var keyImg= Firebase.database().ref().child('/streekproducten/'+key+'/secundaireImages').push().key;
                    Firebase.database().ref('/streekproducten/'+key+'/secundaireImages/'+keyImg).set({

                        id: keyImg,
                        name: this.state.imagesSecundaireNames[m]
                    }).then((data)=>{
                    
            
                    }).catch((error)=>{
                        //error callback
                        console.log('error ' , error)
                    })
                }

                


   

                toast.success('Item toegevoegd');
               
    
            }).then((data)=>{
this.setState({
    companyName: '',
    companyAdres:'',
    companyPostal:'',
    companyContact:'',
    companyFunction:'',
    companyPhone:'',
    companyMail:'',
    companySite:'',
    companyFb:'',
    companyIg:'',
    companyTw:'',
    companyLi:'',
    mvo:'',
    desc:'',
    product:'',
    price:'',
    period:'',
    employees:'',
    promo:'',
    order:'',
    delivery:'',
    extraInfo:'',
    imagesSecundaireNames:[],
    imagesPrimaireName:'',
    imagesCompanyName:'',
    imageAvailableSec:false,
    imageAvailablePrimaire:false,
    imageAvailableCompany:false,
    showuploadSec:true,
    showuploadPri:true,
    showuploadlog:true,



            })
                
            }).then(()=>{
                this.setState({
                    changes: false
                })
            }).catch((error)=>{
                //error callback
                console.log('error ' , error)
            })
        }else{
            this.setState({
                error: true,
                errorMessage:'Controlleer de productnaam.'
            })
        }
    }

    
    deleteImage(){
        this.setState({
            imagePrimaireSet: '',
            imageAvailablePrimaire:false,
            showuploadPri:true
        })
    }


    deleteImageCompany(){
        this.setState({
            imagesCompany: '',
            imageAvailableCompany:false,
            showuploadlog:true
        })
    }

    deleteImageSecundaires(url){
        this.setState({
            imagesSecundaireTwo: this.state.imagesSecundaireTwo.filter((item)=> item !== url),
        })
        if(this.state.imagesSecundaireNames.length<3){
            this.setState({
                showuploadSec:true
            })
        }
    }



    

    render() {
        return (
            <div>
                        <Toaster/>
            <Row className="col-sm-12">
  
                <Col sm={12}  className="pdfviewer">
                    <span className="fill-in-form">
                        <label>Naam van het bedrijf</label><br/>
                        <input required type="text" value={this.state.companyName} onChange={(event)=>this.setState({companyName:event.target.value,changes:true,companynameisset:true})}/><br/><br/>
                    </span>
                    <span className="fill-in-form">
                        <label>Adres van het bedrijf</label><br/>
                        <input required type="text" value={this.state.companyAdres} onChange={(event)=>this.setState({companyAdres:event.target.value,changes:true})}/><br/><br/>
                    </span>
                    <span className="fill-in-form">
                        <label>Postcode van het bedrijf</label><br/>
                        <input required type="text" value={this.state.companyPostal} onChange={(event)=>this.setState({companyPostal:event.target.value,changes:true})}/><br/><br/>
                    </span>
                    <span className="fill-in-form">
                        <label>Contactpersoon van het bedrijf</label><br/>
                        <input required type="text" value={this.state.companyContact} onChange={(event)=>this.setState({companyContact:event.target.value,changes:true})}/><br/><br/>
                    </span>
                    <span className="fill-in-form">
                        <label>Function van contactpersoon in het bedrijf</label><br/>
                        <input required type="text" value={this.state.companyFunction} onChange={(event)=>this.setState({companyFunction:event.target.value,changes:true})}/><br/><br/>
                    </span>
                    <span className="fill-in-form">
                        <label>Telefoonnummer</label><br/>
                        <input required type="text" value={this.state.companyPhone} onChange={(event)=>this.setState({companyPhone:event.target.value,changes:true})}/><br/><br/>
                    </span>
                    <span className="fill-in-form">
                        <label>E-mail</label><br/>
                        <input required type="text" value={this.state.companyMail} onChange={(event)=>this.setState({companyMail:event.target.value,changes:true})}/><br/><br/>
                    </span>
                    <span className="fill-in-form">
                        <label>Website</label><br/>
                        <input required type="text" value={this.state.companySite} onChange={(event)=>this.setState({companySite:event.target.value,changes:true})}/><br/><br/>
                    </span>
                    <span className="fill-in-form">
                        <label>Categorie</label><br/>
                        <select  onChange={(event)=>this.setState({category: event.target.value,changes:true})}>
                            <option selected="selected" disabled="disabled">Selecteer een categorie</option>
                                <option value="Vlees, wild gevogelte">Vlees, wild gevogelte</option>
                                <option value="Vis">Vis</option>
                                <option value="Zuivel, kaas & eieren">Zuivel, kaas & eieren</option>
                                <option value="Dranken">Dranken</option>
                                <option value="Brood & banket">Brood & banket</option>
                                <option value="Aardappelen, groente, fruit">Aardappelen, groente, fruit</option>
                                <option value="Droogwaren">Droogwaren</option>
                                <option value="Ijs">Ijs</option>
                             
                            </select><br/><br/>
                    </span>
                    <span className="fill-in-form">
                        <label>Social media</label><br/>
                        <input placeholder="Facebook" type="text" value={this.state.companyFb} onChange={(event)=>this.setState({companyFb:event.target.value,changes:true})}/><br/><br/>
                        <input placeholder="Instagram" type="text" value={this.state.companyIg} onChange={(event)=>this.setState({companyIg:event.target.value,changes:true})}/><br/><br/>
                        <input placeholder="Twitter" type="text" value={this.state.companyTw} onChange={(event)=>this.setState({companyTw:event.target.value,changes:true})}/><br/><br/>
                        <input placeholder="Linkedin" type="text" value={this.state.companyLi} onChange={(event)=>this.setState({companyLi:event.target.value,changes:true})}/><br/><br/>
                    </span>
                    <span className="fill-in-form">
                        <label>MVO / Duurzaamheid</label><br/>
                        <input type="text" value={this.state.mvo} onChange={(event)=>this.setState({mvo:event.target.value,changes:true})}/><br/><br/>
                    </span>
                    <span className="fill-in-form">
                        <label>Beschrijving van het bedrijf</label><br/>
                        <input type="text" value={this.state.desc} onChange={(event)=>this.setState({desc:event.target.value,changes:true})}/><br/><br/>
                    </span>
                    <span className="fill-in-form">
                        <label>Product</label><br/>
                        <input type="text" value={this.state.product} onChange={(event)=>this.setState({product:event.target.value,changes:true})}/><br/><br/>
                    </span>
                    <span className="fill-in-form">
                        <label>Prijs</label><br/>
                        <input type="text" value={this.state.price} onChange={(event)=>this.setState({price:event.target.value,changes:true})}/><br/><br/>
                    </span>
                    <span className="fill-in-form">
                        <label>Periode</label><br/>
                        <input type="text" value={this.state.period} onChange={(event)=>this.setState({period:event.target.value,changes:true})}/><br/><br/>
                    </span>
                    <span className="fill-in-form">
                        <label>Personeel</label><br/>
                        <input type="text" value={this.state.employees} onChange={(event)=>this.setState({employees:event.target.value,changes:true})}/><br/><br/>
                    </span>
                    <span className="fill-in-form">
                        <label>Promotie</label><br/>
                        <input type="text" value={this.state.promo} onChange={(event)=>this.setState({promo:event.target.value,changes:true})}/><br/><br/>
                    </span>
                    <span className="fill-in-form">
                        <label>Bestellen</label><br/>
                        <input type="text" value={this.state.order} onChange={(event)=>this.setState({order:event.target.value,changes:true})}/><br/><br/>
                    </span>
                    <span className="fill-in-form">
                        <label>Levering</label><br/>
                        <input type="text" value={this.state.delivery} onChange={(event)=>this.setState({delivery:event.target.value,changes:true})}/><br/><br/>
                    </span>
                    <span className="fill-in-form">
                        <label>Extra Informatie</label><br/>
                        <input type="text" value={this.state.extraInfo} onChange={(event)=>this.setState({extraInfo:event.target.value,changes:true})}/><br/><br/>
                    </span>
                    {this.state.companynameisset ?<span>
                        <label>Bedrijfslogo (max 1.)</label><br/>
                        {this.state.showuploadlog?<span>
                        <input type="file" accept="image/gif, image/jpeg, image/png" onChange={(event) => this.handleChangeCompanyLogo(event)} /><Button className="add-button-settings" onClick={()=>this.handleUploadCompanyLogo()}>Voeg afbeelding toe</Button> <br/><br/></span>:null}
                        <label>Geselecteerde bedrijfslogo</label>
                            {this.state.imageAvailableCompany ?
                             <Row>
                   
                                <Col sm={4} className="product-images">
                                <span className="delete-image button-list button-onimage" >
                                    <Button onClick={()=>this.deleteImageCompany(this.state.imagesCompany)}>Verwijder</Button>
                                </span>
                                <img src={this.state.imagesCompany}/>
                            </Col>
                           
                            </Row>
                            :
                            <Row>
                                <Col sm={12} className="nothing-present">
                                    <span>Geen bedrijfslogo geselecteert</span>
                                </Col>
                            </Row>
                            }
                    
<br/><br/><br/>                    
                        <label>Primaire foto (max 1.)</label><br/>
                        {this.state.showuploadPri?<span>
                        <input type="file" accept="image/gif, image/jpeg, image/png" onChange={(event) => this.handleChangeCompanyPrimaire(event)} /><Button className="add-button-settings" onClick={()=>this.handleUploadCompanyPrimaire()}>Voeg afbeelding toe</Button> <br/><br/></span>:null}
                        <label>Geselecteerde product foto</label>
                            {this.state.imageAvailablePrimaire ?
                             <Row>
                               
                                <Col sm={4} className="product-images">
                                <span className="delete-image button-list button-onimage" >
                                    <Button onClick={()=>this.deleteImage(this.state.imagePrimaireSet)}>Verwijder</Button>
                                </span>
                                <img src={this.state.imagePrimaireSet}/>
                            </Col>
                            
                            </Row>
                            :
                            <Row>
                                <Col sm={12} className="nothing-present">
                                    <span>Geen afbeeldingen geselecteert</span>
                                </Col>
                            </Row>
                            }
            <br/><br/><br/>   
                        <label>Secundaire foto's (max 2.)</label><br/>
                        {this.state.showuploadSec?
                       <span> <input type="file" accept="image/gif, image/jpeg, image/png" onChange={(event) => this.handleChangeSecundaire(event)} /><Button className="add-button-settings" onClick={()=>this.handleUploadSecundaire()}>Voeg afbeelding toe</Button> <br/><br/></span>:null}
                        <label>Geselecteerde product foto's</label>
                            {this.state.imageAvailableSec ?
                             <Row>
                                 {this.state.imagesSecundaireTwo.map((image)=>(
                                <Col sm={4} className="product-images">
                                <span className="delete-image button-list button-onimage" >
                                    <Button onClick={()=>this.deleteImageSecundaires(image)}>Verwijder</Button>
                                </span>
                                <img src={image}/>
                            </Col>
                                ))}
                            </Row>
                            :
                            <Row>
                                <Col sm={12} className="nothing-present">
                                    <span>Geen foto's geselecteert</span>
                                </Col>
                            </Row>
                            }
                            <br/><br/><br/>   
         
                    <Button  className="changed-button" onClick={()=>this.saveItem()}>Opslaan</Button>
                    <br/><br/><br/>   
                    </span>:null}
                </Col>
   
            </Row>

            </div>



        );
    }
};

export default signupForm;
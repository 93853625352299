import React, { Component } from 'react';
import { Row, Col, Card, CardHeader, CardBody, Button } from "shards-react";
import Firebase from 'firebase';
import { Link } from 'react-router-dom';


class OrdersIndex extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            clientUUID:'-ohlnfwebk',
            orders:[]
            
        };
    }

    getOrders(){
    
        let ref = Firebase.database().ref('/cities/-onefoefw/shops/'+Firebase.auth().currentUser.uid+'/orders');
        ref.on('value' , snapshot => {
           
            snapshot.forEach((childSnap) => {
            let state = childSnap.val();
            console.log("resources:"+state);
          
                this.setState(prevState => ({
                    orders: [...prevState.orders, state]
                }))  
            })
        });

    }



    componentDidMount() {
        this.setState({
            clientUUID: Firebase.auth().currentUser.uid
        })
        this.getOrders();
    }

    

    render() {
        return (
            <div>
            <Row className="col-sm-12">
                {/*<Col sm={12} className="products-actions">
                    <Link>Product bewerken</Link>
                    <Link>Meer acties</Link>
                    <Link className="align-button-right" to={{pathname: `/producten/toevoegen`}} >Product toevoegen</Link>
        </Col>*/}
                <Col sm={12} className="products-table">
                
            <table>
                <tr>
                    <th>Order nr</th>
                    <th>Klant naam</th>
                    <th>Status</th>
                    <th>Betaald</th>
                    <th>Actie</th>
                </tr>

                {this.state.orders.map((order)=>(
                    <tr >
                        <td>{order.orderNr}</td>
                        <td>{order.clientName}</td>
                        <td>{order.status}</td>
                        <td>{order.payed ? <i class="fas fa-check-circle"></i>: <i class="fas fa-times-circle"></i>}</td>
                        <td><Button>Download factuur</Button></td>
                    </tr>
                ))}
                
                
            </table>
</Col>
   
            </Row>
            </div>



        );
    }
};

export default OrdersIndex;
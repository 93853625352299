import React, { Component } from 'react';
import { Row, Col, Card, CardHeader, CardBody, Button } from "shards-react";
import Firebase from 'firebase';
import { Link } from 'react-router-dom';
import { keys } from '@material-ui/core/styles/createBreakpoints';


class SignOnCompleteComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }
    

    render() {
        return (
            <div>
            <Row >
                <Col sm={2}></Col>

<Col lg="8" className="sign-on-col complete-row">
<img src={require("../../assets/images/logo.svg")}  className="logo-login"/>
    <h3>Aanmelden gelukt!</h3>
    {/**<h4>Q-sort</h4> */}
    <p>Je account is gereed om aan de slag te gaan met het vullen van jouw online winkel.</p>
    <br/><br/>
    <Link className="sign-on-button" to={{pathname: `/inloggen`}} >Ga naar inloggen</Link>
</Col>

</Row>
            </div>



        );
    }
};

export default SignOnCompleteComponent;
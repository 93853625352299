import React, { Component } from 'react';
import { Row, Col, Card, CardHeader, CardBody, Button , Container} from "shards-react";
import Firebase from 'firebase';
import { Link } from 'react-router-dom';
import { StaticRouter } from 'react-router';

class BlogDetailsthreeIndex extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            meatActive:true,
            fishActive:false,
            milkActive:false,
            drinksActive:false,
            breadActive:false,
            patatoActive:false,
            dryActive:false,
            iceActive:false
        };
    }

componentDidMount(){
    window.scrollTo(0, 0)
}   

    render() {

        return (
        <Container fluid>
            

            <Row className="recepts-details-row">
                <Col sm={12} >
                <img src={require('../../assets/images/streekproductengids/aan-het-woord.jpg')} />
                <span className="title-recepts-detail left-title">Aan ’t woord<br/><hr/></span>
                </Col>
            </Row>

            <Container className="recept-details-container">
                <Row>
                    <Col sm={8} className="recept-item-image">
                        <h2>Met Fruit Erop Uit</h2>
                        <h3><i>Een coöperatie in een snel veranderende omgeving.</i></h3>
                        <img src={require('../../assets/images/fruit-erop-uit-3.png')} />
                    </Col>
                    
                    <Col sm={4} className="archive-item-sidebar">
                        <h5>Ook aan ’t woord</h5>
                        <ul>
                            <Link to={{pathname:'../aan-het-woord/bert-vliex'}}><li>Bert Vliex, Programmamanager LOF</li></Link>
                            <Link to={{pathname:'../aan-het-woord/brouwer-de-fontein'}}><li>Lonneke Brouns, Brouwerij de Fontein</li></Link>

                        </ul>
                    
        </Col>
                </Row>
                <Row>

                    <Col sm={12} className="recept-getting-ready green-title">
                         <h2> Met Fruit Erop Uit</h2>
                         <p>
                       
Het ideaal van duurzaamheid wint in rap tempo terrein. Consumenten worden kritischer op hun leefomgeving en op hun bestedingspatroon . 65+ers zijn gezonder en kapitaalkrachtiger
dan ooit en hebben een stem in het kapittel. Gezonde voeding uit eigen bodem is een ‘flagship’ , dat steeds meer de wind in de zeilen krijgt.
                        <br/>  <br/>
                         Actueel is de dynamiek van het individu als tegengewicht t.o.v. de anonimiteit van regelgevende overheden.
                         <br/>  <br/>
                         We zien in het Nationaal Landschap Zuid-Limburg en de omgevende Euregio de ruimtedruk fors toenemen.
                         <br/>  <br/>
                         Verstening en grootschalige landbouw breiden zich uit. Herverkaveling, ruilverkaveling en landinrichtingsprojecten ruimen het landschap in gestaag tempo op. De spanning tussen partijen neemt onderhuids toe.
                         </p>
                       
                         </Col>
                         <Col sm={12} className="green-title">
                         <h3>Veenbrand</h3>
                         <p>
                         
Daarnaast tekent zich een klimaatprobleem af, dat o.a. laagstam-fruittelers inmiddels ertoe aanzet hun percelen massaal te rooien. De grootschalige akkerbouw neemt de gronden over.

                         </p>

                      
                         </Col>
                         <Col sm={12} className="green-title">
                         <h3>Weg bloesem</h3>
                         <p>
                         
                         De agrarische sector in een kleinschalig landschap zal zich op enig moment moeten beraden op een bedrijfsvoering, die past bij de staat en de structuur van het landschap. De stikstofproblematiek dwingt verandering af.
Recreatieve en toeristische ondernemers zullen tegelijk een stap moeten zetten om hun bedrijven en inkomsten voor de toekomst te waarborgen.<br/>
De maatvoering is van belang. De kwaliteit is doorslaggevend.<br/>  <br/>
In dit spanningsveld is het de kunst om positief en constructief te ondernemen.

                         </p>

                      
                         </Col>
                         <Col sm={12} className="green-title">
                         <h3>De uitdaging</h3>
                         <p>
                         
                         MFEU is een coöperatie met een 100-tal leden in de regio Eijsden-Margraten, die zich als doel stelt haar leden met elkaar te verbinden, zodat kennis en ondernemerschap gebundeld worden en aansluiten op de actualiteit met nieuwe concepten en nieuwe partnerships.
<br/>  <br/>Met het oog op het ontwikkelen van nieuwe , bij de tijd passende verdienmodellen focussen wij momenteel o.a. op samenwerking tussen ( cateraars van ) Campussen en MKB/MFEU , waaronder ‘green offices’ Zuyd Hogeschool & Universiteit Maastricht als ‘pilot‘
<br/>  <br/>In een vervolgtraject zullen wij dit concept uitwaaieren naar cateraars van gemeentes en ABP, DSM en in overleg met onze leden offertes uitbrengen. In dit kader actueel: Projectbureau MFEU coördineerde de levering van 3700 stuks handfruit ten behoeve van de start van het campusjaar Zuyd Hogeschool . Een viertal fruittelers leverden als lid MFEU appels en peren op 20 en 23 september j.l. op 5 locaties in Maastricht, Heerlen en Sittard.

                         </p>

                      
                         </Col>
                         <Col sm={12}>
                         <img className="blog-image-full" src={require('../../assets/images/fruit-erop-uit-1.png')}/>
                         </Col>
                         <Col sm={12}  className="green-title">
<p>Gaandeweg ontwikkelen wij zodoende MFEU als ‘bedrijf’ en als ‘merk’. Het projectbureau Bestuur staat voor de PR en coördineert business aan alle leden. Om onze kansen goed in te kunnen schatten, overwegen wij een marktonderzoek om te bezien hoe wij de diverse marktsegmenten van ons gehele ledenbestand op termijn in beeld kunnen brengen.</p>
                            </Col>
                         <Col sm={12} className=" green-title">
                         <h3>Participatie</h3>
                         <p>
                         Van groot belang is onze samenwerking met partnerorganisaties in de regio met betrekking tot de ” korte-keten-technologie”. Samenwerking met o.a. het burgerinitiatief “ Korte keten. Zeker (W)eten “ in de gemeente Eijsden-Margraten ligt daarom voor de hand.
Ook het uitventen van producten van onze food-leden via de streekproducten-catalogus “Beter bij ons” past in deze ambitie.


                         </p>
                        
                         
                    </Col>
                    <Col sm={12}>
                         <img className="blog-image-full " src={require('../../assets/images/fruit-erop-uit-2.png')}/>
                         </Col>
                         <Col sm={12}  className="green-title">
<p>Tevens droegen wij de coöperatie MFEU voor als ambassadeur Cittaslow om aansluitend te participeren in het Cittaslow-netwerk. Ook heeft het bestuur MFEU de
procedure opgestart voor het aanvragen van een subsidie via
het programma LEADER Zuid -Limburg.</p>
                            </Col>
                            
                            <Col sm={6}>
                         <img className="blog-image-full " src={require('../../assets/images/fruit-erop-uit-3.png')}/>
                         </Col>
                         <Col sm={6}  className="green-title">
<p>MFEU<br/>
Vz Bestuur<br/>
 Paul Debey , PhD<br/>
paul@metfruiterop.nl <br/> paul.debey@teledisnet.be <br/>06 55 87 88 46 <br/><a href="mailto:info@metfruiteropuit.nl">info@metfruiteropuit.nl</a></p>
                            </Col>
                       

                    <Col sm={12} className="archive-item-sidebar-mobile">
                        <h5>Ook aan ’t woord</h5>
                        <ul>
                            <Link to={{pathname:'../aan-het-woord/bert-vliex'}}><li>Bert Vliex, Programmamanager LOF</li></Link>
                            <Link to={{pathname:'../aan-het-woord/brouwer-de-fontein'}}><li>Lonneke Brouns, Brouwerij de Fontein</li></Link>

                        </ul>
                    
        </Col>

                </Row>
            </Container>

            
        
            
        </Container>

        );
    }
};

export default BlogDetailsthreeIndex;
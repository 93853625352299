import React, { Component } from 'react';
import { Row, Col, Card, CardHeader, CardBody, Button , Container} from "shards-react";
import Firebase from 'firebase';
import { Link } from 'react-router-dom';
import { StaticRouter } from 'react-router';
import ReactMapboxGl, { Layer, Feature } from 'react-mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import MapContainer from './MapContainer';
import { Redirect } from 'react-router-dom';
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import toast, { Toaster } from 'react-hot-toast';
const mapStyle = {
    width: '100%',
    height: 800,
    position:'absolute'
}

const Map = ReactMapboxGl({
    accessToken:
      'pk.eyJ1IjoiZmFicmljOCIsImEiOiJjaWc5aTV1ZzUwMDJwdzJrb2w0dXRmc2d0In0.p6GGlfyV-WksaDV_KdN27A'
  });



class FrontendHomeComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            viewport: { width: "100",
            height: "400",
                latitude: 50.987283,
                longitude: 5.7697241,
                zoom: 15
              },
              items:[],
              meatActive:false,
              fishActive:false,
              milkActive:false,
              drinksActive:false,
              breadActive:false,
              patatoActive:false,
              dryActive:false,
              iceActive:false,
              filterItem:'',
              redirect:false,
              postalCode:'',
              nameChanger:'',
              mailChanger:'',
              overlaySave:false,
              downloadTrue:false,
              errorForm:false
        };
    }


    componentDidMount(){
        this.setState({
            redirect:false
        })
        let items = Firebase.database().ref('/streekproducten/');
        items.on('value' , snapshot => {

            snapshot.forEach((childSnap) => {
                let newShop = childSnap.val();  
            
                this.setState(prevState =>({
                    items: [...prevState.items, newShop]
                }))
            })
        })
    }

    searchOnPostal(value){

        this.setState({
            postalCode:value
        })

        localStorage.setItem('searchItem',value)

        

        
        
    }

    downloadForm(){
        if(this.state.nameChanger != '' && this.state.mailChanger != ''){
        var key= Firebase.database().ref().child('/streekproducten-downloads/').push().key;

        Firebase.database().ref('/streekproducten-downloads/'+key).set({
          id:key,
            name: this.state.nameChanger,
                mail: this.state.mailChanger,
                
        }).then(()=>{
          
            window.location.href ="https://firebasestorage.googleapis.com/v0/b/web-app-bbo-prod.appspot.com/o/Streekproductengids%2FStreekproductengids-Limburg-DEF-V1-compressed.pdf?alt=media&token=eb3a30f6-e477-4da2-b1c3-8202c445d375"
            
        }).then((data)=>{
            toast.loading('Bedankt voor uw download.', {
                duration: 2000,
              })

              this.setState({
                overlaySave:false
            })
              
        })

        }
        else{
            this.setState({
                errorForm:true
            })
        }
    }




    render() {
        const { viewport } = this.state;
        if (this.state.redirect) {
            return <Redirect to={{pathname:'/deelnemers',state:{data:this.state.postalCode}}} />
          }else if(this.state.downloadTrue){
              return  <Link target="_blank" to={'https://firebasestorage.googleapis.com/v0/b/web-app-bbo-prod.appspot.com/o/Streekproductengids%2FStreekproductengids-Limburg-DEF-V1-compressed.pdf?alt=media&token=eb3a30f6-e477-4da2-b1c3-8202c445d375'} />
          }else{

        return (
        <Container fluid>
              <Row className="change-row">
        <Col sm={12} className="change-col">
            <div className="change-div"><Button onClick={()=>this.setState({overlaySave:true})}><i class="fas fa-download"></i>  Download streekproductengids</Button></div>
        </Col>
    </Row>
    {this.state.overlaySave?
                <Container fluid className="save-changes-home">
            <Row className="overlay-row">
         <Col sm={12} className="overlay-col input-download-home">
                    <h2>Wil je de gids downloaden?</h2>
                    <p>Laat dan hier je gegevens achter!</p>
                    <form>
                    <label>Naam/ Bedrijfsnaam</label><br/>
                    <input  required type="text" name="name" value={this.state.nameChanger} onChange={(event)=>this.setState({nameChanger: event.target.value,errorForm:false})}/>
                    <br/>  <label>E-mail</label><br/>
                    <input required type="text" name="mail" value={this.state.mailChanger} onChange={(event)=>this.setState({mailChanger: event.target.value,errorForm:false})}/>
                   
                    </form>
                   {this.state.errorForm ? <span className="error-form">Let op! Alle velden zijn verplicht</span>:null}
                    <Button onClick={()=>this.setState({overlaySave:false})} className="close-button">Annuleren</Button>
                   {this.state.sendingform ? <Button className="save-button"><Loader className="send-form" type="ThreeDots" color="#fff" height={10} width={100} /*timeout={3000}*/  /> </Button>: <Button onClick={()=>this.downloadForm()} className="save-button">downloaden</Button>}
               </Col>
               </Row>
               </Container>:null}
    
            <Row  className="home-banner-row">
                <Col sm={12} className="home-banner-col">
                    <Container>
                        <Row>
                            <Col sm={12} className="home-banner-col-items">
                                <h2>Regionale <br/>Leveranciers</h2>
                                <h3>Van tuin tot tafel </h3>
                               <p>Op zoek naar de lekkerste streekproducten bij u in de buurt?</p>
                               <span className="postal-field">
                                   <label>Voer uw zoekterm in:</label>
                                   <input onChange={(event)=>this.searchOnPostal(event.target.value)} type="text"/>
                                   
                                   <button onClick={()=>this.setState({redirect:true})}>Zoeken</button>
                                </span>
                                <img src={require('../../assets/images/streekproductengids/keur-merk-bbo.svg')} />
                            </Col>
                            
                        </Row>
                    </Container>

                </Col>
            </Row>
            <Row>
                <Col sm={12} className="category-col-items">
                    <Container >
                        <Row className="category-col-row">

                            <Col sm={10} className="category-col-item">
                                <h2>De groene gids</h2>
                                <p>Rechtstreeks in <br/>contact met uw <br/>lokale streekproducenten</p>
                            </Col>
                            
                        </Row>
                
                        <Row className="category-row">
                            <Col sm={3} className="category-block little-mobile"><div className="text-category"><hr/>Gezond en duurzaam lokaal eten voor een eerlijke prijs<Link className="light-button" to={'../voorwoord'}>Lees meer</Link> </div></Col>
                            <Col sm={3} className="category-block "><div className="sign-up-category"><Link className="category-link"  to={'../inschrijven'}>Aanmelden<br/>Streekproductengids</Link></div></Col>
                            <Col sm={3} className="category-block "><div className="local-cagtegory"><Link className="category-link" to={'../deelnemers'}>Streekproducten<br/>Bekijken</Link></div></Col>
                            <Col sm={3} className="category-block "><div className="question-category"><Link className="category-link"  to={'../webshops'}>Streekproducten<br/>Winkelen</Link></div></Col>
                        </Row>
                    </Container>
                </Col>
            </Row>

            <Row className="products-row">
                <Col sm={12} className="products-col">
                    <Container>
                        <Row className="category-row-products">
                            <Col sm={12} className="products-title">
                                <h2>Productcategorieën</h2>
                                <hr/>
                            </Col>
                            <Col sm={3} className="category ">
                                <div className="category-inside category-meat">
                                    <img src={require('../../assets/images/streekproductengids/icons/vlees-icon.svg')} />
                          
                                    <Link to={{pathname:'../deelnemers', data:'Vlees, wild gevogelte'}}><h3>Vlees, wild & gevogelte</h3></Link>
                               </div>
                            </Col>
                            <Col sm={3} className="category ">
                                <div className="category-inside category-fish">
                                    <img src={require('../../assets/images/streekproductengids/icons/fish.svg')} />
                          
                                    <Link to={{pathname:'../deelnemers', data:'Vis'}}><h3>Vis</h3></Link>
                               </div>
                            </Col>
                            <Col sm={3} className="category ">
                                <div className="category-inside category-cheese">
                                    <img src={require('../../assets/images/streekproductengids/icons/cheese.svg')} />
                          
                                    <Link to={{pathname:'../deelnemers', data:'Zuivel, kaas & eieren'}}><h3>Zuivel, kaas & eieren</h3></Link>
                               </div>
                            </Col>
                            <Col sm={3} className="category ">
                                <div className="category-inside category-drinks">
                                    <img src={require('../../assets/images/streekproductengids/icons/drinks.svg')} />
                          
                                    <Link to={{pathname:'../deelnemers', data:'Dranken'}}><h3>Dranken</h3></Link>
                               </div>
                            </Col>
                            <Col sm={3} className="category ">
                                <div className="category-inside category-bread">
                                    <img src={require('../../assets/images/streekproductengids/icons/bread.svg')} />
                          
                                    <Link to={{pathname:'../deelnemers', data:'Brood & Banket'}}><h3>Brood & Banket</h3></Link>
                               </div>
                            </Col>
                            <Col sm={3} className="category ">
                                <div className="category-inside category-patato">
                                    <img src={require('../../assets/images/streekproductengids/icons/patato.svg')} />
                          
                                    <Link to={{pathname:'../deelnemers', data:'Aardappelen, Groente, fruit'}}><h3>Aardappelen, Groente, fruit</h3></Link>
                               </div>
                            </Col>
                            <Col sm={3} className="category ">
                                <div className="category-inside category-dry">
                                    <img src={require('../../assets/images/streekproductengids/icons/dry.svg')} />
                          
                                    <Link to={{pathname:'../deelnemers', data:'Droogwaren'}}><h3>Droogwaren</h3></Link>
                               </div>
                            </Col>
                            <Col sm={3} className="category ">
                                <div className="category-inside category-ice">
                                    <img src={require('../../assets/images/streekproductengids/icons/ice.svg')} />
                          
                                    <Link to={{pathname:'../deelnemers', data:'Ijs'}}><h3>Ijs</h3></Link>
                               </div>
                            </Col>
                            
                         
                        </Row>

                    </Container>
                </Col>
            </Row>

            <Row className="highlighted-row">
                <Col sm={12}>
                    <Container>
                        <Col sm={12} className="highlighted-title">
                                <h2>Uitgelicht deze maand</h2>
                                <hr/>
                        </Col>
                        <Row>
                        <Col sm={6} className="highlighted-left-image">
                            <span className="highlighted-title-boer">
                                <span className="qoute-highlighted">"</span>
                                Focus op <br/>
                                samenwerking’’
                            </span>
                        </Col>
                        <Col sm={6} className="highlighted-right">
                            <h2>Met fruit erop uit, Paul Debey</h2>
                            <p>
                            Het ideaal van duurzaamheid wint in rap tempo terrein. Consumenten worden kritischer op hun leefomgeving en op hun bestedingspatroon . 65+ers zijn gezonder en kapitaalkrachtiger..
                            </p>
                            <Link to={'../aan-het-woord'}>Lees meer</Link>
                        </Col>
                        </Row>
                    </Container>
                </Col>
            </Row>

            <Row className="recipes-row">
                <Col sm={12}>
                    <Container>

                        <Row>
                        <Col sm={5} className="recipes-left">
                      
                              
                        </Col>
                        <Col sm={7} className="recipes-right">
                            <h2>Recepten</h2>
                            <hr/>
                            <Row>
                      
                                <Col sm={6} className="border-space">
                                    <Link className="link-recept-home" to={{pathname: `/recepten/details`,state: {data: 0}}}>
                                <div className="recipe-col recept-1">
                                    <h4>Terrine de fromage Limbourgeois</h4>
                                    <p>Dit recept is afkomstig van: S.V.H.Meesterkok M.J.Vanhautem</p>
                                    </div>
                                    </Link>
                                </Col>
                                <Col sm={6} className="border-space">
                                <Link className="link-recept-home" to={{pathname: `/recepten/details`,state: {data: 1}}}>
                                <div className="recipe-col recept-2">
                                    <h4>Konijn in het zuur op Limburgse wijze</h4>
                                    <p>LDit recept is afkomstig van: S.V.H.Meesterkok M.J.Vanhautem</p>
                                    </div>
                                    </Link>
                                </Col>

                                <Col sm={6} className="border-space">
                                <Link className="link-recept-home" to={{pathname: `/recepten/details`,state: {data: 2}}}>
                                <div className="recipe-col recept-3">
                                    <h4>Crème aux champignons</h4>
                                    <p>Dit recept is afkomstig van: S.V.H.Meesterkok M.J.Vanhautem </p>
                                    </div>
                                    </Link>
                                </Col>
                                <Col sm={6} className="border-space">
                                <Link className="link-recept-home" to={{pathname: `/recepten/details`,state: {data: 3}}}>
                                <div className="recipe-col recept-4">
                                    <h4>Bloedworst op appelcompote met roggebrood en gekonfijte uien</h4>
                                    <p>Dit recept is afkomstig van: S.V.H.Meesterkok M.J.Vanhautem </p>
                                    </div>
                                    </Link>
                                </Col>
                             
                          
                            </Row>
                         
                        </Col>
                        </Row>
                    </Container>
                </Col>
            </Row>

            <Row className="find-row">
                <Col sm={12}>
                    <Container>
                        <Col sm={12} className="find-title">
                                <h2>Waar kun je de deelnemers vinden?</h2>
                                <hr/>
                                <Row>
                                <Col sm={3}></Col>
                                    <Col sm={6}>
                                    <span className="map">
                          
                               </span>
                                <p>Op zoek lekkerste streekproducten bij u in de buurt?</p>
                               {/*<span className="postal-field">
                                   <label>Voer uw postcode in:</label>
                                   <input type="text"/>
                                   <button>Zoeken</button>
                                </span>*/}
                                
                              
                                </Col>
                                <Col sm={3}></Col>
                                <Col sm={1}></Col>
                                <Col sm={10} className="filter">
                                <h4>Filter:</h4>
                                <Button onClick={()=>this.setState({ meatActive:true,fishActive:false,milkActive:false,drinksActive:false,breadActive:false,patatoActive:false,dryActive:false,iceActive:false,filterItem:'Vlees, wild gevogelte'})} className={this.state.meatActive ? 'active-category':null}>Vlees, wild & gevogelte</Button>
                                <Button onClick={()=>this.setState({ meatActive:false,fishActive:true,milkActive:false,drinksActive:false,breadActive:false,patatoActive:false,dryActive:false,iceActive:false,filterItem:'Vis'})} className={this.state.fishActive ? 'active-category':null}>Vis</Button>
                                <Button onClick={()=>this.setState({ meatActive:false,fishActive:false,milkActive:true,drinksActive:false,breadActive:false,patatoActive:false,dryActive:false,iceActive:false,filterItem:'Zuivel, kaas & eieren'})} className={this.state.milkActive ? 'active-category':null}>Zuivel, kaas & eieren</Button>
                                <Button onClick={()=>this.setState({ meatActive:false,fishActive:false,milkActive:false,drinksActive:true,breadActive:false,patatoActive:false,dryActive:false,iceActive:false,filterItem:'Dranken'})} className={this.state.drinksActive ? 'active-category':null}>Dranken</Button>
                                <Button onClick={()=>this.setState({ meatActive:false,fishActive:false,milkActive:false,drinksActive:false,breadActive:true,patatoActive:false,dryActive:false,iceActive:false,filterItem:'Brood & banket'})} className={this.state.breadActive ? 'active-category':null}>Brood & Banket</Button>
                                <Button onClick={()=>this.setState({ meatActive:false,fishActive:false,milkActive:false,drinksActive:false,breadActive:false,patatoActive:true,dryActive:false,iceActive:false,filterItem:'Aardappelen, groente, fruit'})} className={this.state.patatoActive ? 'active-category':null}>Aardappelen, Groente & fruit</Button>
                                <Button onClick={()=>this.setState({ meatActive:false,fishActive:false,milkActive:false,drinksActive:false,breadActive:false,patatoActive:false,dryActive:true,iceActive:false,filterItem:'Droogwaren'})} className={this.state.dryActive ? 'active-category':null}>Droogwaren</Button>
                                <Button onClick={()=>this.setState({ meatActive:false,fishActive:false,milkActive:false,drinksActive:false,breadActive:false,patatoActive:false,dryActive:false,iceActive:true,filterItem:'Ijs'})} className={this.state.iceActive ? 'active-category':null}>Ijs</Button>
                            </Col>
                                </Row>
                        </Col>
                       
                    </Container>
                </Col>
      
                <Col sm={12} className="map-col">
                    <Container>
                <MapContainer data={this.state.items} filter={this.state.filterItem} />
                </Container>
                </Col>
            </Row>
            
        </Container>

        );}
    }
};

export default FrontendHomeComponent;
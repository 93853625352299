import { Button } from '@material-ui/core';
import React from 'react';
import { Link } from 'react-router-dom';
import {Col, Row} from 'shards-react';
import { Container } from "shards-react";

class FooterComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
         
        };
    }


    toggleHamburger = () => {
        document.querySelector('.navbar-mobile').classList.toggle('showNav');
        document.querySelector('.ham').classList.toggle('showClose');
    }

    render() {

        return (
            <div className="footer-section ">
      
                <Container>
                <Row className="news-letter-footer-row">
                    <Col sm={12} className="news-letter-footer"> 
                       {/*} <h4>Op de hoogte blijven van de laatste ontwikkelingen?</h4>
                        <form><input type="text"/>  <button type="submit" className="subtmit-button" form="hook-form">Inschrijven</button></form>
        */}
                        </Col>
                </Row>
                <Row >
                    <Col sm={4} className="footer-list sitemap">
                        <h3>Sitemap</h3>
                        <ul>
                            <li><Link to={'../home'}>Home</Link></li>
                            <li><Link to={'../Deelnemers'}>Deelnemers</Link></li>
                            <li><Link to={'../recepten'}>Recepten</Link></li>
                            <li><Link to={'../aan-het-woord'}>Aan 't woord</Link></li>
                            <li><Link to={'../inschrijven'}>Inschrijven</Link></li>
                        </ul>
                    </Col>
                    <Col sm={4} className="footer-list">
                        <h3>Streekproducten-limburg</h3>
                        <ul>
                            <li><Link to={'../voorwoord'}>Waarom?</Link></li>
                            <li><Link to={'../deelnemers'}>Deelnemers</Link></li>

                        </ul>
                        
                    </Col>
                    <Col sm={4} className="footer-list">
                        <h3>Contact</h3>
                        <ul>
                            <li><a href="mailto:hallo@beterbijons.nl">hallo@beterbijons.nl</a></li>
                            <li>www.streekproducten-limburg.nl</li>
                          
                        </ul>
                        
                    </Col>

                    <Col sm={11} className="bottom-line">
                        <hr/>
                        <p>All rights reserved @ BeterBijOns B.V.</p>
                    </Col>
                </Row>

        
                </Container>
            </div>
        );
    }
}

export default FooterComponent;

import React from "react";
import { Container, Row, Col } from "shards-react";
import SignOnComponent from "../../components/authentication/sign-on";
import SignOnCompleteComponent from "../../components/authentication/sing-on-complete";

import PageTitle from "../../components/common/PageTitle";
import OrdersIndex from "../../components/orders";



const SignOnCompleteView = () => (
  <Container  className="main-content-container px-4">
    <SignOnCompleteComponent />
    
  </Container>
);

export default SignOnCompleteView;

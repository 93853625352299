import React from 'react';
import {Col, Row} from 'shards-react';
import { Container } from "shards-react";

class HeaderComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            winkels:true,
            aboutUs: false,
            contact: false,
            faq: false,
            inschrijving: false,
            webshops:false
         
        };
    }


    toggleHamburger = () => {
        document.querySelector('.navbar-mobile').classList.toggle('showNav');
        document.querySelector('.ham').classList.toggle('showClose');
    }

    onClickMenu(menuItem){
        console.log('clicked');
        if(menuItem === 'home'){
            console.log('hello')
            localStorage.setItem('menuActive', 'home')
        }else if(menuItem === 'deelnemers'){
            localStorage.setItem('menuActive', 'deelnemers')
        }else if(menuItem === 'recepten'){
            localStorage.setItem('menuActive', 'recepten')
        }
        else if(menuItem === 'aan-het-woord'){
            localStorage.setItem('menuActive', 'aan-het-woord')
        }else if(menuItem === 'inschrijving'){
            localStorage.setItem('menuActive', 'inschrijving')
        }
        else if(menuItem === 'webshops'){
            localStorage.setItem('menuActive', 'webshops')
        }
    }

    componentDidMount(){
        if(localStorage.getItem('menuActive') === 'home'){
            this.setState({
                winkels:true,
                aboutUs: false,
                contact: false,
                faq: false,
                inschrijving: false,
                webshops:false
            })
        }else if(localStorage.getItem('menuActive') === 'deelnemers'){
            this.setState({
                winkels:false,
                aboutUs: true,
                contact: false,
                faq: false,
                inschrijving: false,
                webshops:false
            })
        }
        else if(localStorage.getItem('menuActive') === 'recepten'){
            this.setState({
                winkels:false,
                aboutUs: false,
                contact: true,
                faq: false,
                inschrijving: false,
                webshops:false
            })
        }
        else if(localStorage.getItem('menuActive') === 'aan-het-woord'){
            this.setState({
                winkels:false,
                aboutUs: false,
                contact: false,
                faq: true,
                inschrijving: false,
                webshops:false
            })
        }
        else if(localStorage.getItem('menuActive') === 'inschrijving'){
            this.setState({
                winkels:false,
                aboutUs: false,
                contact: false,
                faq: false,
                inschrijving: true,
                webshops:false
            })
        }
        else if(localStorage.getItem('menuActive') === 'webshops'){
            this.setState({
                winkels:false,
                aboutUs: false,
                contact: false,
                faq: false,
                inschrijving: false,
                webshops:true
            })
        }
    }

    render() {

        return (
            <Container fluid>
 
                <Row className="header-section desktop-menu">
                    <Col sm={2} className="logo-section">
                    <a href="../"><img alt='Het logo van Beter Bij Ons' src={"https://firebasestorage.googleapis.com/v0/b/web-app-bbo-prod.appspot.com/o/Streekproductengids%2FSTREEKPRODUCTEN_LIMBURG_WRITTEN.svg?alt=media&token=87e3a4e2-60c4-438d-b660-934ec4a0af40"}/></a>
                    </Col>
                    <Col sm={8} className="menu-section">
                        <a onClick={()=>this.onClickMenu('home')}  className={this.state.winkels ?"active-desktop-menu":""} href="../">Home</a>
                        <a   onClick={()=>this.onClickMenu('deelnemers')} className={this.state.aboutUs ?"active-desktop-menu":""} href="../deelnemers">Deelnemers</a>
                        <a  onClick={()=>this.onClickMenu('recepten')} className={this.state.contact ?"active-desktop-menu":""}  href="../recepten">Recepten</a>
                        <a  onClick={()=>this.onClickMenu('aan-het-woord')} className={this.state.faq ?"active-desktop-menu":""}  href="../aan-het-woord">Aan ’t woord</a>
                        <a  onClick={()=>this.onClickMenu('webshops')} className={this.state.webshops ?"active-desktop-menu":""}  href="../webshops">Winkels</a>
                      
                       <a  onClick={()=>this.onClickMenu('inschrijving')} className={this.state.inschrijving ?"active-desktop-menu cta-menu":"cta-menu"} href="../inschrijven">Inschrijving</a>
                    </Col>
                    <Col sm={2} className="sub-logo-section">
                    <a href="../"><img alt='Het logo van Beter Bij Ons' src={"https://firebasestorage.googleapis.com/v0/b/web-app-bbo-prod.appspot.com/o/Streekproductengids%2FLOGO_PLATEAU_HUB_WRITTEN.svg?alt=media&token=2a7fc1be-ddab-4654-849f-7cdd9f3a8e61"}/></a>
                    </Col>
                </Row>
                <Row className="mobile-menu">
                    <Col sm={12}>
                        <a href="../"><img alt='Het logo van Beter Bij Ons' src={"https://firebasestorage.googleapis.com/v0/b/web-app-bbo-prod.appspot.com/o/Streekproductengids%2FSTREEKPRODUCTEN_LIMBURG_WRITTEN.svg?alt=media&token=87e3a4e2-60c4-438d-b660-934ec4a0af40"} className="mobile-logo-menu"/></a>
                        <button className="ham" onClick={() => this.toggleHamburger()}></button>
                        <nav className="navbar-mobile">

                            <ul>
                                <li><a className="menuLink"  href="../">Home</a></li>
                                <li><a className="menuLink" href="../deelnemers">Deelnemers</a></li>
                                <li><a className="menuLink" href="../recepten">Recepten</a></li>
                                <li><a className="menuLink" href="../aan-het-woord">Aan ’t woord</a></li>
                                <li><a className="menuLink" href="../webshops">Winkels</a></li>
                               
                            </ul>
                            <a href="../"><img className="logo-mobile-plateau" alt='Het logo van Beter Bij Ons' src={"https://firebasestorage.googleapis.com/v0/b/web-app-bbo-prod.appspot.com/o/Streekproductengids%2FLOGO_PLATEAU_HUB_WRITTEN.svg?alt=media&token=2a7fc1be-ddab-4654-849f-7cdd9f3a8e61"}/></a>
                        </nav>
                    </Col>


        </Row>

        </Container>
        );
    }
}

export default HeaderComponent;

import React, { Component } from 'react';
import { Row, Col, Card, CardHeader, CardBody, Button , Container} from "shards-react";
import Firebase from 'firebase';
import { Link } from 'react-router-dom';
import { StaticRouter } from 'react-router';
import { Redirect } from 'react-router-dom';
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
class ParticipantIndexPostal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            meatActive:false,
            fishActive:false,
            milkActive:false,
            drinksActive:false,
            breadActive:false,
            patatoActive:false,
            dryActive:false,
            iceActive:false,
            items:[],
            images:[],
            filterItem:'',
            redirect:false,
            slice:15,
            loading:true
        };
    }

    deleteFilter(){
        console.log('here')
        localStorage.setItem('postalSet',false)
        localStorage.setItem('postal','')
        this.setState({
            redirect:true
        })
    }

    componentDidMount(){
        this.setState({
            loading:true,
            searchItem:''
        })
       if(this.props.data){
           this.setState({
               filterItem:this.props.data
           })
           if(this.props.data === 'Vlees, wild gevogelte'){
            this.setState({
             meatActive:true,
             fishActive:false,
             milkActive:false,
             drinksActive:false,
             breadActive:false,
             patatoActive:false,
             dryActive:false,
             iceActive:false,
            })
            }
           else if(this.props.data === 'Vis'){
               this.setState({
                meatActive:false,
                fishActive:true,
                milkActive:false,
                drinksActive:false,
                breadActive:false,
                patatoActive:false,
                dryActive:false,
                iceActive:false,
               })
           }
           else if(this.props.data === 'Zuivel, kaas & eieren'){
            this.setState({
             meatActive:false,
             fishActive:false,
             milkActive:true,
             drinksActive:false,
             breadActive:false,
             patatoActive:false,
             dryActive:false,
             iceActive:false,
            })
            }
            else if(this.props.data === 'Dranken'){
                this.setState({
                 meatActive:false,
                 fishActive:false,
                 milkActive:false,
                 drinksActive:true,
                 breadActive:false,
                 patatoActive:false,
                 dryActive:false,
                 iceActive:false,
                })
            }
            else if(this.props.data === 'Brood & Banket'){
                this.setState({
                 meatActive:false,
                 fishActive:false,
                 milkActive:false,
                 drinksActive:false,
                 breadActive:true,
                 patatoActive:false,
                 dryActive:false,
                 iceActive:false,
                })
            }
            else if(this.props.data === 'Aardappelen, Groente, fruit'){
                this.setState({
                 meatActive:false,
                 fishActive:false,
                 milkActive:false,
                 drinksActive:false,
                 breadActive:false,
                 patatoActive:true,
                 dryActive:false,
                 iceActive:false,
                })
            }
            else if(this.props.data === 'Droogwaren'){
                this.setState({
                 meatActive:false,
                 fishActive:false,
                 milkActive:false,
                 drinksActive:false,
                 breadActive:false,
                 patatoActive:false,
                 dryActive:true,
                 iceActive:false,
                })
            }
            else if(this.props.data === 'Ijs'){
                this.setState({
                 meatActive:false,
                 fishActive:false,
                 milkActive:false,
                 drinksActive:false,
                 breadActive:false,
                 patatoActive:false,
                 dryActive:false,
                 iceActive:true,
                })
            }
       }
        window.scrollTo(0, 0)
        let items = Firebase.database().ref('/streekproducten/');
        items.on('value' , snapshot => {

            snapshot.forEach((childSnap) => {
                let newShop = childSnap.val();  
            
                this.setState(prevState =>({
                    items: [...prevState.items, newShop]
                }))
            })
        })

        let images = Firebase.database().ref('/streekproducten/');
        images.on('value' , snapshot => {

            snapshot.forEach((childSnap, i) => {
                let newShop = childSnap.val();  

                /*const BASE_URL = 'https://maps.googleapis.com/maps/api/geocode/json?address=';
                const APIKey= '+CA&key=AIzaSyB7G-vcpfhNmIdFl-RkodKDYsDY1VTQZV0'
                fetch(`${BASE_URL}${newShop.companyAdres}${newShop.companyPostal}${APIKey}`, { 
                  method: 'GET',
                  headers:{
                   
                  }
                })
                .then(response => response.json())
                .then(json => {
                    if(json.results[0]){
                        Firebase.database().ref('/streekproducten/'+newShop.id).update({

                            lat: json.results[0].geometry.location.lat,
                            lng:json.results[0].geometry.location.lng, 
                           
                            
                        
            
                    
                        }).then((data)=>{
       
                console.log('done')
                        }).catch((error)=>{
                            console.log('not done')
                        })
                    }else{
                        console.log('not')
                    }
                   // console.log(newShop.id +'aantal:'+ i)
                  
                
                 
                
                });*/

                Firebase.storage().ref('/Streekproductengids/images/'+newShop.companyName+'/'+newShop.imagesPrimaireName.replace(/^([^.]+)$|(\.[^.]+)$/i, '$1' + "_1920x1080" + '$2')).getDownloadURL().then((url) => { 
                    let newImage ={id : newShop.id, url: url}
                  
                    this.setState(prevState =>({
                        images: [...prevState.images, newImage]
                    }))
                })

             


                
            })
            this.setState({
                loading:false
            })
        })
        
    }


    

    render() {
        if (this.state.redirect) {
            return <Redirect to={'/deelnemers'} />
          }else{
        return (
        <Container fluid>
            
        
            <Row className="participant-row">
                <Col sm={12}>
                    <Container>
                        <Col sm={12} className="participant-title">
                                <h2>Deelnemers</h2> 
                                <hr/>
                                <p>Maak kennis met de boeren deelnemers van streekproducten-limburg. Van boeren en bakkers tot kaasmakers en kwekers. Ontmoet de helden die dag en nacht voor jouw eten en drinken klaar staan. En met liefde, passie en op eerlijke wijze produceren. Zij vertellen je hier graag meer over. Want als je weet wat je eet, smaak alles net even lekkerder!</p>
                        </Col>
                        <Col sm={12} className="filter">
                            <h4>Zoeken:</h4>
                            <input type="text" placeholder="Zoeken..." id="icon" className="search-bar" value={this.state.searchItem} onChange={(event)=>this.setState({searchItem:event.target.value})} />
                        </Col>                    
                        
                        <Col sm={12} className="filter">
                                <h4>Filter:</h4>
                                <Button onClick={()=>this.setState({ meatActive:true,fishActive:false,milkActive:false,drinksActive:false,breadActive:false,patatoActive:false,dryActive:false,iceActive:false,filterItem:'Vlees, wild gevogelte'})} className={this.state.meatActive ? 'active-category':null}>Vlees, wild & gevogelte</Button>
                                <Button onClick={()=>this.setState({ meatActive:false,fishActive:true,milkActive:false,drinksActive:false,breadActive:false,patatoActive:false,dryActive:false,iceActive:false,filterItem:'Vis'})} className={this.state.fishActive ? 'active-category':null}>Vis</Button>
                                <Button onClick={()=>this.setState({ meatActive:false,fishActive:false,milkActive:true,drinksActive:false,breadActive:false,patatoActive:false,dryActive:false,iceActive:false,filterItem:'Zuivel, kaas & eieren'})} className={this.state.milkActive ? 'active-category':null}>Zuivel, kaas & eieren</Button>
                                <Button onClick={()=>this.setState({ meatActive:false,fishActive:false,milkActive:false,drinksActive:true,breadActive:false,patatoActive:false,dryActive:false,iceActive:false,filterItem:'Dranken'})} className={this.state.drinksActive ? 'active-category':null}>Dranken</Button>
                                <Button onClick={()=>this.setState({ meatActive:false,fishActive:false,milkActive:false,drinksActive:false,breadActive:true,patatoActive:false,dryActive:false,iceActive:false,filterItem:'Brood & banket'})} className={this.state.breadActive ? 'active-category':null}>Brood & Banket</Button>
                                <Button onClick={()=>this.setState({ meatActive:false,fishActive:false,milkActive:false,drinksActive:false,breadActive:false,patatoActive:true,dryActive:false,iceActive:false,filterItem:'Aardappelen, groente, fruit'})} className={this.state.patatoActive ? 'active-category':null}>Aardappelen, Groente & fruit</Button>
                                <Button onClick={()=>this.setState({ meatActive:false,fishActive:false,milkActive:false,drinksActive:false,breadActive:false,patatoActive:false,dryActive:true,iceActive:false,filterItem:'Droogwaren'})} className={this.state.dryActive ? 'active-category':null}>Droogwaren</Button>
                                <Button onClick={()=>this.setState({ meatActive:false,fishActive:false,milkActive:false,drinksActive:false,breadActive:false,patatoActive:false,dryActive:false,iceActive:true,filterItem:'Ijs'})} className={this.state.iceActive ? 'active-category':null}>Ijs</Button>
                            </Col>
{this.state.loading? 
    <Loader className="loading-primaire" type="Oval" color="#000" height={50} width={100} /*timeout={3000}*/  />
:
                        <Row>
                            {this.state.items.filter((item)=>   this.state.searchItem ? item.companyName.toLowerCase().includes(this.state.searchItem.toLowerCase()) || item.companyCity.toLowerCase().includes(this.state.searchItem.toLowerCase())|| item.category.toLowerCase().includes(this.state.searchItem.toLowerCase()):item.companyName == item.companyName).filter((item)=> this.state.filterItem ?item.category === this.state.filterItem: item.category === item.category).sort((a, b) =>{
    if(a.companyName < b.companyName) { return -1; }
    if(a.companyName > b.companyName) { return 1; }
    return 0;
}).slice(0,this.state.slice).map((item)=>(
                                <Col sm={4} className="particioant-col">
                                <div className="participant">
                                    <img src={this.state.images.filter(image => item.id == image.id).map((image)=>(
                                       image.url
                                    ))} />
                                    <div className="participant-content">
                                        <i class="fas fa-map-marker-alt"></i> <span className="participant-location">{item.companyCity}</span>
                                    
                                    <span className="participant-category">Categorie: {item.category}</span>
                                    <h3>{item.companyName}</h3>
                                    <p dangerouslySetInnerHTML={{ __html:item.desc.slice(0, 180)+'...'}}>
                                    
                                    </p>
                                    <Link to={"/deelnemers/details?id="+item.id}>Meer</Link>
                                    </div>
                                </div>

                            </Col>
                            ))}
                        </Row>
          }
                        <Row>
                            <Col sm={4}>

                            </Col>
                            <Col sm={4} className="load-more">
                                <Button onClick={()=>this.setState({slice: this.state.slice+15})}>Meer deelnemers</Button>
                                
                            </Col>
                        </Row>

                        
                    
                        
                    </Container>
                </Col>
            </Row>

            
        
            
        </Container>

        );}
    }
};

export default ParticipantIndexPostal;
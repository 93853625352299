import React from "react";
import { Container, Row, Col } from "shards-react";
import ChangesIndex from "../../components/changes";

import PageTitle from "../../components/common/PageTitle";
import DownloadGidsIndex from "../../components/downloads";
import OrdersIndex from "../../components/orders";



const Downloads = () => (
  <Container fluid className="main-content-container px-4">
    <Row noGutters className="page-header py-4">
      <PageTitle title="Downloads van gids" subtitle="Beter bij ons" md="12" className="ml-sm-auto mr-sm-auto" />
    </Row>
    <DownloadGidsIndex />
    
  </Container>
);

export default Downloads;

import React, { Component } from 'react';
import { Row, Col, Card, CardHeader, CardBody, Button , Container} from "shards-react";
import Firebase from 'firebase';
import { Link } from 'react-router-dom';
import { StaticRouter } from 'react-router';

class HomeBlogComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            meatActive:true,
            fishActive:false,
            milkActive:false,
            drinksActive:false,
            breadActive:false,
            patatoActive:false,
            dryActive:false,
            iceActive:false
        };
    }

componentDidMount(){
    window.scrollTo(0, 0)
}   

    render() {

        return (
        <Container fluid>
            

            <Row className="recepts-details-row home-blog">
                <Col sm={12} >
                <img src={require('../../assets/images/streekproductengids/frontend/landscape-foto-voorwoord.jpg')} />
          
                </Col>
            </Row>

            <Container className="recept-details-container">
                <Row>

                    <Col sm={12} className="recept-getting-ready green-title mobile-top">
                         <h2>Alle LOF voor Limburg</h2>
                         <p>
                         Het begon ooit met een gezamenlijke droom. Wat zou er gebeuren als je organisaties in een keten met elkaar gaat verbinden om hen vervolgens gezamenlijk te laten werken aan één gezamenlijk doel: “Gezond en zo veel mogelijk lokaal geproduceerd voedsel op de werkvloer, in de zorg, het bedrijfsleven, onderwijs, de sport en de horeca. Daarbij krijgt iedere speler in de keten een eerlijke prijs betaald en worden duurzame investeringen beloond”. Kortom Voedselketens transparanter en korter maken door meer streekproducten te gebruiken en lokale producenten een eerlijke prijs te bieden; dat was het doel van het programma Limburgs Offensief Food (LOF).<br/><br/>
Het programma LOF is in juni 2021 succesvol afgerond, waarbij de reis met deze streekproductengids dóórgaat.<br/><br/>
De uitdaging, waar Limburg nu voor staat, is door F-Fort, Vliex4U en Pixelpro’s opgepakt, allen spelers van het eerste uur. We hebben onze krachten gebundeld in Beter Bij Ons bv (BBO).<br/><br/>
 en vertaald naar een laagdrempelige, subsidievrije aanpak voor de Limburgse Streekproducenten.<br/><br/>
We bieden een laagdrempelig, gratis podium voor iedere streekproducent. Dit podium bestaat uit opname van zijn of haar bedrijf in zowel de gids als de bijhorende website van <a href="https://streekproducten-limburg.nl">“Streekproducten-limburg”</a>. De gids is gratis te downloaden als pdf. Al bij start zijn hier meer dan 100 streekproducenten in te vinden met zowel inspirerende als praktische informatie en aansprekende foto’s. Daarnaast wordt de streekproducenten de mogelijkheid geboden hier ook een eigen webshop aan te koppelen.<br/><br/>
Op deze wijze proberen wij te borgen dat het gedachtengoed achter het programma LOF voortgezet kan worden. Bovendien vormt deze aanpak de kans om in Limburg een community van Streekproducenten te vormen, waar samenwerking en kennisdeling centrale thema’s kunnen/zullen zijn. Streekproducenten kunnen doen waar ze goed in zijn: zorgen dat er schitterende Limburgse streekproducten zijn en zowel Limburgse opdrachtgevers in de zorg, het bedrijfsleven, onderwijs, sport en horeca, maar ook de consument en de toerist, kunnen eenvoudig streekproducten vinden en bestellen. De samenwerking met de landelijk coordinator SDG-12 (duurzame productie en consumptie), Karin van Ijsselmuide, wordt eveneens voortgezet om blijvend te werken aan een eerlijke prijs waarin kosten van de impact op mens, milieu en maatschappij meegerekend worden.
                         </p>
                        
                         <p>
                         Deze samenwerkingen zorgen dat onze regionale producten het podium krijgen dat ze verdienen. Belangrijker nog: We realiseren met dit initiatief daadwerkelijk korte ketens op grote schaal voor onze streekproducten. LOF-doelstellingen zoals een betere prijs voor de producten, minder vervoer, transport, opslag, hogere betrokkenheid van de consument, een sterke(re) locale verankering van producten en een duurzame consumptie door locale en seizoensgebonden producten worden hiermee gerealiseerd.
<br/><br/>Reist u met ons mee?

                        </p>
                        <p>Het BBO-team,<br/><b>Bert, Jordie, Joppe, Raoul</b></p>
                       
                    </Col>

                </Row>
            </Container>

            
        
            
        </Container>

        );
    }
};

export default HomeBlogComponent;
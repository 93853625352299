import React from "react";
import { Container, Row, Col } from "shards-react";
import SignOnComponent from "../../components/authentication/sign-on";

import PageTitle from "../../components/common/PageTitle";
import OrdersIndex from "../../components/orders";



const SignOnView = () => (
  <Container  className="main-content-container px-4">
    <SignOnComponent />
    
  </Container>
);

export default SignOnView;

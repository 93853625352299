import React, { Component } from 'react';
import { Row, Col, Card, CardHeader, CardBody, Button , Container} from "shards-react";
import Firebase from 'firebase';
import { Link } from 'react-router-dom';
import { StaticRouter } from 'react-router';

class ReceptIndex extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            meatActive:true,
            fishActive:false,
            milkActive:false,
            drinksActive:false,
            breadActive:false,
            patatoActive:false,
            dryActive:false,
            iceActive:false
        };
    }


    

    render() {

        return (
        <Container fluid>
            

            <Row className="participant-row">
                <Col sm={12}>
                    <Container>
                        <Col sm={12} className="participant-title">
                                <h2>Recepten</h2>
                                <hr/>
                                <Row>
                                    <Col sm={6} className="recept-kok">
                                    <img src={"https://firebasestorage.googleapis.com/v0/b/web-app-bbo-prod.appspot.com/o/Streekproductengids%2FMathieu_1920x1080.jpg?alt=media&token=ed8135fa-f24e-43ac-9c3f-1d108ff6cb7f"} />
                                    </Col>
                                    <Col sm={6} className="recept-kok">
                                    <p>Als S.V.H. Meesterkok Mathieu Vanhautem werk ik nu reeds bijna 25 jaar bij Roc Gilde Opleidingen. Het totale horeca team zorgt voor de koks van de toekomst.<br/><br/>
Als ambachtsman weet ik dat het heel belangrijk voor onze leerlingen is, om met de juiste mensen om te gaan, kruisbestuiving is hierbij zo belangrijk dat je met de juiste docenten en in het bedrijfsleven met de juiste chefs omgaat om het vak je eigen te maken.<br/><br/>
Door zelf initiatief en innoverend gedrag te tonen, met inachtneming van een stukje gezondheid en welzijn zal dit altijd een uitdaging blijven. Wij vormen en bekwamen onze studenten tot gedreven chefs van de toekomst.<br/><br/>
De steekwoorden blijven dan ook altijd regionale producten / gezond / duurzaam / en winst voor iedereen. Dit geheel gericht op consument / product / aarde,  uiteraard is het de natuur die ons van al dit mooist voorziet.<br/><br/>
Met ingang van het nieuwe schooljaar gaan wij de uitdaging aan om u maandelijks mooie recepten te presenteren van seizoensgebonden streekproducten die door onze studenten worden samengesteld.

</p>
                                    </Col>
                                </Row>
                               
                        </Col>
                       {/*} <Col sm={12} className="filter">
                                <h4>Filter:</h4>
                                <Button onClick={()=>this.setState({ meatActive:true,fishActive:false,milkActive:false,drinksActive:false,breadActive:false,patatoActive:false,dryActive:false,iceActive:false})} className={this.state.meatActive ? 'active-category':null}>Vlees, wild & gevogelte</Button>
                                <Button onClick={()=>this.setState({ meatActive:false,fishActive:true,milkActive:false,drinksActive:false,breadActive:false,patatoActive:false,dryActive:false,iceActive:false})} className={this.state.fishActive ? 'active-category':null}>Vis</Button>
                                <Button onClick={()=>this.setState({ meatActive:false,fishActive:false,milkActive:true,drinksActive:false,breadActive:false,patatoActive:false,dryActive:false,iceActive:false})} className={this.state.milkActive ? 'active-category':null}>Zuivel, kaas & eieren</Button>
                                <Button onClick={()=>this.setState({ meatActive:false,fishActive:false,milkActive:false,drinksActive:true,breadActive:false,patatoActive:false,dryActive:false,iceActive:false})} className={this.state.drinksActive ? 'active-category':null}>Dranken</Button>
                                <Button onClick={()=>this.setState({ meatActive:false,fishActive:false,milkActive:false,drinksActive:false,breadActive:true,patatoActive:false,dryActive:false,iceActive:false})} className={this.state.breadActive ? 'active-category':null}>Boord & Banket</Button>
                                <Button onClick={()=>this.setState({ meatActive:false,fishActive:false,milkActive:false,drinksActive:false,breadActive:false,patatoActive:true,dryActive:false,iceActive:false})} className={this.state.patatoActive ? 'active-category':null}>Aardappelen, Groente & fruit</Button>
                                <Button onClick={()=>this.setState({ meatActive:false,fishActive:false,milkActive:false,drinksActive:false,breadActive:false,patatoActive:false,dryActive:true,iceActive:false})} className={this.state.dryActive ? 'active-category':null}>Droogwaren</Button>
                                <Button onClick={()=>this.setState({ meatActive:false,fishActive:false,milkActive:false,drinksActive:false,breadActive:false,patatoActive:false,dryActive:false,iceActive:true})} className={this.state.iceActive ? 'active-category':null}>Ijs</Button>
        </Col>*/}

                        <Row>
                            <Col sm={12}>
                                <Row className="recepts-row">
                                    <Col sm={8} className="recepts-left">
                                        <h3>Terrine de fromage Limbourgeois</h3>
                                        <p>Dit recept is afkomstig van: S.V.H.Meesterkok M.J.Vanhautem</p>
                                       
                                        <Link to={{pathname: `/recepten/details`,state: {data: 0}}}className="light-button">Lees meer</Link>
                                    </Col>
                                    <Col sm={4} className="recepts-right">
                                    <img src={require('../../assets/images/streekproductengids/recepten/recept-1.jpg')} />
                                    </Col>
                                </Row>
                            </Col>
                            <Col sm={12}>
                                <Row className="recepts-row">
                                    <Col sm={8} className="recepts-left">
                                        <h3>Konijn in het zuur op Limburgse wijze</h3>
                                        
                                        <p>Dit recept is afkomstig van: S.V.H.Meesterkok M.J.Vanhautem</p>
                                        <Link to={{pathname: `/recepten/details`,state: {data: 1}}}className="light-button">Lees meer</Link>
                                    </Col>
                                    <Col sm={4} className="recepts-right">
                                    <img src={require('../../assets/images/streekproductengids/recepten/recept-2.jpg')} />
                                    </Col>
                                </Row>
                            </Col>
                            <Col sm={12}>
                                <Row className="recepts-row">
                                    <Col sm={8} className="recepts-left">
                                        <h3>Crème aux champignons</h3>
                                    
                                        
                                        <p>Dit recept is afkomstig van: S.V.H.Meesterkok M.J.Vanhautem</p>
                                        <Link to={{pathname: `/recepten/details`,state: {data: 2}}}className="light-button">Lees meer</Link>
                                    </Col>
                                    <Col sm={4} className="recepts-right">
                                    <img src={require('../../assets/images/streekproductengids/recepten/recept-3.jpg')} />
                                    </Col>
                                </Row>
                            </Col>
                            <Col sm={12}>
                                <Row className="recepts-row">
                                    <Col sm={8} className="recepts-left">
                                        <h3>Bloedworst  op appelcompote met roggebrood en gekonfijte uien</h3>
                                      
                                        
                                        <p>Dit recept is afkomstig van: S.V.H.Meesterkok M.J.Vanhautem</p>
                                        <Link to={{pathname: `/recepten/details`,state: {data: 3}}}className="light-button">Lees meer</Link>
                                    </Col>
                                    <Col sm={4} className="recepts-right">
                                    <img src={require('../../assets/images/streekproductengids/recepten/recept-4.jpg')} />
                                    </Col>
                                </Row>
                            </Col>

                          
                           
                        </Row>
                    
                        
                    </Container>
                </Col>
            </Row>

            
        
            
        </Container>

        );
    }
};

export default ReceptIndex;
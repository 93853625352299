import React from "react";
import { Container, Row, Col } from "shards-react";
import AdminPanelIndex from "../../components/adminpanel";

import PageTitle from "../../components/common/PageTitle";
import FooterComponent from "../../components/Footer/FooterComponent";
import FrontendHomeComponent from "../../components/frontend";
import ParticipantIndex from "../../components/frontend/participantIndex";
import ShopsIndex from "../../components/frontend/ShopsIndex";
import HeaderComponent from "../../components/Header/headerComponent";
import ProductsIndex from "../../components/products";



const ShopsView = (props) => (
    <div>
    <HeaderComponent/>
    <ShopsIndex  />
    <FooterComponent/>
    
    </div>
);

export default ShopsView;

import React, { Component } from 'react';
import { Row, Col, Card, CardHeader, CardBody, Button , Container} from "shards-react";
import Firebase from 'firebase';
import { Link } from 'react-router-dom';
import { StaticRouter } from 'react-router';
import { Redirect } from 'react-router-dom';
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
class EnqueteComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            questions:[
                {id:0,question:'1. Welk cijfer geeft u als eerste indruk aan het inschrijfformulier?',value:'',text:'',extendedWithText:false},
                {id:1,question:'2. Mist u onderwerpen op het inschrijfformulier?',value:'',text:'',extendedWithText:false},
                {id:2,question:'3. Vond u het abonnementskosten bijbehorend aan deelname van de website makkelijk te vinden?',value:'',text:'',extendedWithText:false},
                {id:3,question:'4. Waren er onduidelijkheden voordat u aansloot bij Streekproducten-Limburg?',value:'',text:'',extendedWithText:false},
                {id:4,question:'5. Wat was voor u de hoofdreden om zich aan te sluiten bij Streekproducten-Limburg?',value:'',text:'',extendedWithText:false},
                {id:5,question:'6. Hoe tevreden bent u over de omslag foto?',value:'',text:'',extendedWithText:false},
                {id:6,question:'7. Hoe tevreden bent u over hoe uw logo eruitziet?',value:'',text:'',extendedWithText:false},
                {id:7,question:'8. Hoe tevreden bent u met hoe uw ingevulde tekst eruitziet?',value:'',text:'',extendedWithText:false},
                {id:8,question:'9. Zou u zelf dingen veranderen aan de opmaak/presentatie van uw ingevulde gegevens?',value:'',text:'',extendedWithText:false},
                {id:9,question:'1. Hoe vindt u dat de producten worden gepresenteerd op de site?',value:'',text:'',extendedWithText:false},
                {id:10,question:'2. Vind u dat de informatie van uw bedrijf op de website overzichtelijk wordt weergeven?',value:'',text:'',extendedWithText:false},
                {id:11,question:'3. Hoe aantrekkelijk vindt u de lay-out van de site?',value:'',text:'',extendedWithText:false},
                {id:12,question:'4. Welk cijfer geeft u de website van Streekproducten-Limburg?',value:'',text:'',extendedWithText:false},
                {id:13,question:'5. Hoe professioneel vindt u de vormgeving van de website?',value:'',text:'',extendedWithText:false},
                {id:14,question:'6. Op welke wijze zou u vanuit het Platform geïnformeerd willen worden?',value:'',text:'',extendedWithText:false},
                {id:15,question:'7. Over welke onderwerpen zou u geïnformeerd willen worden?',value:'',text:'',extendedWithText:false},
                {id:16,question:'8. Wat vindt u van de abonnementskosten om uw producten via de website te verkopen?',value:'',text:'',extendedWithText:false},
                {id:17,question:'9. Bent u tevreden over het onderlinge contact tussen U/Uw bedrijf en Streekproducten-Limburg/BBO',value:'',text:'',extendedWithText:false},
                {id:18,question:'10. Wat verwacht u van het onderlinge contact?',value:'',text:'',extendedWithText:false},
                {id:19,question:'11. Wat was voor u de hoofdreden om zich aan te sluiten bij Streekproducten-Limburg?',value:'',text:'',extendedWithText:false},
                {id:20,question:'12. Staat u open voor het promoten van uw producten via sociale media?',value:'',text:'',extendedWithText:false},
                {id:21,question:'13. Door het concept van streekproducten-Limburg zal minder transport vereist zijn. Hoeveel heeft dit uw keuze beïnvloed op het inschrijven bij Streekproducten-Limburg?',value:'',text:'',extendedWithText:false},
                {id:22,question:'14. Vind u dat de informatie van uw bedrijf op de website overzichtelijk wordt weergeven?',value:'',text:'',extendedWithText:false},
                {id:23,question:'15. Heeft u al iets gehad aan de promotie via de website?',value:'',text:'',extendedWithText:false},
                {id:24,question:'16. Waaruit bestaat uw klantenbestand?',value:'',text:'',extendedWithText:false},
                {id:25,question:'17. Wat zou u willen toevoegen aan de website?',value:'',text:'',extendedWithText:false},
                {id:26, question:'18. Begrijpt u het concept/idee van de streekproducten gids?',value:'',text:'',extendedWithText:false},
                {id:27, question:'19. Staat u open voor een vervolggesprek?',value:'',text:'',extendedWithText:false},
                {id:28, question:'20. Wat vond u van de lengte van de enquête?',value:'',text:'',extendedWithText:false},
            ],
            redirect:false,
            valid:false,
            error:'',
            loading:false

        };
    }

    
    

    componentDidMount(){
       
    }

    checkBoxHandle(questionNumber, value, extended){

            for(let i = 0; i < this.state.questions.length; i++){
   
                if(i == questionNumber){
              
                    let questions = [...this.state.questions];
                    let question = {...this.state.questions[i]};
                    question.value = value;
                    question.extendedWithText = extended;
                    questions[i] = question
                    this.setState({questions})


                }
            }
    }

    textInputHandle(questionNumber, value){

        for(let i = 0; i < this.state.questions.length; i++){

            if(i == questionNumber){
          
                let questions = [...this.state.questions];
                let question = {...this.state.questions[i]};
                question.text = value;
                questions[i] = question
                
                this.setState({questions})


            }
        }
    }

    handleSubmitForm(){
        this.setState({
            loading:true
        })

        for(let i=0; i < this.state.questions.length; i++){
            if(this.state.questions[i].value != ''){
                this.setState({
                    valid: true
                })
            }


        }

        setTimeout(() => {
            
            if(this.state.valid == true){
                var key = Firebase.database().ref().child('/streekproducten-items/limburg/survey/recorded-participants/').push().key;
                Firebase.database().ref('/streekproducten-items/limburg/survey/recorded-participants/'+key).set({
                    id:key,
                    repsonse: this.state.questions
    
                }).then(()=>{
                    this.setState({
                        redirect: true
                    })
                })
            }else{
                this.setState({
                    error:'Controlleer of alle velden zijn ingevult.',
                    loading:false
                })
            }
          
            }, 1000);

    }

    

    render() {
        if (this.state.redirect) {
            return <Redirect to={'/bedankt'} />
          }else{
        return (
        <Container fluid>
            
        
            <Row className="participant-row">
                <Col sm={12}>
                    <Container>
                        <Col sm={12} className="participant-title survey">
                                <h2>Vragenlijst</h2> 
                                <hr/>
                                <p>
                                Beste ondernemer en partner,<br/>
                                we vragen kort uw aandacht voor de onderstaande enquête. Omdat wij onze dienstverlening aan u constant willen blijven verbeteren, hebben we uw mening nodig. Neemt u alstublieft 5 a 10 minuten de tijd om de vragenlijst volledig in te vullen. Zodat Streekproducten-Limburg een beeld krijgt over de stand van zaken van het bedrijf. 
                                    <br/><br/>De onderwerpen waar de vragen over zullen gaan zijn:
                                    <ul>
                                        <li>Uw ervaring over het inschrijfformulier</li>
                                        <li>Website en dienstverlening</li>
                                    </ul>
                                </p>
                        </Col>    

                        <Col sm={12} className="form">
                        <div className="participant-title form-question survey">
                            <h2>Vragen over website & dienstverlening</h2> 
                            <hr/>
                        </div>
                        <div className="input-field-request">
                            <div className="form-question">1. Welk cijfer geeft u als eerste indruk aan het inschrijfformulier?</div>
                             
                                    <label><input type='checkbox' checked={this.state.questions[0].value == '1' ?true:false} value="1" onChange={()=>this.checkBoxHandle(0,'1',false)}/><i class="fas fa-star"></i> <i class="far fa-star"></i> <i class="far fa-star"></i> <i class="far fa-star"></i> <i class="far fa-star"></i></label>
                                    <label><input type='checkbox' checked={this.state.questions[0].value == '2' ?true:false} value="2" onChange={()=>this.checkBoxHandle(0,'2',false)}/><i class="fas fa-star"></i> <i class="fas fa-star"></i> <i class="far fa-star"></i> <i class="far fa-star"></i> <i class="far fa-star"></i></label>
                                    <label><input type='checkbox' checked={this.state.questions[0].value == '3' ?true:false} value="3" onChange={()=>this.checkBoxHandle(0,'3',false)}/><i class="fas fa-star"></i> <i class="fas fa-star"></i> <i class="fas fa-star"></i> <i class="far fa-star"></i> <i class="far fa-star"></i></label>
                                    <label><input type='checkbox' checked={this.state.questions[0].value == '4' ?true:false} value="4" onChange={()=>this.checkBoxHandle(0,'4',false)}/><i class="fas fa-star"></i> <i class="fas fa-star"></i> <i class="fas fa-star"></i> <i class="fas fa-star"></i> <i class="far fa-star"></i></label>
                                    <label><input type='checkbox' checked={this.state.questions[0].value == '5' ?true:false} value="5" onChange={()=>this.checkBoxHandle(0,'5',false)}/><i class="fas fa-star"></i> <i class="fas fa-star"></i> <i class="fas fa-star"></i> <i class="fas fa-star"></i> <i class="fas fa-star"></i></label>
                        </div>

                        <div className="input-field-request">
                            <div className="form-question">2. Mist u onderwerpen op het inschrijfformulier? </div>
                               
                                    <label><input type='checkbox' checked={this.state.questions[1].value == 'Ja, ik mis:' ?true:false} value="Ja, ik mis:" onChange={()=>this.checkBoxHandle(1,'Ja, ik mis:',true)}/>Ja, ik mis:</label>
                                    <label><input type='checkbox' checked={this.state.questions[1].value == 'Nee' ?true:false} value="Nee" onChange={()=>this.checkBoxHandle(1,'Nee',false)}/>Nee</label>
                                <br/>
                                {this.state.questions[1].extendedWithText ?
                                    <input className="input-extension" value={this.state.questions[1].text} onChange={(event) => this.textInputHandle(1,event.target.value)} type="text" placeholder="Geef aan wat je mist.." />
                                :
                                    null
                                }
                        </div>

                        <div className="input-field-request">
                            <div className="form-question">3. Vond u het abonnementskosten bijbehorend aan deelname van de website makkelijk te vinden?</div>
                             
                                    <label><input type='checkbox' checked={this.state.questions[2].value == 'Ja' ?true:false} value="Ja" onChange={()=>this.checkBoxHandle(2,'Ja',false)}/>Ja</label>
                                    <label><input type='checkbox' checked={this.state.questions[2].value == 'Nee, omdat:' ?true:false} value="Nee, omdat:" onChange={()=>this.checkBoxHandle(2,'Nee, omdat:',true)}/>Nee, omtdat:</label>
                                <br/>
                                {this.state.questions[2].extendedWithText ?
                                    <input className="input-extension" value={this.state.questions[2].text} onChange={(event) => this.textInputHandle(2,event.target.value)} type="text" placeholder="Opmerking/ toevoeging.." />
                                :
                                    null
                                }
                        </div>

                        <div className="input-field-request">
                            <div className="form-question">4. Waren er onduidelijkheden voordat u aansloot bij Streekproducten-Limburg?</div>
                             
                                    <label><input type='checkbox' checked={this.state.questions[3].value == 'Zo ja, welke?' ?true:false} value="Zo ja, welke?" onChange={()=>this.checkBoxHandle(3,'Zo ja, welke?',true)}/>Zo ja, welke?</label>
                                    <label><input type='checkbox' checked={this.state.questions[3].value == 'Nee' ?true:false} value="Nee" onChange={()=>this.checkBoxHandle(3,'Nee',false)}/>Nee</label>
                                <br/>
                                {this.state.questions[3].extendedWithText ?
                                    <input className="input-extension" value={this.state.questions[3].text} onChange={(event) => this.textInputHandle(3,event.target.value)} type="text" placeholder="Opmerking/ toevoeging.." />
                                :
                                    null
                                }
                        </div>

                        <div className="input-field-request">
                            <div className="form-question">5. Wat was voor u de hoofdreden om zich aan te sluiten bij Streekproducten-Limburg?</div>
                             
                                    <label><input type='checkbox' checked={this.state.questions[4].value == 'Eerlijkere prijs' ?true:false} value="Eerlijkere prijs" onChange={()=>this.checkBoxHandle(4,'Eerlijkere prijs',false)}/>Eerlijkere prijs</label>
                                    <label><input type='checkbox' checked={this.state.questions[4].value == 'Vergroten klantenbestand' ?true:false} value="Vergroten klantenbestand" onChange={()=>this.checkBoxHandle(4,'Vergroten klantenbestand',false)}/>Vergroten klantenbestand</label>
                                    <label><input type='checkbox' checked={this.state.questions[4].value == 'Milieuvriendelijker' ?true:false} value="Milieuvriendelijker" onChange={()=>this.checkBoxHandle(4,'Milieuvriendelijker',false)}/>Milieuvriendelijker</label>
                                    <label><input type='checkbox' checked={this.state.questions[4].value == 'Anders:' ?true:false} value="Anders:" onChange={()=>this.checkBoxHandle(4,'Anders:',true)}/>Anders:</label>
                                <br/>
                                {this.state.questions[4].extendedWithText ?
                                    <input className="input-extension" value={this.state.questions[4].text} onChange={(event) => this.textInputHandle(4,event.target.value)} type="text" placeholder="Opmerking/ toevoeging.." />
                                :
                                    null
                                }
                        </div>

                        <div className="input-field-request">
                            <div className="form-question">6. Hoe tevreden bent u over de omslag foto?</div>
                             
                                    <label><input type='checkbox' checked={this.state.questions[5].value == 'Zeer tevreden' ?true:false} value="Zeer tevreden" onChange={()=>this.checkBoxHandle(5,'Zeer tevreden',false)}/>Zeer tevreden</label>
                                    <label><input type='checkbox' checked={this.state.questions[5].value == 'Tevreden' ?true:false} value="Tevreden" onChange={()=>this.checkBoxHandle(5,'Tevreden',false)}/>Tevreden</label>
                                    <label><input type='checkbox' checked={this.state.questions[5].value == 'Neutraal' ?true:false} value="Neutraal" onChange={()=>this.checkBoxHandle(5,'Neutraal',false)}/>Neutraal</label>
                                    <label><input type='checkbox' checked={this.state.questions[5].value == 'Ontevreden' ?true:false} value="Ontevreden" onChange={()=>this.checkBoxHandle(5,'Ontevreden',false)}/>Ontevreden</label>
                                    <label><input type='checkbox' checked={this.state.questions[5].value == 'Zeer ontevreden' ?true:false} value="Zeer ontevreden" onChange={()=>this.checkBoxHandle(5,'Zeer ontevreden',true)}/>Zeer ontevreden</label>
                                <br/>
                                {this.state.questions[5].extendedWithText ?
                                    <input className="input-extension" value={this.state.questions[5].text} onChange={(event) => this.textInputHandle(5,event.target.value)} type="text" placeholder="Opmerking/ toevoeging.." />
                                :
                                    null
                                }
                        </div>

                        <div className="input-field-request">
                            <div className="form-question">7. Hoe tevreden bent u over hoe uw logo eruitziet?</div>
                             
                                    <label><input type='checkbox' checked={this.state.questions[6].value == 'Zeer tevreden' ?true:false} value="Zeer tevreden" onChange={()=>this.checkBoxHandle(6,'Zeer tevreden',false)}/>Zeer tevreden</label>
                                    <label><input type='checkbox' checked={this.state.questions[6].value == 'Tevreden' ?true:false} value="Tevreden" onChange={()=>this.checkBoxHandle(6,'Tevreden',false)}/>Tevreden</label>
                                    <label><input type='checkbox' checked={this.state.questions[6].value == 'Neutraal' ?true:false} value="Neutraal" onChange={()=>this.checkBoxHandle(6,'Neutraal',false)}/>Neutraal</label>
                                    <label><input type='checkbox' checked={this.state.questions[6].value == 'Ontevreden' ?true:false} value="Ontevreden" onChange={()=>this.checkBoxHandle(6,'Ontevreden',false)}/>Ontevreden</label>
                                    <label><input type='checkbox' checked={this.state.questions[6].value == 'Zeer ontevreden' ?true:false} value="Zeer ontevreden" onChange={()=>this.checkBoxHandle(6,'Zeer ontevreden',true)}/>Zeer ontevreden</label>
                                <br/>
                                {this.state.questions[6].extendedWithText ?
                                    <input className="input-extension" value={this.state.questions[6].text} onChange={(event) => this.textInputHandle(6,event.target.value)} type="text" placeholder="Opmerking/ toevoeging.." />
                                :
                                    null
                                }
                        </div>

                        <div className="input-field-request">
                            <div className="form-question">8. Hoe tevreden bent u met hoe uw ingevulde tekst eruitziet?</div>
                             
                                    <label><input type='checkbox' checked={this.state.questions[7].value == 'Zeer tevreden' ?true:false} value="Zeer tevreden" onChange={()=>this.checkBoxHandle(7,'Zeer tevreden',false)}/>Zeer tevreden</label>
                                    <label><input type='checkbox' checked={this.state.questions[7].value == 'Tevreden' ?true:false} value="Tevreden" onChange={()=>this.checkBoxHandle(7,'Tevreden',false)}/>Tevreden</label>
                                    <label><input type='checkbox' checked={this.state.questions[7].value == 'Neutraal' ?true:false} value="Neutraal" onChange={()=>this.checkBoxHandle(7,'Neutraal',false)}/>Neutraal</label>
                                    <label><input type='checkbox' checked={this.state.questions[7].value == 'Ontevreden' ?true:false} value="Ontevreden" onChange={()=>this.checkBoxHandle(7,'Ontevreden',false)}/>Ontevreden</label>
                                    <label><input type='checkbox' checked={this.state.questions[7].value == 'Zeer ontevreden' ?true:false} value="Zeer ontevreden" onChange={()=>this.checkBoxHandle(7,'Zeer ontevreden',true)}/>Zeer ontevreden</label>
                                <br/>
                                {this.state.questions[7].extendedWithText ?
                                    <input className="input-extension" value={this.state.questions[7].text} onChange={(event) => this.textInputHandle(7,event.target.value)} type="text" placeholder="Opmerking/ toevoeging.." />
                                :
                                    null
                                }
                        </div>

                        <div className="input-field-request">
                            <div className="form-question">9. Zou u zelf dingen veranderen aan de opmaak/presentatie van uw ingevulde gegevens?</div>
                             
                                    <label><input type='checkbox' checked={this.state.questions[8].value == 'Ja, namelijk:' ?true:false} value="Ja, namelijk:" onChange={()=>this.checkBoxHandle(8,'Ja, namelijk:',false)}/>Ja, namelijk:</label>
                                    <label><input type='checkbox' checked={this.state.questions[8].value == 'Nee' ?true:false} value="Nee" onChange={()=>this.checkBoxHandle(8,'Nee',false)}/>Nee</label>
                                <br/>
                                {this.state.questions[8].extendedWithText ?
                                    <input className="input-extension" value={this.state.questions[8].text} onChange={(event) => this.textInputHandle(8,event.target.value)} type="text" placeholder="Opmerking/ toevoeging.." />
                                :
                                    null
                                }
                        </div>
                        <div className="participant-title form-question survey">
                            <h2>Vragen over website & dienstverlening</h2> 
                            <hr/>
                        </div>

                        <div className="input-field-request">
                            <div className="form-question">1. Hoe vindt u dat de producten worden gepresenteerd op de site?</div>
                             
                                    <label><input type='checkbox' checked={this.state.questions[9].value == '1' ?true:false} value="1" onChange={()=>this.checkBoxHandle(9,'1',false)}/><i class="fas fa-star"></i> <i class="far fa-star"></i> <i class="far fa-star"></i> <i class="far fa-star"></i> <i class="far fa-star"></i></label>
                                    <label><input type='checkbox' checked={this.state.questions[9].value == '2' ?true:false} value="2" onChange={()=>this.checkBoxHandle(9,'2',false)}/><i class="fas fa-star"></i> <i class="fas fa-star"></i> <i class="far fa-star"></i> <i class="far fa-star"></i> <i class="far fa-star"></i></label>
                                    <label><input type='checkbox' checked={this.state.questions[9].value == '3' ?true:false} value="3" onChange={()=>this.checkBoxHandle(9,'3',false)}/><i class="fas fa-star"></i> <i class="fas fa-star"></i> <i class="fas fa-star"></i> <i class="far fa-star"></i> <i class="far fa-star"></i></label>
                                    <label><input type='checkbox' checked={this.state.questions[9].value == '4' ?true:false} value="4" onChange={()=>this.checkBoxHandle(9,'4',false)}/><i class="fas fa-star"></i> <i class="fas fa-star"></i> <i class="fas fa-star"></i> <i class="fas fa-star"></i> <i class="far fa-star"></i></label>
                                    <label><input type='checkbox' checked={this.state.questions[9].value == '5' ?true:false} value="5" onChange={()=>this.checkBoxHandle(9,'5',false)}/><i class="fas fa-star"></i> <i class="fas fa-star"></i> <i class="fas fa-star"></i> <i class="fas fa-star"></i> <i class="fas fa-star"></i></label>
                        </div>

                        <div className="input-field-request">
                            <div className="form-question">2. Vind u dat de informatie van uw bedrijf op de website overzichtelijk wordt weergeven?</div>
                             
                                    <label><input type='checkbox' checked={this.state.questions[10].value == 'Ja' ?true:false} value="Ja" onChange={()=>this.checkBoxHandle(10,'Ja',false)}/>Ja</label>
                                    <label><input type='checkbox' checked={this.state.questions[10].value == 'Zo niet: Wat kan beter?' ?true:false} value="Zo niet: Wat kan beter?" onChange={()=>this.checkBoxHandle(10,'Zo niet: Wat kan beter?',true)}/>Zo niet: Wat kan beter?</label>
                                <br/>
                                {this.state.questions[10].extendedWithText ?
                                    <input className="input-extension" value={this.state.questions[10].text} onChange={(event) => this.textInputHandle(10,event.target.value)} type="text" placeholder="Opmerking/ toevoeging.." />
                                :
                                    null
                                }
                        </div>

                        <div className="input-field-request">
                            <div className="form-question">3. Hoe aantrekkelijk vindt u de lay-out van de site?</div>
                             
                                    <label><input type='checkbox' checked={this.state.questions[11].value == '1' ?true:false} value="1" onChange={()=>this.checkBoxHandle(11,'1',false)}/><i class="fas fa-star"></i> <i class="far fa-star"></i> <i class="far fa-star"></i> <i class="far fa-star"></i> <i class="far fa-star"></i></label>
                                    <label><input type='checkbox' checked={this.state.questions[11].value == '2' ?true:false} value="2" onChange={()=>this.checkBoxHandle(11,'2',false)}/><i class="fas fa-star"></i> <i class="fas fa-star"></i> <i class="far fa-star"></i> <i class="far fa-star"></i> <i class="far fa-star"></i></label>
                                    <label><input type='checkbox' checked={this.state.questions[11].value == '3' ?true:false} value="3" onChange={()=>this.checkBoxHandle(11,'3',false)}/><i class="fas fa-star"></i> <i class="fas fa-star"></i> <i class="fas fa-star"></i> <i class="far fa-star"></i> <i class="far fa-star"></i></label>
                                    <label><input type='checkbox' checked={this.state.questions[11].value == '4' ?true:false} value="4" onChange={()=>this.checkBoxHandle(11,'4',false)}/><i class="fas fa-star"></i> <i class="fas fa-star"></i> <i class="fas fa-star"></i> <i class="fas fa-star"></i> <i class="far fa-star"></i></label>
                                    <label><input type='checkbox' checked={this.state.questions[11].value == '5' ?true:false} value="5" onChange={()=>this.checkBoxHandle(11,'5',false)}/><i class="fas fa-star"></i> <i class="fas fa-star"></i> <i class="fas fa-star"></i> <i class="fas fa-star"></i> <i class="fas fa-star"></i></label>
                        </div>

                        <div className="input-field-request">
                            <div className="form-question">4. Welk cijfer geeft u de website van Streekproducten-Limburg?</div>
                             
                                    <label><input type='checkbox' checked={this.state.questions[12].value == '1' ?true:false} value="1" onChange={()=>this.checkBoxHandle(12,'1',false)}/><i class="fas fa-star"></i> <i class="far fa-star"></i> <i class="far fa-star"></i> <i class="far fa-star"></i> <i class="far fa-star"></i></label>
                                    <label><input type='checkbox' checked={this.state.questions[12].value == '2' ?true:false} value="2" onChange={()=>this.checkBoxHandle(12,'2',false)}/><i class="fas fa-star"></i> <i class="fas fa-star"></i> <i class="far fa-star"></i> <i class="far fa-star"></i> <i class="far fa-star"></i></label>
                                    <label><input type='checkbox' checked={this.state.questions[12].value == '3' ?true:false} value="3" onChange={()=>this.checkBoxHandle(12,'3',false)}/><i class="fas fa-star"></i> <i class="fas fa-star"></i> <i class="fas fa-star"></i> <i class="far fa-star"></i> <i class="far fa-star"></i></label>
                                    <label><input type='checkbox' checked={this.state.questions[12].value == '4' ?true:false} value="4" onChange={()=>this.checkBoxHandle(12,'4',false)}/><i class="fas fa-star"></i> <i class="fas fa-star"></i> <i class="fas fa-star"></i> <i class="fas fa-star"></i> <i class="far fa-star"></i></label>
                                    <label><input type='checkbox' checked={this.state.questions[12].value == '5' ?true:false} value="5" onChange={()=>this.checkBoxHandle(12,'5',false)}/><i class="fas fa-star"></i> <i class="fas fa-star"></i> <i class="fas fa-star"></i> <i class="fas fa-star"></i> <i class="fas fa-star"></i></label>
                        </div>

                        <div className="input-field-request">
                            <div className="form-question">5. Hoe professioneel vindt u de vormgeving van de website?</div>
                             
                                    <label><input type='checkbox' checked={this.state.questions[13].value == '1' ?true:false} value="1" onChange={()=>this.checkBoxHandle(13,'1',false)}/><i class="fas fa-star"></i> <i class="far fa-star"></i> <i class="far fa-star"></i> <i class="far fa-star"></i> <i class="far fa-star"></i></label>
                                    <label><input type='checkbox' checked={this.state.questions[13].value == '2' ?true:false} value="2" onChange={()=>this.checkBoxHandle(13,'2',false)}/><i class="fas fa-star"></i> <i class="fas fa-star"></i> <i class="far fa-star"></i> <i class="far fa-star"></i> <i class="far fa-star"></i></label>
                                    <label><input type='checkbox' checked={this.state.questions[13].value == '3' ?true:false} value="3" onChange={()=>this.checkBoxHandle(13,'3',false)}/><i class="fas fa-star"></i> <i class="fas fa-star"></i> <i class="fas fa-star"></i> <i class="far fa-star"></i> <i class="far fa-star"></i></label>
                                    <label><input type='checkbox' checked={this.state.questions[13].value == '4' ?true:false} value="4" onChange={()=>this.checkBoxHandle(13,'4',false)}/><i class="fas fa-star"></i> <i class="fas fa-star"></i> <i class="fas fa-star"></i> <i class="fas fa-star"></i> <i class="far fa-star"></i></label>
                                    <label><input type='checkbox' checked={this.state.questions[13].value == '5' ?true:false} value="5" onChange={()=>this.checkBoxHandle(13,'5',false)}/><i class="fas fa-star"></i> <i class="fas fa-star"></i> <i class="fas fa-star"></i> <i class="fas fa-star"></i> <i class="fas fa-star"></i></label>
                        </div>

                        <div className="input-field-request">
                            <div className="form-question">6. Op welke wijze zou u vanuit het Platform geïnformeerd willen worden?</div>
                             
                                    <label><input type='checkbox' checked={this.state.questions[14].value == 'Nieuwsbrief (via de post)' ?true:false} value="Nieuwsbrief (via de post)" onChange={()=>this.checkBoxHandle(14,'Nieuwsbrief (via de post)',false)}/>Nieuwsbrief (via de post)</label>
                                    <label><input type='checkbox' checked={this.state.questions[14].value == 'Nieuwsbrief (via de email)' ?true:false} value="Nieuwsbrief (via de email)" onChange={()=>this.checkBoxHandle(14,'Nieuwsbrief (via de email)',false)}/>Nieuwsbrief (via de email)</label>
                                    <label><input type='checkbox' checked={this.state.questions[14].value == 'Sociale media' ?true:false} value="Sociale media" onChange={()=>this.checkBoxHandle(14,'Sociale media',false)}/>Sociale media</label>
                                    <label><input type='checkbox' checked={this.state.questions[14].value == 'Telefonie' ?true:false} value="Telefonie" onChange={()=>this.checkBoxHandle(14,'Telefonie',false)}/>Telefonie</label>
                                    <label><input type='checkbox' checked={this.state.questions[14].value == 'Ik hoef niet op de hoogte gehouden te worden' ?true:false} value="Ik hoef niet op de hoogte gehouden te worden" onChange={()=>this.checkBoxHandle(14,'Ik hoef niet op de hoogte gehouden te worden',false)}/>Ik hoef niet op de hoogte gehouden te worden</label>
                                    <label><input type='checkbox' checked={this.state.questions[14].value == 'Anders:' ?true:false} value="Anders:" onChange={()=>this.checkBoxHandle(14,'Anders:',true)}/>Anders:</label>
                                <br/>
                                {this.state.questions[14].extendedWithText ?
                                    <input className="input-extension" value={this.state.questions[14].text} onChange={(event) => this.textInputHandle(14,event.target.value)} type="text" placeholder="Opmerking/ toevoeging.." />
                                :
                                    null
                                }
                        </div>

                        <div className="input-field-request">
                            <div className="form-question">7. Over welke onderwerpen zou u geïnformeerd willen worden?</div>
                             
                                    <label><input type='checkbox' checked={this.state.questions[15].value == 'Updates over nieuwe/goedkope verzendmethoden' ?true:false} value="Updates over nieuwe/goedkope verzendmethoden" onChange={()=>this.checkBoxHandle(15,'Updates over nieuwe/goedkope verzendmethoden',false)}/>Updates over nieuwe/goedkope verzendmethoden</label>
                                    <label><input type='checkbox' checked={this.state.questions[15].value == 'Milieuvriendelijkheid' ?true:false} value="Milieuvriendelijkheid" onChange={()=>this.checkBoxHandle(15,'Milieuvriendelijkheid',false)}/>Milieuvriendelijkheid</label>
                                    <label><input type='checkbox' checked={this.state.questions[15].value == 'Gepersonaliseerd overzicht over het aantal bezoeken op de site bij uw bedrijf' ?true:false} value="Gepersonaliseerd overzicht over het aantal bezoeken op de site bij uw bedrijf" onChange={()=>this.checkBoxHandle(15,'Gepersonaliseerd overzicht over het aantal bezoeken op de site bij uw bedrijf',false)}/>Gepersonaliseerd overzicht over het aantal bezoeken op de site bij uw bedrijf</label>
                                    <label><input type='checkbox' checked={this.state.questions[15].value == 'Anders:' ?true:false} value="Anders:" onChange={()=>this.checkBoxHandle(15,'Anders:',true)}/>Anders:</label>
                                <br/>
                                {this.state.questions[15].extendedWithText ?
                                    <input className="input-extension" value={this.state.questions[15].text} onChange={(event) => this.textInputHandle(15,event.target.value)} type="text" placeholder="Opmerking/ toevoeging.." />
                                :
                                    null
                                }
                        </div>

                        <div className="input-field-request">
                            <div className="form-question">8. Wat vindt u van de abonnementskosten om uw producten via de website te verkopen?</div>
                             
                                    <label><input type='checkbox' checked={this.state.questions[16].value == 'Hoog' ?true:false} value="Hoog" onChange={()=>this.checkBoxHandle(16,'Hoog',false)}/>Hoog</label>
                                    <label><input type='checkbox' checked={this.state.questions[16].value == 'Normaal' ?true:false} value="Normaal" onChange={()=>this.checkBoxHandle(16,'Normaal',false)}/>Normaal</label>
                                    <label><input type='checkbox' checked={this.state.questions[16].value == 'Laag' ?true:false} value="Laag" onChange={()=>this.checkBoxHandle(16,'Laag',false)}/>Laag</label>

                        </div>

                        <div className="input-field-request">
                            <div className="form-question">9. Bent u tevreden over het onderlinge contact tussen U/Uw bedrijf en Streekproducten-Limburg/BBO</div>
                             
                                    <label><input type='checkbox' checked={this.state.questions[17].value == 'Ja' ?true:false} value="Ja" onChange={()=>this.checkBoxHandle(17,'Ja',false)}/>Ja</label>
                                    <label><input type='checkbox' checked={this.state.questions[17].value == 'Nee' ?true:false} value="Nee" onChange={()=>this.checkBoxHandle(17,'Nee',false)}/>Nee</label>
                        </div>

                        <div className="input-field-request">
                            <div className="form-question">10. Wat verwacht u van het onderlinge contact?</div>
                             
                                    <label><input type='checkbox' checked={this.state.questions[18].value == 'Wekelijks contact' ?true:false} value="Wekelijks contact" onChange={()=>this.checkBoxHandle(18,'Wekelijks contact',false)}/>Wekelijks contact</label>
                                    <label><input type='checkbox' checked={this.state.questions[18].value == 'Vast contactpersoon' ?true:false} value="Vast contactpersoon" onChange={()=>this.checkBoxHandle(18,'Vast contactpersoon',false)}/>Vast contactpersoon</label>
                                    <label><input type='checkbox' checked={this.state.questions[18].value == 'Maandelijks overzicht van traffic vanuit BBO/streekproducten-Limburg' ?true:false} value="Maandelijks overzicht van traffic vanuit BBO/streekproducten-Limburg" onChange={()=>this.checkBoxHandle(18,'Maandelijks overzicht van traffic vanuit BBO/streekproducten-Limburg',false)}/>Maandelijks overzicht van traffic vanuit BBO/streekproducten-Limburg</label>
                                    <label><input type='checkbox' checked={this.state.questions[18].value == 'Anders:' ?true:false} value="Anders:" onChange={()=>this.checkBoxHandle(18,'Anders:',true)}/>Anders:</label>
                                <br/>
                                {this.state.questions[18].extendedWithText ?
                                    <input className="input-extension" value={this.state.questions[18].text} onChange={(event) => this.textInputHandle(18,event.target.value)} type="text" placeholder="Opmerking/ toevoeging.." />
                                :
                                    null
                                }
                        </div>

                        <div className="input-field-request">
                            <div className="form-question">11. Wat was voor u de hoofdreden om zich aan te sluiten bij Streekproducten-Limburg?</div>
                             
                                    <label><input type='checkbox' checked={this.state.questions[19].value == 'Eerlijkere prijs' ?true:false} value="Eerlijkere prijs" onChange={()=>this.checkBoxHandle(19,'Eerlijkere prijs',false)}/>Eerlijkere prijs</label>
                                    <label><input type='checkbox' checked={this.state.questions[19].value == 'Vergroten klantenbestand' ?true:false} value="Vergroten klantenbestand" onChange={()=>this.checkBoxHandle(19,'Vergroten klantenbestand',false)}/>Vergroten klantenbestand</label>
                                    <label><input type='checkbox' checked={this.state.questions[19].value == 'Milieuvriendelijker' ?true:false} value="Milieuvriendelijker" onChange={()=>this.checkBoxHandle(19,'Milieuvriendelijker',false)}/>Milieuvriendelijker</label>
                                    <label><input type='checkbox' checked={this.state.questions[19].value == 'Anders:' ?true:false} value="Anders:" onChange={()=>this.checkBoxHandle(19,'Anders:',true)}/>Anders:</label>
                                <br/>
                                {this.state.questions[19].extendedWithText ?
                                    <input className="input-extension" value={this.state.questions[19].text} onChange={(event) => this.textInputHandle(19,event.target.value)} type="text" placeholder="Opmerking/ toevoeging.." />
                                :
                                    null
                                }
                        </div>
                        <div className="input-field-request">
                            <div className="form-question">12. Staat u open voor het promoten van uw producten via sociale media?</div>
                             
                                    <label><input type='checkbox' checked={this.state.questions[20].value == 'Ja' ?true:false} value="Ja" onChange={()=>this.checkBoxHandle(20,'Ja',false)}/>Ja</label>
                                    <label><input type='checkbox' checked={this.state.questions[20].value == 'Nee' ?true:false} value="Nee" onChange={()=>this.checkBoxHandle(20,'Nee',false)}/>Nee</label>
                                
                        </div>

                        <div className="input-field-request">
                            <div className="form-question">13. Door het concept van streekproducten-Limburg zal minder transport vereist zijn. Hoeveel heeft dit uw keuze beïnvloed op het inschrijven bij Streekproducten-Limburg?</div>
                             
                                    <label><input type='checkbox' checked={this.state.questions[21].value == '1' ?true:false} value="1" onChange={()=>this.checkBoxHandle(21,'1',false)}/><i class="fas fa-star"></i> <i class="far fa-star"></i> <i class="far fa-star"></i> <i class="far fa-star"></i> <i class="far fa-star"></i></label>
                                    <label><input type='checkbox' checked={this.state.questions[21].value == '2' ?true:false} value="2" onChange={()=>this.checkBoxHandle(21,'2',false)}/><i class="fas fa-star"></i> <i class="fas fa-star"></i> <i class="far fa-star"></i> <i class="far fa-star"></i> <i class="far fa-star"></i></label>
                                    <label><input type='checkbox' checked={this.state.questions[21].value == '3' ?true:false} value="3" onChange={()=>this.checkBoxHandle(21,'3',false)}/><i class="fas fa-star"></i> <i class="fas fa-star"></i> <i class="fas fa-star"></i> <i class="far fa-star"></i> <i class="far fa-star"></i></label>
                                    <label><input type='checkbox' checked={this.state.questions[21].value == '4' ?true:false} value="4" onChange={()=>this.checkBoxHandle(21,'4',false)}/><i class="fas fa-star"></i> <i class="fas fa-star"></i> <i class="fas fa-star"></i> <i class="fas fa-star"></i> <i class="far fa-star"></i></label>
                                    <label><input type='checkbox' checked={this.state.questions[21].value == '5' ?true:false} value="5" onChange={()=>this.checkBoxHandle(21,'5',false)}/><i class="fas fa-star"></i> <i class="fas fa-star"></i> <i class="fas fa-star"></i> <i class="fas fa-star"></i> <i class="fas fa-star"></i></label>
                        </div>

                        <div className="input-field-request">
                            <div className="form-question">14. Vind u dat de informatie van uw bedrijf op de website overzichtelijk wordt weergeven?</div>
                             
                                    <label><input type='checkbox' checked={this.state.questions[22].value == 'Ja' ?true:false} value="Ja" onChange={()=>this.checkBoxHandle(22,'Ja',false)}/>Ja</label>
                                    <label><input type='checkbox' checked={this.state.questions[22].value == 'Nee' ?true:false} value="Nee" onChange={()=>this.checkBoxHandle(22,'Nee',false)}/>Nee</label>
                                
                        </div>

                        <div className="input-field-request">
                            <div className="form-question">15. Heeft u al iets gehad aan de promotie via de website?</div>
                             
                                    <label><input type='checkbox' checked={this.state.questions[23].value == 'Ja, Ik heb:' ?true:false} value="Ja, Ik heb:" onChange={()=>this.checkBoxHandle(23,'Ja, Ik heb:',true)}/>Ja, Ik heb:</label>
                                    <label><input type='checkbox' checked={this.state.questions[23].value == 'Nee' ?true:false} value="Nee" onChange={()=>this.checkBoxHandle(23,'Nee',false)}/>Nee</label>

                                <br/>
                                {this.state.questions[23].extendedWithText ?
                                    <input className="input-extension" value={this.state.questions[23].text} onChange={(event) => this.textInputHandle(23,event.target.value)} type="text" placeholder="Opmerking/ toevoeging.." />
                                :
                                    null
                                }
                        </div>
                        <div className="input-field-request">
                            <div className="form-question">16. Waaruit bestaat uw klantenbestand?</div>
                             
                                    <label><input type='checkbox' checked={this.state.questions[24].value == 'Bedrijven' ?true:false} value="Bedrijven" onChange={()=>this.checkBoxHandle(24,'Bedrijven',false)}/>Bedrijven</label>
                                    <label><input type='checkbox' checked={this.state.questions[24].value == 'Consumenten' ?true:false} value="Consumenten" onChange={()=>this.checkBoxHandle(24,'Consumenten',false)}/>Consumenten</label>
                                    <label><input type='checkbox' checked={this.state.questions[24].value == 'Overheid/gemeenten' ?true:false} value="Overheid/gemeenten" onChange={()=>this.checkBoxHandle(24,'Overheid/gemeenten',false)}/>Overheid/gemeenten</label>
                                    <label><input type='checkbox' checked={this.state.questions[24].value == 'Groothandel' ?true:false} value="Groothandel" onChange={()=>this.checkBoxHandle(24,'Groothandel',false)}/>Groothandel</label>
                                    <label><input type='checkbox' checked={this.state.questions[24].value == 'Anders:' ?true:false} value="Anders:" onChange={()=>this.checkBoxHandle(24,'Anders:',true)}/>Anders:</label>
                                <br/>
                                {this.state.questions[24].extendedWithText ?
                                    <input className="input-extension" value={this.state.questions[24].text} onChange={(event) => this.textInputHandle(24,event.target.value)} type="text" placeholder="Opmerking/ toevoeging.." />
                                :
                                    null
                                }
                        </div>

                        <div className="input-field-request">
                            <div className="form-question">17. Wat zou u willen toevoegen aan de website?</div>
                             
                                    <label><input type='checkbox' checked={this.state.questions[25].value == 'FAQ/Q&A' ?true:false} value="FAQ/Q&A" onChange={()=>this.checkBoxHandle(25,'FAQ/Q&A',false)}/>FAQ/Q&A</label>
                                    <label><input type='checkbox' checked={this.state.questions[25].value == 'Chat' ?true:false} value="Chat" onChange={()=>this.checkBoxHandle(25,'Chat',false)}/>Chat</label>
                                    <label><input type='checkbox' checked={this.state.questions[25].value == 'Polls' ?true:false} value="Polls" onChange={()=>this.checkBoxHandle(25,'Polls',false)}/>Polls</label>
                                    <label><input type='checkbox' checked={this.state.questions[25].value == 'Anders:' ?true:false} value="Anders:" onChange={()=>this.checkBoxHandle(25,'Anders:',true)}/>Anders:</label>
                                <br/>
                                {this.state.questions[25].extendedWithText ?
                                    <input className="input-extension" value={this.state.questions[25].text} onChange={(event) => this.textInputHandle(25,event.target.value)} type="text" placeholder="Opmerking/ toevoeging.." />
                                :
                                    null
                                }
                        </div>

                        <div className="input-field-request">
                            <div className="form-question">18. Begrijpt u het concept/idee van de streekproducten gids?</div>
                             
                                    <label><input type='checkbox' checked={this.state.questions[26].value == 'Ja' ?true:false} value="Ja" onChange={()=>this.checkBoxHandle(26,'Ja',false)}/>Ja</label>
                                    <label><input type='checkbox' checked={this.state.questions[26].value == 'Nee' ?true:false} value="Nee" onChange={()=>this.checkBoxHandle(26,'Nee',false)}/>Nee</label>
                                
                        </div>

                        <div className="input-field-request">
                            <div className="form-question">19. Staat u open voor een vervolggesprek?</div>
                             
                                    <label><input type='checkbox' checked={this.state.questions[27].value == 'Ja' ?true:false} value="Ja" onChange={()=>this.checkBoxHandle(27,'Ja',true)}/>Ja</label>
                                    <label><input type='checkbox' checked={this.state.questions[27].value == 'Nee' ?true:false} value="Nee" onChange={()=>this.checkBoxHandle(27,'Nee',false)}/>Nee</label>
                                
                                {this.state.questions[27].extendedWithText ?
                                    <input className="input-extension" value={this.state.questions[27].text} onChange={(event) => this.textInputHandle(27,event.target.value)} type="text" placeholder="E-mailadres" />
                                :
                                    null
                                }
                        </div>

                        <div className="input-field-request">
                            <div className="form-question">20. Wat vond u van de lengte van de enquête?</div>
                             
                                    <label><input type='checkbox' checked={this.state.questions[28].value == 'Te kort' ?true:false} value="Te kort" onChange={()=>this.checkBoxHandle(28,'Te kort',false)}/>Te kort</label>
                                    <label><input type='checkbox' checked={this.state.questions[28].value == 'Precies goed' ?true:false} value="Precies goed" onChange={()=>this.checkBoxHandle(28,'Precies goed',false)}/>Precies goed</label>
                                    <label><input type='checkbox' checked={this.state.questions[28].value == 'Te lang' ?true:false} value="Te lang" onChange={()=>this.checkBoxHandle(28,'Te lang',false)}/>Te lang</label>
                                
                        </div>
                        <div className="button-div">
                            {this.state.loading ?
                                <button className="cta-menu-button"> <Loader type="Bars" color="#fff" height={20} width={20} /></button>
                            :
                                <button className="cta-menu-button" onClick={()=>this.handleSubmitForm()}>Verzend formulier</button>
                            }
                        </div>
                        {this.state.error !=''?
                            <div className="error-message-survey">
                                <p>{this.state.error}</p>
                            </div>
                        :
                            null
                        }
                       
                        
                        </Col>             

                        
                        
                    </Container>
                </Col>
            </Row>

            
        
            
        </Container>

        );}
    }
};

export default EnqueteComponent;
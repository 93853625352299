import React from "react";
import { Page, Text } from "@react-pdf/renderer";

const ToCContext = React.createContext({
  toq: [],
  add: () => {}
});

export class ToCProvider extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      toq: [],
      add: title => {
        if (!this.state.toq.includes(title))
          this.setState((state, props) => ({ toq: [...state.toq, title] }));
          console.log(title)
      }
    };
  }

  render() {
    return <ToCContext.Provider value={this.state} {...this.props} />;
  }
}

export const ToC = () => {
  return (
    <Page>
      <Text>Inhoudsopgave</Text>
      <ToCContext.Consumer>
        {({ toq }) => {
          return toq.map(item => <Text key={item}>{item} </Text>);
        }}
      </ToCContext.Consumer>
    </Page>
  );
};

class InnerTitle extends React.Component {
  componentDidMount() {
    this.props.add(this.props.children);
  }

  render() {
    return <Text>{this.props.children}</Text>;
  }
}

export const Title = ({ ...props }) => (
  <ToCContext.Consumer>
    {({ add }) => <InnerTitle {...props} add={add} />}
  </ToCContext.Consumer>
);

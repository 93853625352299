import React from "react";
import { Container, Row, Col } from "shards-react";
import AdminPanelIndex from "../../components/adminpanel";

import PageTitle from "../../components/common/PageTitle";
import FooterComponent from "../../components/Footer/FooterComponent";
import FrontendHomeComponent from "../../components/frontend";
import ParticipantIndex from "../../components/frontend/participantIndex";
import ParticipantIndexPostal from "../../components/frontend/postalSearchcomponent";
import HeaderComponent from "../../components/Header/headerComponent";
import ProductsIndex from "../../components/products";



const ParticipantPostalView = (props) => (
    <div>
    <HeaderComponent/>
    <ParticipantIndexPostal  data={props.location.state}/>
    <FooterComponent/>
    
    </div>
);

export default ParticipantPostalView;

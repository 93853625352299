import React from "react";
import { Container, Row, Col } from "shards-react";

import PageTitle from "../../components/common/PageTitle";
import OrdersIndex from "../../components/orders";
import ProfileIndex from "../../components/profile";



const Profile = () => (
  <Container fluid className="main-content-container px-4">
    <Row noGutters className="page-header py-4">
      <PageTitle title="Mijn profiel" subtitle="Beter bij ons" md="12" className="ml-sm-auto mr-sm-auto" />
    </Row>
    <ProfileIndex />
    
  </Container>
);

export default Profile;

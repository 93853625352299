import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as firebase from 'firebase';
import 'firebase/auth';
import * as serviceWorker from './serviceWorker';
import {AuthProvider} from "./Auth";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"

/*prod 
var config ={
    apiKey: "AIzaSyB2i07o_A2Hqo9Wk9mV35KcTLmySxoO29U",
    authDomain: "productie-omgeving.firebaseapp.com",
    databaseURL: "https://productie-omgeving-default-rtdb.firebaseio.com",
    projectId: "productie-omgeving",
    storageBucket: "productie-omgeving.appspot.com",
    messagingSenderId: "274979395553",
    appId: "1:274979395553:web:d1367b9ccdaa3d44e724a0"
};*/

//dev
var config ={
  apiKey: "AIzaSyB7G-vcpfhNmIdFl-RkodKDYsDY1VTQZV0",
  authDomain: "web-app-bbo-prod.firebaseapp.com",
  databaseURL: "https://web-app-bbo-prod-default-rtdb.firebaseio.com",
  projectId: "web-app-bbo-prod",
  storageBucket: "web-app-bbo-prod.appspot.com",
  messagingSenderId: "919860289135",
  appId: "1:919860289135:web:f964262df4ea4fff6af79a"
}
firebase.initializeApp(config);


ReactDOM.render(
<AuthProvider>
<App />
</AuthProvider>

, 
document.getElementById('root'));



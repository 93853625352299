import React, { Component } from 'react';
import { Row, Col, Card, CardHeader, CardBody, Button } from "shards-react";
import Firebase from 'firebase';
import { Link } from 'react-router-dom';
import { StaticRouter } from 'react-router';
import { set } from 'react-ga';
import { Satellite } from '@material-ui/icons';


class ChangesIndex extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            changes:[],
            changeAvaideble:false,
            selectedProduct:[]
        };
    }

    



    componentDidMount() {
 

        let ref = Firebase.database().ref('/streekproducten-temp/');
        ref.on('value' , snapshot => {
            this.setState({changes:[]})
            snapshot.forEach((childSnap) => {
                let state = childSnap.val();
                    if(state){
                        this.setState(prevState =>({
                            changes: [...prevState.changes, state],
                            changeAvaideble:true
                        }))
                    }else{
                        this.setState({
                            changeAvaideble:false
                        })
                    }
              
             }) 
      
            
        });
      

        
    }

    changeActive(product){

        for(let k = 0; k <this.state.changes.length; k++){
                this.state.changes[k].checked = false;
                this.state.changes[k].active = 'not-active';
                
                this.setState({
                    changes: this.state.changes,
                    selectedProduct: [],
                    isProductSelected:false
                })
               
             
        }

        for(let i = 0; i < this.state.changes.length; i++){
            if(this.state.changes[i].id === product.id && this.state.changes[i].checked === false){
                this.state.changes[i].active = 'active';
                this.state.changes[i].checked = true;
                this.setState({
                    changes: this.state.changes,
                    selectedProduct: this.state.changes[i],
                    isProductSelected:true
                })
            }else if(this.state.changes[i].id === product.id && this.state.changes[i].checked === true){
                this.state.changes[i].active = 'not-active';
                this.state.changes[i].checked = false;
                this.setState({
                    changes: this.state.changes,
                })
            }
        }
    }




    

    render() {
        return (
            <div>
            <Row className="col-sm-12">
                <Col sm={12} className="products-actions">
                    {this.state.isProductSelected ?
                    <div>
                    <Link to={{pathname: `/aanmeldingen/beoordelen`,state: {data: this.state.selectedProduct}}} >Meldingen bekijken</Link>
                    </div>:null}
                </Col>
                <Col sm={12} className="products-table">
                {this.state.changeAvaideble?
            <table>
                <tr>
                    <th><input type="checkbox" disabled/></th>
                    <th>Bedrijfsnaam</th>
                    <th>Telefoon</th>
                    <th>E-mail</th>
                    <th>Contactpersoon</th>
                </tr>

                {this.state.changes.map((product)=>(
                    <tr className={product.active}>
                    <td><input type="checkbox" checked={product.checked} onClick={()=>this.changeActive(product)}/></td>
                    <td>{product.companyName}</td>
                    <td>{product.companyPhone}</td>
                    <td>{product.companyMail}</td>
                    <td>{ product.companyContact}</td>
                </tr>
                ))}
                
            
            </table>:null}
</Col>
   
            </Row>
            </div>



        );
    }
};

export default ChangesIndex;
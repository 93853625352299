import React, { Component } from 'react';
import { Row, Col, Card, CardHeader, CardBody, Button } from "shards-react";
import Firebase from 'firebase';
import { Link } from 'react-router-dom';
import { keys } from '@material-ui/core/styles/createBreakpoints';


class MessageIndex extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            messages:[],
            clientUUID:'-ohlnfwebk',

            
        };
    }

    getMessages(city){


        let ref = Firebase.database().ref('/cities/'+city+'/shops/'+Firebase.auth().currentUser.uid+'/messages');
        ref.on('value' , snapshot => {
           
            snapshot.forEach((childSnap) => {
            let state = childSnap.val();
                this.setState(prevState => ({
                    messages: [...prevState.messages, state]
                }))  
            })
        });
    }



    componentDidMount() {
        this.setState({
            clientUUID: Firebase.auth().currentUser.uid
        })
        let ref = Firebase.database().ref('/users/'+Firebase.auth().currentUser.uid);
        ref.on('value' , snapshot => {
           
   
            let state = snapshot.val();
                this.setState({
                    city: state.city
                }) 
                this.getMessages(state.city);
               
            
        });
     
    }



    changeNew(id){

        Firebase.database().ref('/cities/-onefoefw/shops/'+Firebase.auth().currentUser.uid+'/messages/'+id).update({
            new: false,
        }).then((data)=>{

        }).catch((error)=>{
            //error callback
            console.log('error ' , error)
        })

    }
    

    render() {
        return (
            <div>
            <Row className="col-sm-12">
                {/*<Col sm={12} className="products-actions">
                    <Link>Product bewerken</Link>
                    <Link>Meer acties</Link>
                    <Link className="align-button-right" to={{pathname: `/producten/toevoegen`}} >Product toevoegen</Link>
        </Col>*/}
                <Col sm={12} className="message-table">
                <h2>Nieuwe berichten</h2>
            <table>
                <tr>
                    <th>Afzender</th>
                    <th>Onderwerp</th>
                    <th>Datum</th>
                    <th></th>
                </tr>
                {this.state.messages.filter(item => item.new == true ).map((l, i) => (
                <tr >
                    <td>{l.name}</td>
                    <td>{l.subject}</td>
                    <th>{l.dateDay} - {l.dateMonth} - {l.dateYear}</th>
                    <td><Link to={{pathname: `/berichten/bekijken`,state: {data: l}}}><Button onClick={()=>this.changeNew(l.id)}>Bekijk</Button></Link></td>
                </tr>
                  ))}
            </table>
</Col>
   
<Col sm={12} className="message-table">
                <h2>Gelezen berichten</h2>
            <table>
                <tr>
                    <th>Afzender</th>
                    <th>Onderwerp</th>
                    <th>Datum</th>
                    <th></th>
                </tr>
                {this.state.messages.filter(item => item.new == false ).map((l, i) => (
                    <tr >
                        <td>{l.name}</td>
                        <td>{l.subject}</td>
                        <th>{l.dateDay} - {l.dateMonth} - {l.dateYear}</th>
                        <td><Link to={{pathname: `/berichten/bekijken`,state: {data: l}}}>Bekijk</Link></td>
                    </tr>
                ))}
            </table>
</Col>
   
            </Row>
            </div>



        );
    }
};

export default MessageIndex;
import React, { Component } from 'react';
import { Row, Col, Card, CardHeader, CardBody, Button } from "shards-react";
import Firebase from 'firebase';
import { Link } from 'react-router-dom';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { Satellite } from '@material-ui/icons';
import toast, { Toaster } from 'react-hot-toast';

class ProfileIndex extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            companyDesc:'',
            image:[],
            imageCompany:[],
            uploading:false,
            uploaded:false,
            branch:'',
            url:'',
            imageAvailable: true,
            monday:[],
            tuesday:[],
            wednesday:[],
            thursday:[],
            friday:[],
            saturday:[],
            sunday:[],
            timeSlotsDay:'',
            timeInfo:'',
            clientUUID:'',
            companyName:'',
            companyNameOfficial:'',
            branch:'selected',
            phone:'',
            street:'',
            zip:'',
            city:'',
            companyImage:'',
            companyIamgeImpression:'',
            image:[],
            city:'',
            shopLocation:'',
            imageAvailableCompany:false,
            contactPersonclient:'',
            contactPersonclientMail:'',
            provincie:'',
            email:'',
            name:'',
            kvk:'',
            btw:'',
            changes:false,
            
            };
    }

    saveSettings(){
        Firebase.database().ref('/cities/'+this.state.city+'/shops/'+Firebase.auth().currentUser.uid).update({
            name: this.state.companyName ? this.state.companyName:null,
            desc: this.state.companyDesc ? this.state.companyDesc :null,
            branche: this.state.branch ? this.state.branch :null,
            officialName: this.state.companyNameOfficial ? this.state.companyNameOfficial:null,
            phone: this.state.phone ? this.state.phone :null,
            adres: this.state.street ? this.state.street :null,
            zip: this.state.zip ? this.state.zip :null,
            city: this.state.shopLocation ? this.state.shopLocation :null,
            companyIamge: this.state.companyImage ? this.state.companyImage :null,
            contactPersonclient: this.state.contactPersonclient ? this.state.contactPersonclient :null,
            contactPersonclientMail: this.state.contactPersonclientMail ? this.state.contactPersonclientMail :null,
            provincie: this.state.provincie ? this.state.provincie :null,
            email: this.state.email ?this.state.email:null,
            name: this.state.name ?this.state.name:null,
            kvk: this.state.kvk ?this.state.kvk:null,
             btw: this.state.btw ?this.state.btw:null
    
        }).then((data)=>{
            toast.success('Instellingen opgeslagen');
            this.setState({
                changes:false
            })

        }).catch((error)=>{
            //error callback
            console.log('error ' , error)
        })

    }
    getInformation(city){
       
    
        let ref = Firebase.database().ref('/cities/'+city+'/shops/'+Firebase.auth().currentUser.uid);
        ref.on('value' , snapshot => {
            let state = snapshot.val();

            console.log(state.email);
            this.setState({
                companyName: state.name,
                companyDesc: state.desc ? state.desc: '',
                branch: state.branche,
                companyNameOfficial: state.officialName,
                phone: state.phone,
                street: state.adres,
                zip: state.zip,
                shopLocation: state.city,
                companyImage: state.companyIamge,
                companyIamgeImpression: state.companyIamgeImpression,
                imageAvailable: state.companyIamge ? true : false,
                imageAvailableCompany: state.companyIamgeImpression ?true: false,
                contactPersonclient: state.contactPersonclient ? state.contactPersonclient :null,
                contactPersonclientMail: state.contactPersonclientMail ? state.contactPersonclientMail :null,
                provincie: state.provincie ? state.provincie :null,
                email: state.email ?state.email:null,
                name: state.name ?state.name:null,
                kvk: state.kvk ?state.kvk:null,
                 btw: state.btw ?state.btw:null

            })
        


                
            
            });

        let monday = Firebase.database().ref('/cities/'+city+'/shops/'+Firebase.auth().currentUser.uid+'/timeslots/monday');
        monday.on('value' , snapshot => {
            this.state.monday =[];
            snapshot.forEach((childSnap) => {
            let state = childSnap.val();

                this.setState(prevState => ({
                    monday: [...prevState.monday, state]
                }))  

        })
        
        });
        let tuesday = Firebase.database().ref('/cities/'+city+'/shops/'+Firebase.auth().currentUser.uid+'/timeslots/tuesday');
        tuesday.on('value' , snapshot => {
            this.state.tuesday =[];
            snapshot.forEach((childSnap) => {
            let state = childSnap.val();

                this.setState(prevState => ({
                    tuesday: [...prevState.tuesday, state]
                }))  

        })
        
        });
        let wednesday = Firebase.database().ref('/cities/'+city+'/shops/'+Firebase.auth().currentUser.uid+'/timeslots/wednesday');
        wednesday.on('value' , snapshot => {
            this.state.wednesday =[];
            snapshot.forEach((childSnap) => {
            let state = childSnap.val();

                this.setState(prevState => ({
                    wednesday: [...prevState.wednesday, state]
                }))  

        })
        
        });
        let thursday = Firebase.database().ref('/cities/'+city+'/shops/'+Firebase.auth().currentUser.uid+'/timeslots/thursday');
        thursday.on('value' , snapshot => {
            this.state.thursday =[];
            snapshot.forEach((childSnap) => {
            let state = childSnap.val();

                this.setState(prevState => ({
                    thursday: [...prevState.thursday, state]
                }))  

        })
        
        });
        let friday = Firebase.database().ref('/cities/'+city+'/shops/'+Firebase.auth().currentUser.uid+'/timeslots/friday');
        friday.on('value' , snapshot => {
            this.state.friday =[];
            snapshot.forEach((childSnap) => {
            let state = childSnap.val();

                this.setState(prevState => ({
                    friday: [...prevState.friday, state]
                }))  

        })
        
        });
        let saturday = Firebase.database().ref('/cities/'+city+'/shops/'+Firebase.auth().currentUser.uid+'/timeslots/saturday');
        saturday.on('value' , snapshot => {
            this.state.saturday =[];
            snapshot.forEach((childSnap) => {
            let state = childSnap.val();

                this.setState(prevState => ({
                    saturday: [...prevState.saturday, state]
                }))  

        })
        
        });
        let sunday = Firebase.database().ref('/cities/'+city+'/shops/'+Firebase.auth().currentUser.uid+'/timeslots/sunday');
        sunday.on('value' , snapshot => {
            this.state.sunday =[];
            snapshot.forEach((childSnap) => {
            let state = childSnap.val();

                this.setState(prevState => ({
                    sunday: [...prevState.sunday, state]
                }))  

        })
        
        });
    }
   async componentWillMount(){
        this.setState({
            clientUUID: Firebase.auth().currentUser.uid
        })

        let ref = Firebase.database().ref('/users/'+Firebase.auth().currentUser.uid);
     ref.on('value' , snapshot => {
           
   
            let state = snapshot.val();
                this.setState({
                    city: state.city
                }) 
                this.getInformation(state.city)
            
        });
       
    }

    handleChange(e){
        this.setState({
          image: e.target.files[0],
          errorMessageLogo:''
        })
      }

      handleChangeCompany(e){
        this.setState({
          imageCompany: e.target.files[0],
          errorMessageLogo:''
        })
      }


    makeid(length) {
        var result           = '';
        var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        var charactersLength = characters.length;
        for ( var i = 0; i < length; i++ ) {
           result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
     }

      handleUpload(){
        const uniqueId =this.makeid(12);
      const {image} = this.state;
      this.setState({
        uploaded:true
      })

      console.log(image)

      if(image != null){
          const uploadTask = Firebase.storage().ref(`images/${Firebase.auth().currentUser.uid}/${uniqueId}${image.name}`).put(image);
          uploadTask.on(
            'state_changed',
            snapshot => {
            },
            error => {
              console.log(error);
            },
            () => {
              Firebase.storage()
                .ref('images/'+Firebase.auth().currentUser.uid)
                .child(uniqueId+image.name)
                .getDownloadURL()
                .then(url => {
                    Firebase.database().ref('/cities/'+this.state.city+'/shops/'+Firebase.auth().currentUser.uid).update({
                        companyIamge: url,
                    }).then((data)=>{
                        toast.success('Afbeedling geupload');
                        this.setState({
                            changes:true
                        })
                       
                    }).catch((error)=>{
                        //error callback
                        console.log('error ' , error)
                    })
                });
            }
          );
        }else{
        this.setState({
          errorMessageLogo: 'Er is geen foto geselecteert.',
          uploaded: false,
          uploading: false
        })
        
      }
  
      
    }

    handleUploadCompany(){
        const uniqueId =this.makeid(12);
      const {imageCompany} = this.state;
      this.setState({
        uploaded:true
      })



      if(imageCompany != null){
          const uploadTask = Firebase.storage().ref(`images/${Firebase.auth().currentUser.uid}/${uniqueId}${imageCompany.name}`).put(imageCompany);
          uploadTask.on(
            'state_changed',
            snapshot => {
            },
            error => {
              console.log(error);
            },
            () => {
              Firebase.storage()
                .ref('images/'+Firebase.auth().currentUser.uid)
                .child(uniqueId+imageCompany.name)
                .getDownloadURL()
                .then(url => {
                    Firebase.database().ref('/cities/'+this.state.city+'/shops/'+Firebase.auth().currentUser.uid).update({
                        companyIamgeImpression: url,
                    }).then((data)=>{
                        toast.success('Afbeedling geupload');
                        this.setState({
                            changes:true
                        })       
                    }).catch((error)=>{
                        //error callback
                        console.log('error ' , error)
                    })
                });
            }
          );
        }else{
        this.setState({
          errorMessageLogo: 'Er is geen foto geselecteert.',
          imageCompany:[],
          uploaded: false,
          uploading: false
        })
        
      }
  
      
    }

      changeTimeSlots(){
          console.log(this.state.timeInfo)
          if(this.state.timeSlotsDay === "monday"){
            var key= Firebase.database().ref().child('/cities/'+this.state.city+'/shops/'+Firebase.auth().currentUser.uid+'/timeslots/monday/').push().key;
            Firebase.database().ref('/cities/'+this.state.city+'/shops/'+Firebase.auth().currentUser.uid+'/timeslots/monday/'+key).set({
           
                id: key,
                slot: this.state.timeInfo,
                order: this.state.monday.length+1
        
            }).then((data)=>{
                this.setState({
                    timeSlotsDay:'selected',
                    timeInfo:''
                })

                toast.success('De openingstijden op maandag zijn verwerkt!');
                

            }).catch((error)=>{
                //error callback
                console.log('error ' , error)
            })

          }else  if(this.state.timeSlotsDay === "tuesday"){
            var key= Firebase.database().ref().child('/cities/'+this.state.city+'/shops/'+Firebase.auth().currentUser.uid+'/timeslots/tuesday/').push().key;
            Firebase.database().ref('/cities/'+this.state.city+'/shops/'+Firebase.auth().currentUser.uid+'/timeslots/tuesday/'+key).set({
           
                id: key,
                slot: this.state.timeInfo,
                order: this.state.tuesday.length+1
        
            }).then((data)=>{
                this.setState({
                    timeSlotsDay:'selected',
                    timeInfo:''
                })
                toast.success('De openingstijden op dinsdag zijn verwerkt!');

            }).catch((error)=>{
                //error callback
                console.log('error ' , error)
            })

        }
        else  if(this.state.timeSlotsDay === "wednesday"){
            var key= Firebase.database().ref().child('/cities/'+this.state.city+'/shops/'+Firebase.auth().currentUser.uid+'/timeslots/wednesday/').push().key;
            Firebase.database().ref('/cities/'+this.state.city+'/shops/'+Firebase.auth().currentUser.uid+'/timeslots/wednesday/'+key).set({
           
                id: key,
                slot: this.state.timeInfo,
                order: this.state.wednesday.length+1
        
            }).then((data)=>{
                this.setState({
                    timeSlotsDay:'selected',
                    timeInfo:''
                })
                toast.success('De openingstijden op woensdag zijn verwerkt!');

            }).catch((error)=>{
                //error callback
                console.log('error ' , error)
            })
        }
        else  if(this.state.timeSlotsDay === "thursday"){
            var key= Firebase.database().ref().child('/cities/'+this.state.city+'/shops/'+Firebase.auth().currentUser.uid+'/timeslots/thursday/').push().key;
            Firebase.database().ref('/cities/'+this.state.city+'/shops/'+Firebase.auth().currentUser.uid+'/timeslots/thursday/'+key).set({
           
                id: key,
                slot: this.state.timeInfo,
                order: this.state.thursday.length+1
        
            }).then((data)=>{
                this.setState({
                    timeSlotsDay:'selected',
                    timeInfo:''
                })
                toast.success('De openingstijden op donderdag zijn verwerkt!');

            }).catch((error)=>{
                //error callback
                console.log('error ' , error)
            })
        }
        else  if(this.state.timeSlotsDay === "friday"){
            var key= Firebase.database().ref().child('/cities/'+this.state.city+'/shops/'+Firebase.auth().currentUser.uid+'/timeslots/friday/').push().key;
            Firebase.database().ref('/cities/'+this.state.city+'/shops/'+Firebase.auth().currentUser.uid+'/timeslots/friday/'+key).set({
           
                id: key,
                slot: this.state.timeInfo,
                order: this.state.friday.length+1
        
            }).then((data)=>{
                this.setState({
                    timeSlotsDay:'selected',
                    timeInfo:''
                })
                toast.success('De openingstijden op vrijdag zijn verwerkt!');

            }).catch((error)=>{
                //error callback
                console.log('error ' , error)
            })
        }
        else  if(this.state.timeSlotsDay === "saturday"){
            var key= Firebase.database().ref().child('/cities/'+this.state.city+'/shops/'+Firebase.auth().currentUser.uid+'/timeslots/saturday/').push().key;
            Firebase.database().ref('/cities/'+this.state.city+'/shops/'+Firebase.auth().currentUser.uid+'/timeslots/saturday/'+key).set({
           
                id: key,
                slot: this.state.timeInfo,
                order: this.state.saturday.length+1
        
            }).then((data)=>{
                this.setState({
                    timeSlotsDay:'selected',
                    timeInfo:''
                })
                toast.success('De openingstijden op zaterdag zijn verwerkt!');

            }).catch((error)=>{
                //error callback
                console.log('error ' , error)
            })
        }
        else  if(this.state.timeSlotsDay === "sunday"){
            var key= Firebase.database().ref().child('/cities/'+this.state.city+'/shops/'+Firebase.auth().currentUser.uid+'/timeslots/sunday/').push().key;
            Firebase.database().ref('/cities/'+this.state.city+'/shops/'+Firebase.auth().currentUser.uid+'/timeslots/sunday/'+key).set({
           
                id: key,
                slot: this.state.timeInfo,
                order: this.state.sunday.length+1
        
            }).then((data)=>{
                this.setState({
                    timeSlotsDay:'selected',
                    timeInfo:''
                })

                toast.success('De openingstijden op zondag zijn verwerkt!');

            }).catch((error)=>{
                //error callback
                console.log('error ' , error)
            })
        }
          
          
      }

      deleteMonday(id){
       
        Firebase.database().ref('/cities/'+this.state.city+'/shops/'+Firebase.auth().currentUser.uid+'/timeslots/monday/'+id).remove();
        toast.error('De openingstijden op maandag zijn verwijderd.');
      }
      deleteTuesday(id){
        Firebase.database().ref('/cities/'+this.state.city+'/shops/'+Firebase.auth().currentUser.uid+'/timeslots/tuesday/'+id).remove();
        toast.error('De openingstijden op dinsdag zijn verwijderd.');
      }
      deleteWednesday(id){
        Firebase.database().ref('/cities/'+this.state.city+'/shops/'+Firebase.auth().currentUser.uid+'/timeslots/wednesday/'+id).remove();
        toast.error('De openingstijden op woensdag zijn verwijderd.');
      }
      deleteThursday(id){
        Firebase.database().ref('/cities/'+this.state.city+'/shops/'+Firebase.auth().currentUser.uid+'/timeslots/thursday/'+id).remove();
        toast.error('De openingstijden op donderdag zijn verwijderd.');

      }
      deleteFriday(id){
        Firebase.database().ref('/cities/'+this.state.city+'/shops/'+Firebase.auth().currentUser.uid+'/timeslots/friday/'+id).remove();
        toast.error('De openingstijden op vrijdag zijn verwijderd.');
      }
      deleteSaturday(id){
        Firebase.database().ref('/cities/'+this.state.city+'/shops/'+Firebase.auth().currentUser.uid+'/timeslots/saturday/'+id).remove();
        toast.error('De openingstijden op zaterdag zijn verwijderd.');
      }
      deleteSunday(id){
        Firebase.database().ref('/cities/'+this.state.city+'/shops/'+Firebase.auth().currentUser.uid+'/timeslots/sunday/'+id).remove();
        toast.error('De openingstijden op zondahg zijn verwijderd.');
      }


    

    render() {
        return (
            <div>     <Toaster />
                
            <Row className="col-sm-12">
                <Col sm={4} className="input-colom-profile-transparant">
                    <h2>Winkelgegevens</h2>
                    <p>Beter bij ons en je klanten zullen deze informatie gebruiken om contact met je op te nemen.</p>
                    {this.state.imageAvailable ?
                     <img src={this.state.companyImage} />
                    :null}
                    {this.state.imageAvailableCompany ?
                     <img className="impression-picture" src={this.state.companyIamgeImpression} />
                    :null}

                </Col>
                <Col sm={8} className="input-colom-profile add-company-logo">
                    <label>Winkelnaam</label><br/>
                    <input type="text" value={this.state.companyName} onChange={(event)=>this.setState({companyName:event.target.value,changes:true})}/><br/><br/>
                    <label>Bedrijfslogo</label><br/>
                    <input type="file"  accept="image/gif, image/jpeg, image/png" onChange={(event) => this.handleChange(event)} /><Button className="add-button-settings" onClick={()=>this.handleUpload()}>Voeg afbeelding toe</Button> <br/><br/>
                    <label>Sfeerfoto bedrijf</label><br/>
                    <input type="file" accept="image/gif, image/jpeg, image/png" onChange={(event) => this.handleChangeCompany(event)} /><Button className="add-button-settings" onClick={()=>this.handleUploadCompany()}>Voeg afbeelding toe</Button><br/>
                    <span className="descript-undertitle">Op de foto sta jij als winkelier en jouw producten centraal. We zoeken een foto waar jezelf opstaat vanaf je midden, centraal in je winkel van voor tot achter scherp waarop jezelf lacht en je producten goed te zien zijn. De foto moet in landscape (liggend) genomen zijn.</span> <br/><br/>
                    <label>Korte winkel omschrijving</label><br/>
                    <CKEditor
                    config={{placeholder: " Vul hier een korte winkel omschrijving in. Dit is het eerste wat een klant leest over jouw winkel. Dus schrijf vooral waarom iemand bij jou moet kopen en wat je aan assortiment te bieden hebt."}} 
                                editor={ ClassicEditor }
                                data={this.state.companyDesc}
                                
                                onChange={ ( event, editor ) => {
                                    const data = editor.getData();
                                    this.setState({companyDesc: data,changes:true})
                                } }

                            /><br/>

                    <label>Contactpersoon van de winkel (naam)</label><br/>
                    <input type="text" value={this.state.contactPersonclient} onChange={(event)=>this.setState({contactPersonclient:event.target.value,changes:true})}/><br/><br/>
                    <label>Contactpersoon van de winkel (E-mail)</label><br/>
                    <input type="text" value={this.state.contactPersonclientMail} onChange={(event)=>this.setState({contactPersonclientMail:event.target.value,changes:true})}/><br/><br/>
                    <label>branch</label><br/>
                    <select value={this.state.branch} onChange={(event)=>this.setState({branch: event.target.value,changes:true})}>
                    <option value="selected" selected="selected" disabled="disabled">Selecteer een branch</option>
                        <option value="Kleding">Kleding, Schoenen & Lederwaren</option>
                        <option value="Internet">Internet, Tv & Bellen</option>
                        <option value="Wonen">Wonen & Inrichten</option>
                        <option value="Auto">Auto's, Brommers & Fietsen</option>
                        <option value="Flora">Flora & Fauna</option>
                        <option value="Uiterlijk">Uiterlijke Verzorging</option>
                        <option value="Zorg">Zorg & Welzijn</option>
                        <option value="Sieraden">Sieraden & Accessoires</option>
                        <option value="Sport">Sport, Muziek & Vrije tijd</option>
                        <option value="Eten">Eten & Drinken</option>
                        <option value="Warenhuis">Warenhuis & Speelgoed</option>

                    </select>
                    <br/><br/>
                    <Button className="main-save-button" onClick={() => this.saveSettings()}>Opslaan</Button>
                </Col>
   
            </Row>
            <hr/>
            <Row className="col-sm-12">
                <Col sm={4} className="input-colom-profile-transparant">
                    <h2>Winkeladres</h2>
                    <p>Dit adres wordt weergegeven op je facturen. Je primaire bedrijfslocatie kan van invloed zijn op welke locaties je producten getoond worden.</p>

                </Col>
                <Col sm={8} className="input-colom-profile">
                    <label>Officiële naam van bedrijf</label><br/>
                    <input type="text" value={this.state.companyNameOfficial} onChange={(event)=>this.setState({companyNameOfficial:event.target.value,changes:true})}/><br/><br/>
                    <label>KVK-nummer</label><br/>
                    <input type="text" value={this.state.kvk} onChange={(event)=>this.setState({kvk:event.target.value,changes:true})}/><br/><br/>
                    <label>BTW-nummer</label><br/>
                    <input type="text" value={this.state.btw} onChange={(event)=>this.setState({btw:event.target.value,changes:true})}/><br/><br/>
                    <label>Naam contactpersoon</label><br/>
                    <input type="text" value={this.state.name} onChange={(event)=>this.setState({name:event.target.value,changes:true})}/><br/><br/>
                    <label>E-mail contactpersoon</label><br/>
                    <input type="text" value={this.state.email} onChange={(event)=>this.setState({email:event.target.value,changes:true})}/><br/><br/>
                    
                    <label>Telefoonnummer</label><br/>
                    <input type="phone" value={this.state.phone} onChange={(event)=>this.setState({phone: event.target.value,changes:true})} /><br/><br/>
                    <label>Straat</label><br/>
                    <input type="text" value={this.state.street} onChange={(event)=>this.setState({street: event.target.value,changes:true})}/><br/><br/>
                    <Row className="input-colom-profile-50-50">
                        <Col sm={6}>
                            <label>Postcode</label><br/>
                            <input type="text" value={this.state.zip} onChange={(event)=>this.setState({zip: event.target.value,changes:true})}/><br/><br/>
                        </Col>
                        <Col sm={6}>
                            <label>Plaats</label><br/>
                            <input type="text" value={this.state.shopLocation} onChange={(event)=>this.setState({shopLocation: event.target.value,changes:true})}/><br/><br/>
                        </Col>
                    </Row>
                    <label >Provincie</label><br/>
                    <select onChange={(event)=> this.setState({provincie: event.target.value,changes:true})} value={this.state.provincie}>
                        <option>Noord-brabant</option>
                    </select><br/><br/>
                    <label>Land/Regio</label><br/>
                    <select disabled>
                        <option>Nederland</option>
                    </select><br/><br/>
                    <Button className="main-save-button" onClick={() => this.saveSettings()}>Opslaan</Button>
                </Col>
   
            </Row>
            <hr/>
            <Row className="col-sm-12 openingstijden">
                <Col sm={4} className="input-colom-profile-transparant">
                    <h2>Openingstijden</h2>
                    <p>Bepaal per dag de tijdslots dat klanten producten kunnen komen afhalen.</p>

                </Col>
                <Col sm={8} className="input-colom-profile">
                    <Row>
                        <Col sm={6} className="timeslots">
                        <label>Maandag</label><br/>
                            <ul>
                                {this.state.monday.sort((a,b)=>{return parseInt(b.order)  - parseInt(a.order);}).map((monday)=>(
                                    <li>{monday.slot} <Button onClick={()=>this.deleteMonday(monday.id)}>Verwijder</Button></li>
                                ))}

                            </ul><br/><br/>
                        </Col>
                        <Col sm={6} className="timeslots">
                        <label>Dinsdag</label><br/>
                            <ul>
                            {this.state.tuesday.sort((a,b)=>{return parseInt(a.order)  - parseInt(b.order);}).map((tuesday)=>(
                                    <li>{tuesday.slot} <Button onClick={()=>this.deleteTuesday(tuesday.id)}>Verwijder</Button></li>
                                ))}
                            </ul><br/><br/>
                        </Col>
                        <Col sm={6} className="timeslots">
                        <label>Woensdag</label><br/>
                            <ul>
                            {this.state.wednesday.sort((a,b)=>{return parseInt(a.order)  - parseInt(b.order);}).map((wednesday)=>(
                                    <li>{wednesday.slot} <Button onClick={()=>this.deleteWednesday(wednesday.id)}>Verwijder</Button></li>
                                ))}
                            </ul><br/><br/>
                        </Col>
                        <Col sm={6} className="timeslots">
                        <label>Donderdag</label><br/>
                            <ul>
                            {this.state.thursday.sort((a,b)=>{return parseInt(a.order)  - parseInt(b.order);}).map((thursday)=>(
                                    <li>{thursday.slot} <Button onClick={()=>this.deleteThursday(thursday.id)}>Verwijder</Button></li>
                                ))}
                            </ul><br/><br/>
                        </Col>
                        <Col sm={6} className="timeslots">
                        <label>Vrijdag</label><br/>
                            <ul>
                            {this.state.friday.sort((a,b)=>{return parseInt(a.order)  - parseInt(b.order);}).map((friday)=>(
                                    <li>{friday.slot} <Button onClick={()=>this.deleteFriday(friday.id)}>Verwijder</Button></li>
                                ))}
                            </ul><br/><br/>
                        </Col>
                        <Col sm={6} className="timeslots">
                        <label>Zaterdag</label><br/>
                            <ul>
                            {this.state.saturday.sort((a,b)=>{return parseInt(a.order)  - parseInt(b.order);}).map((saturday)=>(
                                    <li>{saturday.slot} <Button onClick={()=>this.deleteSaturday(saturday.id)}>Verwijder</Button></li>
                                ))}
                            </ul><br/><br/>
                        </Col>
                        <Col sm={6} className="timeslots">
                        <label>Zondag</label><br/>
                            <ul>
                            {this.state.sunday.sort((a,b)=>{return parseInt(a.order)  - parseInt(b.order);}).map((sunday)=>(
                                    <li>{sunday.slot} <Button onClick={()=>this.deleteSunday(sunday.id)}>Verwijder</Button></li>
                                ))}
                            </ul><br/><br/>
                        </Col>
   
                    </Row>
                    
                    
                    <Row className="input-colom-profile-50-50">
                        <Col sm={6}>
                            <label>Dag</label><br/>
                            <select onChange={(event)=> this.setState({timeSlotsDay: event.target.value})}>
                                <option value="selected" selected="selected" disabled="disabled">Selecteer een dag</option>
                                <option value="monday">Maandag</option>
                                <option value="tuesday">Dinsdag</option>
                                <option value="wednesday">Woensdag</option>
                                <option value="thursday">Donderdag</option>
                                <option value="friday">Vrijdag</option>
                                <option value="saturday">Zaterdag</option>
                                <option value="sunday">Zondag</option>
                            </select><br/>
                        </Col>
                        <Col sm={6}>
                            <label>Tijd</label><br/>
                            <input type="text" value={this.state.timeInfo} onChange={(event)=>this.setState({timeInfo: event.target.value})}></input>
                            
                        </Col>
                        
                            </Row>
                    <Button className="main-save-button add-button" onClick={()=>this.changeTimeSlots()}>Toevoegen</Button>
                    
                    
                </Col>
   
            </Row>
             {this.state.changes?
            <Row className="changed-row">
                <Col sm={12} className="changed-col">
                    <p>Er zijn wijzigingen aangebracht. Vergeet niet om de wijzigingen op te slaan.</p>
                    <Button  className="changed-button" onClick={()=>this.saveSettings()}>Opslaan</Button>
                </Col>
            </Row>:null}
            </div>
        );
    }
};

export default ProfileIndex;
import React, { Component } from 'react';
import { Row, Col, Card, CardHeader, CardBody, Button , Container} from "shards-react";
import Firebase from 'firebase';
import { Link } from 'react-router-dom';
import { StaticRouter } from 'react-router';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';

const images = [
    { url: require('../../assets/images/streekproductengids/logos/1.png')},
    { url: require('../../assets/images/streekproductengids/logos/2.png')},
    { url: require('../../assets/images/streekproductengids/logos/3.png')},
    { url: require('../../assets/images/streekproductengids/logos/4.png')},
    { url: require('../../assets/images/streekproductengids/logos/5.png')},
    { url: require('../../assets/images/streekproductengids/logos/6.png')},    
    { url: require('../../assets/images/streekproductengids/logos/7.png')},
    { url: require('../../assets/images/streekproductengids/logos/8.png')},
    { url: require('../../assets/images/streekproductengids/logos/9.png')},
    { url: require('../../assets/images/streekproductengids/logos/10.png')},
    { url: require('../../assets/images/streekproductengids/logos/11.png')},
    { url: require('../../assets/images/streekproductengids/logos/12.png')},
    { url: require('../../assets/images/streekproductengids/logos/13.png')},
    { url: require('../../assets/images/streekproductengids/logos/14.png')},
    { url: require('../../assets/images/streekproductengids/logos/15.png')},
    { url: require('../../assets/images/streekproductengids/logos/16.png')},    
    { url: require('../../assets/images/streekproductengids/logos/17.png')},
    { url: require('../../assets/images/streekproductengids/logos/18.png')},
    { url: require('../../assets/images/streekproductengids/logos/19.png')},
    { url: require('../../assets/images/streekproductengids/logos/20.png')},
    { url: require('../../assets/images/streekproductengids/logos/21.png')},
    { url: require('../../assets/images/streekproductengids/logos/22.png')},
    { url: require('../../assets/images/streekproductengids/logos/23.png')},
    { url: require('../../assets/images/streekproductengids/logos/24.png')},
    { url: require('../../assets/images/streekproductengids/logos/25.png')},
    { url: require('../../assets/images/streekproductengids/logos/26.png')},    
    { url: require('../../assets/images/streekproductengids/logos/27.png')},
    { url: require('../../assets/images/streekproductengids/logos/28.png')},
    { url: require('../../assets/images/streekproductengids/logos/29.png')},
    { url: require('../../assets/images/streekproductengids/logos/30.png')},
    { url: require('../../assets/images/streekproductengids/logos/31.png')},
    { url: require('../../assets/images/streekproductengids/logos/32.png')},
    { url: require('../../assets/images/streekproductengids/logos/33.png')},
    { url: require('../../assets/images/streekproductengids/logos/34.png')},
    { url: require('../../assets/images/streekproductengids/logos/35.png')},
    { url: require('../../assets/images/streekproductengids/logos/36.png')},    
    { url: require('../../assets/images/streekproductengids/logos/37.png')},
    { url: require('../../assets/images/streekproductengids/logos/38.png')},
    { url: require('../../assets/images/streekproductengids/logos/39.png')},
    { url: require('../../assets/images/streekproductengids/logos/40.png')},
    { url: require('../../assets/images/streekproductengids/logos/51.png')},
    { url: require('../../assets/images/streekproductengids/logos/52.png')},
    { url: require('../../assets/images/streekproductengids/logos/53.png')},
    { url: require('../../assets/images/streekproductengids/logos/54.png')},
    { url: require('../../assets/images/streekproductengids/logos/55.png')},
    { url: require('../../assets/images/streekproductengids/logos/56.png')},    
    { url: require('../../assets/images/streekproductengids/logos/57.png')},
    { url: require('../../assets/images/streekproductengids/logos/58.png')},
    { url: require('../../assets/images/streekproductengids/logos/59.png')},
    { url: require('../../assets/images/streekproductengids/logos/60.png')},
    { url: require('../../assets/images/streekproductengids/logos/61.png')},
    { url: require('../../assets/images/streekproductengids/logos/62.png')},
    { url: require('../../assets/images/streekproductengids/logos/63.png')},
    { url: require('../../assets/images/streekproductengids/logos/64.png')},
    { url: require('../../assets/images/streekproductengids/logos/65.png')},
    { url: require('../../assets/images/streekproductengids/logos/66.png')},    
    { url: require('../../assets/images/streekproductengids/logos/67.png')},
    { url: require('../../assets/images/streekproductengids/logos/68.png')},
    { url: require('../../assets/images/streekproductengids/logos/69.png')},
    { url: require('../../assets/images/streekproductengids/logos/70.png')},
    { url: require('../../assets/images/streekproductengids/logos/71.png')},
    { url: require('../../assets/images/streekproductengids/logos/72.png')},
    { url: require('../../assets/images/streekproductengids/logos/73.png')},
    { url: require('../../assets/images/streekproductengids/logos/74.png')},
    { url: require('../../assets/images/streekproductengids/logos/75.png')},
    { url: require('../../assets/images/streekproductengids/logos/76.png')},    
    { url: require('../../assets/images/streekproductengids/logos/77.png')},
    { url: require('../../assets/images/streekproductengids/logos/78.png')},
    { url: require('../../assets/images/streekproductengids/logos/79.png')},
    { url: require('../../assets/images/streekproductengids/logos/80.png')},
    { url: require('../../assets/images/streekproductengids/logos/81.png')},
    { url: require('../../assets/images/streekproductengids/logos/82.png')},
    { url: require('../../assets/images/streekproductengids/logos/83.png')},
    { url: require('../../assets/images/streekproductengids/logos/84.png')},
    { url: require('../../assets/images/streekproductengids/logos/85.png')},
    { url: require('../../assets/images/streekproductengids/logos/86.png')},    
    { url: require('../../assets/images/streekproductengids/logos/87.png')},
    { url: require('../../assets/images/streekproductengids/logos/88.png')},
    { url: require('../../assets/images/streekproductengids/logos/89.png')},
    { url: require('../../assets/images/streekproductengids/logos/90.png')},
    
   
   
  ];
class CarrousselComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
  images:[]
        };
    }

componentDidMount(){
    window.scrollTo(0, 0)
    for(let i =1 ; i< 91;i++){
        let url ={url: require('../../assets/images/streekproductengids/logos/'+[i]+'.png')}
        this.setState(prevState =>({
            
            images: [...prevState.images, url]
        }))
    }
}   

    render() {

        return (
        <Container >
            
             <Carousel autoPlay={true} infiniteLoop={true}>
                    {images.map((image)=>(
                        <div  className="image-slider">
                        <img src={image.url} />
                       
                        </div>
                    ))}
             
               
            </Carousel>
            

            
        
            
        </Container>

        );
    }
};

export default CarrousselComponent;
import React from "react";
import { Container, Row, Col } from "shards-react";
import AdminPanelIndex from "../../components/adminpanel";

import PageTitle from "../../components/common/PageTitle";
import ProductsIndex from "../../components/products";



const AdminPanel = () => (
  <Container fluid className="main-content-container px-4">
    <Row noGutters className="page-header py-4">
      <PageTitle title="Admin panel" subtitle="Beter bij ons" md="12" className="ml-sm-auto mr-sm-auto" />
    </Row>
    <AdminPanelIndex />
    
  </Container>
);

export default AdminPanel;

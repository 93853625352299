import React, { Component } from 'react';
import { Row, Col, Card, CardHeader, CardBody, Button, Container } from "shards-react";
import Firebase from 'firebase';
import { Link } from 'react-router-dom';
import { StaticRouter } from 'react-router';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import toast, { Toaster } from 'react-hot-toast';
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

class ParticipantNewIndex extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      nameChanger: '',
      mailChanger: '',
      phoneChanger: '',
      overlaySave: false,
      meatActive: true,
      errorForm: false,
      fishActive: false,
      milkActive: false,
      drinksActive: false,
      breadActive: false,
      patatoActive: false,
      dryActive: false,
      iceActive: false,
      item: [],
      imagesPrimaire: [],
      companyImage: [],
      secundareImages: [],
      changeData: false,
      companyName: '',
      companyAdres: '',
      companyPostal: '',
      companyContact: '',
      companyFunction: '',
      companyPhone: '',
      companyMail: '',
      companySite: '',
      mvo: '',
      desc: '',
      product: '',
      price: '',
      period: '',
      employees: '',
      promo: '',
      order: '',
      delivery: '',
      extraInfo: '',
      saving: false,
      overlay: false,
      imagesCompanyLogo: '',
      imagesCompany: '',
      imageAvailableCompany: false,
      imageAvailableCompanyLogo: false,
      imagesCompanyName: '',
      loadingComapny: false,
      handleUpload: false,
      overlayPrimaire: false,
      sendingform: false,

      imagePrimaireSet: '',
      imagesPrimaireName: '',
      loadingPrimaire: true,
      imagesSecundaireNames: [],
      imagesSecundaireTwo: [],
      category: '',
      imagesPrimaireTemp: '',
      companyImageTemp: ''

    };
  }





  componentWillMount() {
    window.scrollTo(0, 0)
    //this.loadData();

  }

  getUrl(company, name) {

    console.log(this.state.companyName)
    console.log(name)
    Firebase.storage().ref('/Streekproductengids/images/' + this.state.companyName + '/' + name).getDownloadURL().then((url) => {



      console.log(url)

      this.setState(prevState => ({

        imagesPrimaireTemp: url

      }));

    })


  }

  updateData() {
    this.setState({
      saving: true
    })
    const query = new URLSearchParams(this.props.id);
    var key = Firebase.database().ref().child('/streekproducten-temp/').push().key;

    Firebase.database().ref('/streekproducten-temp/' + key).set({
      id: key,
      companyName: this.state.companyName,
      companyAdres: this.state.companyAdres,
      companyPostal: this.state.companyPostal,
      companyContact: this.state.companyContact,
      companyFunction: this.state.companyFunction,
      companyPhone: this.state.companyPhone,
      companyMail: this.state.companyMail,
      companySite: this.state.companySite,
      mvo: this.state.mvo,
      desc: this.state.desc,
      product: this.state.product,
      price: this.state.price,
      period: this.state.period,
      employees: this.state.employees,
      promo: this.state.promo,
      order: this.state.order,
      delivery: this.state.delivery,
      extraInfo: this.state.extraInfo,
      imagesCompanyName: this.state.imagesCompanyName,
      imagesPrimaireName: this.state.imagesPrimaireName,
      category: this.state.category
    }).then(() => {
      const query = new URLSearchParams(this.props.id);
      // Firebase.database().ref('getUrlSecundaireOne').remove();
      for (let m = 0; m < this.state.imagesSecundaireNames.length; m++) {
        var keyImg = Firebase.database().ref().child('/streekproducten-temp/' + query.get('id') + '/secundaireImages').push().key;
        Firebase.database().ref('/streekproducten-temp/' + query.get('id') + '/secundaireImages/' + keyImg).set({

          id: keyImg,
          name: this.state.imagesSecundaireNames[m]
        }).then((data) => {


        }).catch((error) => {
          //error callback
          console.log('error ', error)
        })
      }
    }).then((data) => {
      toast.loading('Data wordt verwerkt, en gekeurd', {
        duration: 6000,
      })
      this.setState({
        saving: false
      })
      this.setState({
        nameChanger: '',
        mailChanger: '',
        phoneChanger: '',
        overlaySave: false,
        changeData: false,
        sendingform: false,
        errorForm: false

      })
      setTimeout(() => {
        window.location.replace('https://streekproducten-limburg.nl/')
      }, 5000)

    })
  }

  handleChangeCompanyLogo(e) {

    this.setState({
      imageCompanyLogo: e.target.files[0],
      errorMessageLogo: ''
    })

  }

  makeid(length) {
    var result = '';
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }


  handleUploadCompanyLogo(e) {

    let logoFile = e.target.files[0];
    const uniqueId = this.makeid(12);
    const { imageCompanyLogo } = this.state;
    this.setState({
      uploaded: true,
      loadingComapny: true,
      handleUpload: true
    })
    const query = new URLSearchParams(this.props.id);


    if (logoFile != null) {
      const uploadTask = Firebase.storage().ref(`Streekproductengids/images/${this.state.companyName}/${uniqueId}${logoFile.name}`).put(logoFile);
      uploadTask.on(
        'state_changed',
        snapshot => {
        },
        error => {
          console.log(error);
        },
        () => {
          Firebase.storage()
            .ref('Streekproductengids/images/' + this.state.companyName + '/')
            .child(uniqueId + logoFile.name)
            .getDownloadURL()
            .then(url => {
              toast.success('Afbeelding opgeslagen');

              this.setState(prevState => ({
                imagesCompany: url,
                imagesCompanyName: uniqueId + logoFile.name,
                imageAvailableCompanyLogo: true,
                uploading: true,
                changes: true,
                showuploadlog: false,

              }))
            }).then(() => {
              this.setState({ overlay: false })
              //  this.updateData();
              setTimeout(() => { this.getUrl(this.state.companyName, this.state.imagesCompanyName.replace(/^([^.]+)$|(\.[^.]+)$/i, '$1' + "_1920x1080" + '$2')) }, 6000);

            }).then(() => {
              console.log(this.state.companyName)
              this.setState({
                loadingComapny: true
              })


            });
        }
      );
    } else {
      this.setState({
        errorMessageLogo: 'Er is geen foto geselecteert.',
        uploaded: false,
        uploading: false
      })

    }


  }

  getCompanyImage(name) {


    Firebase.storage().ref('/Streekproductengids/images/' + this.state.companyName + '/' + name).getDownloadURL().then((url) => {





      this.setState(prevState => ({

        companyImageTemp: url

      }));

    })


  }

  handleUploadCompanyPrimaire(e) {

    let logoFile = e.target.files[0];
    const uniqueId = this.makeid(12);
    const { imageCompanyLogo } = this.state;
    this.setState({
      uploaded: true,
      loadingComapny: true,
      handleUpload: true
    })
    const query = new URLSearchParams(this.props.id);

    if (logoFile != null) {
      const uploadTask = Firebase.storage().ref(`Streekproductengids/images/${this.state.companyName}/${uniqueId}${logoFile.name}`).put(logoFile);
      uploadTask.on(
        'state_changed',
        snapshot => {
        },
        error => {
          console.log(error);
        },
        () => {
          Firebase.storage()
            .ref('Streekproductengids/images/' + this.state.companyName + '/')
            .child(uniqueId + logoFile.name)
            .getDownloadURL()
            .then(url => {
              toast.success('Afbeelding opgeslagen');

              this.setState(prevState => ({
                imagePrimaireSet: url,
                imagesPrimaireName: uniqueId + logoFile.name,
                imageAvailableCompany: true,
                uploading: true,
                changes: true,
                showuploadlog: false,

              }))
            }).then(() => {
              this.setState({ overlayPrimaire: false })
              //  this.updateData();

              setTimeout(() => { this.getCompanyImage(this.state.imagesPrimaireName.replace(/^([^.]+)$|(\.[^.]+)$/i, '$1' + "_1920x1080" + '$2')) }, 6000);
            }).then(() => {
              console.log(this.state.companyName)
              this.setState({
                loadingPrimaire: true,
              })


            });
        }
      );
    } else {
      this.setState({
        errorMessageLogo: 'Er is geen foto geselecteert.',
        uploaded: false,
        uploading: false
      })

    }


  }

  handleUploadSecundaire(e) {

    let logoFile = e.target.files[0];
    const uniqueId = this.makeid(12);
    const { imageCompanyLogo } = this.state;
    this.setState({
      uploaded: true,
      loadingComapny: true,
      handleUpload: true
    })
    const query = new URLSearchParams(this.props.id);


    if (logoFile != null) {
      const uploadTask = Firebase.storage().ref(`Streekproductengids/images/${this.state.companyName}/${uniqueId}${logoFile.name}`).put(logoFile);
      uploadTask.on(
        'state_changed',
        snapshot => {
        },
        error => {
          console.log(error);
        },
        () => {
          Firebase.storage()
            .ref('Streekproductengids/images/' + this.state.companyName + '/')
            .child(uniqueId + logoFile.name)
            .getDownloadURL()
            .then(url => {
              toast.success('Afbeelding opgeslagen');



              this.setState(prevState => ({
                imagesSecundaireTwo: [...prevState.imagesSecundaireTwo, url],
                imagesSecundaireNames: [...prevState.imagesSecundaireNames, uniqueId + logoFile.name],
                imageAvailableCompany: true,
                uploading: true,
                changes: true,
                showuploadlog: false,

              }))
            }).then(() => {

              this.uploadSecundaire();


            }).then(() => {
              console.log(this.state.companyName)
              this.setState({
                loadingPrimaire: true,
              })


            });
        }
      );
    } else {
      this.setState({
        errorMessageLogo: 'Er is geen foto geselecteert.',
        uploaded: false,
        uploading: false
      })

    }


  }

  uploadSecundaire() {
    const query = new URLSearchParams(this.props.id);
    for (let m = 0; m < this.state.imagesSecundaireNames.length; m++) {
      var keyImg = Firebase.database().ref().child('/streekproducten/' + query.get('id') + '/secundaireImages').push().key;
      Firebase.database().ref('/streekproducten/' + query.get('id') + '/secundaireImages/' + keyImg).set({

        id: keyImg,
        name: this.state.imagesSecundaireNames[m],
        order: (Number(this.state.secundareImages.length) + 1)
      }).then((data) => {
        this.setState({
          imagesSecundaireNames: []
        })


      }).catch((error) => {
        //error callback
        console.log('error ', error)
      })
    }
  }

  deleteImageSecundaires(url) {
    const query = new URLSearchParams(this.props.id);
    console.log(url)

    Firebase.database().ref('/streekproducten/' + query.get('id') + '/secundaireImages/' + url.imageId).remove().then(() => {
      this.loadData();
    });


  }

  sendForm() {
    this.setState({
      loader: true,
      sendingform: true
    })
    if (this.state.nameChanger != '' && this.state.extraInfo != '' && this.state.delivery != '' && this.state.order != '' && this.state.promo != '' && this.state.employees != '' && this.state.period != '' && this.state.price != '' && this.state.product != '' && this.state.desc != '' && this.state.mvo != '' && this.state.companySite != '' && this.state.companyMail != '' && this.state.companyPhone != '' && this.state.companyFunction != '' && this.state.mailChanger != '' && this.state.phoneChanger != '' && this.state.companyName != '' && this.state.companyPostal != '' && this.state.companyAdres != '' && this.state.companyAdres != '') {
      let headers = new Headers();
      console.log('here')
      this.updateData();
      headers.append('Content-Type', 'application/json');
      headers.append('Accept', 'application/json');
      headers.append('Origin', 'https://streekproducten-limburg.nl');
      //headers.append('Origin','https://frontend-test-bbo.web.app/')
      headers.append('Access-Control-Allow-Methods', "POST, GET, OPTIONS, DELETE, PUT")
      fetch("https://us-central1-web-app-bbo-prod.cloudfunctions.net/ContactForm", {
        method: "POST",
        headers: headers,
        body: JSON.stringify({ name: this.state.nameChanger, email: this.state.mailChanger, phone: this.state.phoneChanger })
      })
        .then(res => {
          if (res.status === 201) {

            this.setState({
              nameChanger: '',
              mailChanger: '',
              phoneChanger: '',
              overlaySave: false,
              changeData: false,
              sendingform: false,
              errorForm: false

            })


          }
        }).catch((error) => {
          console.log(error)
        });
    }
    else {
      this.setState({
        errorForm: true,
        sendingform: false
      })
    }
  }



  render() {

    return (

      <Container fluid>
        <Toaster />



        <Row className="participant-details-row">
          <Col sm={12} >
            {this.state.imageAvailableCompany ? <img src={this.state.companyImageTemp} /> : <div className="new-singip-image"><h2>Upload je omslagfoto rechtsboven in de hoek</h2></div>}
            <Button className="change-primaire-logo" onClick={() => this.setState({ overlayPrimaire: true })}><i className="fas fa-edit"></i></Button>
            <span className="logo-company">{this.state.imageAvailableCompanyLogo ? <img src={this.state.imagesPrimaireTemp} /> : <div className="new-singip-image-logo"><h2>Upload je logo </h2></div>}</span>
            <Button className="change-logo" onClick={() => this.setState({ overlay: true })}><i className="fas fa-edit"></i></Button>
          </Col>
        </Row>
        {this.state.overlayPrimaire ?
          <Row className="overlay-row">
            <Col sm={12} className="overlay-col">
              <h2>Wijzig omslagfoto</h2>
              <p>Selecteer hier je nieuwe omslagfoto!</p>
              <input type="file" accept="image/gif, image/jpeg, image/png" onChange={(event) => this.handleUploadCompanyPrimaire(event)} />


              <Button onClick={() => this.setState({ overlayPrimaire: false })} className="close-button">Bewerken sluiten</Button>
            </Col>

          </Row>


          : null}

        {this.state.overlay ?
          <Row className="overlay-row">
            <Col sm={12} className="overlay-col">
              <h2>Wijzig logo</h2>
              <p>Selecteer hier je nieuwe logo!</p>
              <input type="file" accept="image/gif, image/jpeg, image/png" onChange={(event) => this.handleUploadCompanyLogo(event)} />


              <Button onClick={() => this.setState({ overlay: false })} className="close-button">Bewerken sluiten</Button>
            </Col>

          </Row>


          : null}

        <Container className="participant-details-container">
          <Row className="change-row">
            <Col sm={12} className="change-col">

              {this.state.saving ? <div className="save-div green-background"><Button> <Loader type="ThreeDots" color="#FFFFFF" height={10} width={100} /*timeout={3000}*/ /></Button></div> : <div className="save-div green-background"><Button onClick={() => this.setState({ overlaySave: true })}>Mijn gegevens aanmelden</Button></div>}
            </Col>
          </Row>
          {this.state.overlaySave ?
            <Container fluid className="save-changes">
              <Row className="overlay-row">
                <Col sm={12} className="overlay-col">
                  <h2>Gegevens opslaan</h2>
                  <p>Je bewerkingen worden eerst gecontrolleert voordat deze worden doorgevoerd, laat hier je naam, e-mail en telefoonnummer achter zodat wij jou kunnen contacteren bij vragen.</p>
                  <form>
                    <label>Naam</label><br />
                    <input required type="text" name="name" value={this.state.nameChanger} onChange={(event) => this.setState({ nameChanger: event.target.value, errorForm: false })} />
                    <br />    <label>E-mail</label><br />
                    <input required type="text" name="mail" value={this.state.mailChanger} onChange={(event) => this.setState({ mailChanger: event.target.value, errorForm: false })} />
                    <br /> <label>Telefoon</label><br />
                    <input required type="text" name="phone" value={this.state.phoneChanger} onChange={(event) => this.setState({ phoneChanger: event.target.value, errorForm: false })} />

                    <br />
                    <label className="label-checkbox"><input type="checkbox" /> Ik ga akkoord met de <a href="https://firebasestorage.googleapis.com/v0/b/web-app-bbo-prod.appspot.com/o/Documenten%2FVerwerkersovereenkomst_Beter_Bij_Ons.pdf?alt=media&token=f37b108e-f036-4d0e-99bb-a3b2470395a2" target="_blank">privacy voorwaarden</a></label>
                  </form>
                  {this.state.errorForm ? <span className="error-form">Let op! Alle velden zijn verplicht</span> : null}
                  <Button onClick={() => this.setState({ overlaySave: false })} className="close-button">Annuleren</Button>
                  {this.state.sendingform ? <Button className="save-button"><Loader className="send-form" type="ThreeDots" color="#fff" height={10} width={100} /*timeout={3000}*/ /> </Button> : <Button onClick={() => this.sendForm()} className="save-button">Definitief opslaan</Button>}
                </Col>
              </Row>
            </Container> : null}
          <Row>

            <Col sm={12} className="recept-getting-ready green-title">
              <h2>{this.state.item.companyName}</h2>
              <ul>
                <li className="participant-list"><img src={require('../../assets/images/streekproductengids/icons/bedrijfsnaam.png')} /> <span className="title-participants-list">Naam van het bedrijf</span><span className="desc-participants-list"><input type="text" value={this.state.companyName} onChange={(event) => this.setState({ companyName: event.target.value })} /></span></li>
                <li className="participant-list"><img src={require('../../assets/images/streekproductengids/icons/adrespostcode.png')} /> <span className="title-participants-list">Adres van bedrijf</span><span className="desc-participants-list"><input type="text" value={this.state.companyAdres} onChange={(event) => this.setState({ companyAdres: event.target.value })} /></span></li>
                <li className="participant-list"><img src={require('../../assets/images/streekproductengids/icons/postcode.png')} /> <span className="title-participants-list">Postcode en plaats</span><span className="desc-participants-list"><input type="text" value={this.state.companyPostal} onChange={(event) => this.setState({ companyPostal: event.target.value })} /></span></li>
                <li className="participant-list"><img src={require('../../assets/images/streekproductengids/icons/contactpersoon.png')} /> <span className="title-participants-list">Contactpersoon</span><span className="desc-participants-list"><input type="text" value={this.state.companyContact} onChange={(event) => this.setState({ companyContact: event.target.value })} /></span></li>
                <li className="participant-list"><img src={require('../../assets/images/streekproductengids/icons/functie.png')} /> <span className="title-participants-list">Functie</span><span className="desc-participants-list"><input type="text" type="text" value={this.state.companyFunction} onChange={(event) => this.setState({ companyFunction: event.target.value })} /></span></li>
                <li className="participant-list"><img src={require('../../assets/images/streekproductengids/icons/telefoon.png')} /> <span className="title-participants-list">Telefoon</span><span className="desc-participants-list"><input type="text" type="text" value={this.state.companyPhone} onChange={(event) => this.setState({ companyPhone: event.target.value })} /></span></li>
                <li className="participant-list"><img src={require('../../assets/images/streekproductengids/icons/email.png')} /> <span className="title-participants-list">E-mail</span><span className="desc-participants-list"><input type="text" value={this.state.companyMail} onChange={(event) => this.setState({ companyMail: event.target.value })} /></span></li>
                <li className="participant-list"><img src={require('../../assets/images/streekproductengids/icons/email.png')} /> <span className="title-participants-list">Website</span><span className="desc-participants-list"><input type="text" value={this.state.companySite} onChange={(event) => this.setState({ companySite: event.target.value })} /></span></li>

                {this.state.item.companyFb || this.state.item.companyLi || this.state.item.companyIg ? <li className="participant-list participant-list-full"><img src={require('../../assets/images/streekproductengids/icons/socialmedia.png')} /> <span className="title-participants-list">Social Media</span><span className="desc-participants-list">{this.state.item.companyFb}{this.state.item.companyFb && this.state.item.companyIg ? ', ' + this.state.item.companyIg : this.state.item.companyIg} {this.state.item.companyFb && this.state.item.companyLi || this.state.item.companyIg && this.state.item.companyLi ? ', ' + this.state.item.companyLi : this.state.item.companyLi}</span></li> : null}
                <li className="participant-list participant-list-full"><img src={require('../../assets/images/streekproductengids/icons/mvoduurzaamheid.png')} /> <span className="title-participants-list">MVO / Duurzaamheid</span><span className="desc-participants-list"><CKEditor editor={ClassicEditor} data={this.state.mvo} onChange={(event, editor) => { const data = editor.getData(); this.setState({ mvo: data, changes: true }) }} /></span></li>
                <li className="participant-list participant-list-full"><img src={require('../../assets/images/streekproductengids/icons/beschrijving.png')} /> <span className="title-participants-list">Het bedrijf</span><span className="desc-participants-list">
                  <select onChange={(event) => this.setState({ category: event.target.value, changes: true })}>
                    <option selected="selected" disabled="disabled">Selecteer een categorie</option>
                    <option value="Vlees, wild gevogelte">Vlees, wild gevogelte</option>
                    <option value="Vis">Vis</option>
                    <option value="Zuivel, kaas & eieren">Zuivel, kaas & eieren</option>
                    <option value="Dranken">Dranken</option>
                    <option value="Brood & banket">Brood & banket</option>
                    <option value="Aardappelen, groente, fruit">Aardappelen, groente, fruit</option>
                    <option value="Droogwaren">Droogwaren</option>
                    <option value="Ijs">Ijs</option>

                  </select><br /><br /><CKEditor editor={ClassicEditor} data={this.state.desc} onChange={(event, editor) => { const data = editor.getData(); this.setState({ desc: data, changes: true }) }} /></span></li>
                <li className="participant-list participant-list-full"><img src={require('../../assets/images/streekproductengids/icons/product.png')} /> <span className="title-participants-list">Product</span><span className="desc-participants-list"><CKEditor editor={ClassicEditor} data={this.state.product} onChange={(event, editor) => { const data = editor.getData(); this.setState({ product: data, changes: true }) }} /></span></li>
                <li className="participant-list participant-list-full"><img src={require('../../assets/images/streekproductengids/icons/prijs.png')} /> <span className="title-participants-list">Prijs</span><span className="desc-participants-list"><input type="text" value={this.state.price} onChange={(event) => this.setState({ price: event.target.value })} /></span></li>
                <li className="participant-list participant-list-full"><img src={require('../../assets/images/streekproductengids/icons/periode.png')} /> <span className="title-participants-list">Periode</span><span className="desc-participants-list"><input type="text" value={this.state.period} onChange={(event) => this.setState({ period: event.target.value })} /></span></li>
                <li className="participant-list participant-list-full"><img src={require('../../assets/images/streekproductengids/icons/personeel.png')} /> <span className="title-participants-list">Personeel</span><span className="desc-participants-list"><input type="text" value={this.state.employees} onChange={(event) => this.setState({ employees: event.target.value })} /></span></li>
                <li className="participant-list participant-list-full"><img src={require('../../assets/images/streekproductengids/icons/promotie.png')} /> <span className="title-participants-list">Promotie</span><span className="desc-participants-list"><input type="text" value={this.state.promo} onChange={(event) => this.setState({ promo: event.target.value })} /></span></li>
                <li className="participant-list participant-list-full"><img src={require('../../assets/images/streekproductengids/icons/bestellen.png')} /> <span className="title-participants-list">Bestellen</span><span className="desc-participants-list"><input type="text" value={this.state.order} onChange={(event) => this.setState({ order: event.target.value })} /></span></li>
                <li className="participant-list participant-list-full"><img src={require('../../assets/images/streekproductengids/icons/levering.png')} /> <span className="title-participants-list">Levering</span><span className="desc-participants-list"><input type="text" value={this.state.delivery} onChange={(event) => this.setState({ delivery: event.target.value })} /></span></li>
                <li className="participant-list participant-list-full"><img src={require('../../assets/images/streekproductengids/icons/extrainfo.png')} /> <span className="title-participants-list">Extra informatie</span><span className="desc-participants-list"><input type="text" value={this.state.extraInfo} onChange={(event) => this.setState({ extraInfo: event.target.value })} /></span></li>
              </ul>
            </Col>
            <Col sm={6}>
              {this.state.secundareImages.length === 2 ? <input disabled type="file" accept="image/gif, image/jpeg, image/png" onChange={(event) => this.handleUploadCompanyPrimaire(event)} /> : <input type="file" accept="image/gif, image/jpeg, image/png" onChange={(event) => this.handleUploadCompanyPrimaire(event)} />}

              <br /><br />
            </Col>
            <Col sm={6} className="change-secundaire">
              {this.state.secundareImages.length === 2 ?
                <p className="color-red"> Je hebt {this.state.secundareImages.length} van de 2 posities in gebruik, verwijder een foto om een nieuwe toe te voegen.</p> :
                <p className="color-green"> Je hebt {this.state.secundareImages.length} van de 2 posities in gebruik, voeg een foto toe!</p>
              }

            </Col>
            <Col sm={6} className="participant-secundaire-image">
              {this.state.secundareImages.slice(0, 1).map((iamge) => (
                <span>
                  <span className="delete-image button-list button-onimage" >
                    <Button onClick={() => this.deleteImageSecundaires(iamge)}>Verwijder</Button>
                  </span>
                  <img src={iamge.url} />
                </span>
              ))}

            </Col>
            <Col sm={6} className="participant-secundaire-image">
              {this.state.secundareImages.slice(1, 2).map((iamge) => (
                <span>
                  <span className="delete-image button-list button-onimage" >
                    <Button onClick={() => this.deleteImageSecundaires(iamge)}>Verwijder</Button>
                  </span>
                  <img src={iamge.url} />
                </span>
              ))}

            </Col>

          </Row>
        </Container>




      </Container>

    );
  }
};

export default ParticipantNewIndex;
import React from "react";
import { Container, Row, Col } from "shards-react";
import ChangesIndex from "../../components/changes";
import ReviewChanges from "../../components/changes/reviewChanges";

import PageTitle from "../../components/common/PageTitle";
import OrdersIndex from "../../components/orders";



const ChangesReview = (props) => (
  <Container fluid className="main-content-container px-4">
    <Row noGutters className="page-header py-4">
      <PageTitle title="Beoordelen aanpassingen" subtitle="Beter bij ons" md="12" className="ml-sm-auto mr-sm-auto" />
    </Row>
    <ReviewChanges  data={props.location.state}/>
    
  </Container>
);

export default ChangesReview;

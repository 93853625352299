import React from "react";
import { Container, Row, Col } from "shards-react";
import AdminPanelIndex from "../../components/adminpanel";

import PageTitle from "../../components/common/PageTitle";
import FooterComponent from "../../components/Footer/FooterComponent";
import FrontendHomeComponent from "../../components/frontend";
import BlogDetailsIndex from "../../components/frontend/blogDetailsComponents";
import CarrousselComponent from "../../components/frontend/carousselComponent";
import ParticipantIndex from "../../components/frontend/participantIndex";
import ReceptIndex from "../../components/frontend/receptComponent";
import ReceptDetailsIndex from "../../components/frontend/receptsDetailsComponent";
import HeaderComponent from "../../components/Header/headerComponent";
import ProductsIndex from "../../components/products";



const CarousselView = () => (
    <div>
    
    <CarrousselComponent />
   
    
    </div>
);

export default CarousselView;

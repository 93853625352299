import React, { Component } from 'react';
import { Row, Col, Card, CardHeader, CardBody, Button , Container} from "shards-react";
import Firebase from 'firebase';
import { Link } from 'react-router-dom';
import { StaticRouter } from 'react-router';

class BlogDetailstwoIndex extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            meatActive:true,
            fishActive:false,
            milkActive:false,
            drinksActive:false,
            breadActive:false,
            patatoActive:false,
            dryActive:false,
            iceActive:false
        };
    }

componentDidMount(){
    window.scrollTo(0, 0)
}   

    render() {

        return (
        <Container fluid>
            

            <Row className="recepts-details-row">
                <Col sm={12} >
                <img src={require('../../assets/images/streekproductengids/aan-het-woord.jpg')} />
                <span className="title-recepts-detail left-title">Aan ’t woord<br/><hr/></span>
                </Col>
            </Row>

            <Container className="recept-details-container">
                <Row>
                    <Col sm={8} className="recept-item-image">
                        <h2>Eigenaresse Brouwerij de Fontein, Lonneke Brouns</h2>
                        <img src={require('../../assets/images/fontein-1.jpg')} />
                    </Col>
                    
                    <Col sm={4} className="archive-item-sidebar">
                        <h5>Ook aan ’t woord</h5>
                        <ul>
                            <Link to={{pathname:'../aan-het-woord/bert-vliex'}}><li>Bert Vliex, Programmamanager LOF</li></Link>
                            <Link to={{pathname:'../aan-het-woord/'}}><li>Paul Debey, Met fruit erop uit</li></Link>

                        </ul>
                    
        </Col>
                </Row>
                <Row>

                    <Col sm={12} className="recept-getting-ready green-title">
                         <h2> Brouwerij de Fontein</h2>
                         <p>
                         Brouwerij de Fontein is een ambachtelijke brouwerij waar sinds 2006 speciaalbieren worden geproduceerd. Deze worden op kleine schaal (1000 liter per brouwsel) gemaakt en verkocht in eigen horeca (proeflokaal) of in de regio. Tevens destilleren we op kleine schaal likeuren, gin en whiskey. De Fontein is gevestigd in een monumentaal complex in Oud-Stein dat door ons is gerestaureerd en ingericht als brouwerij en horecabedrijf (proeflokaal) met terras. 
                         </p>
                         <p>
                         De Fontein ligt in de natuur en werkt met de natuur. Rond de brouwerij liggen een moestuin, hoogstam fruitbomen, kruiden en staan er bijenkasten. Al deze ingrediënten worden op eigen terrein verwerkt tot speciaalbier, likeuren, sap etc. Er wordt waar kan gewerkt met de seizoenen. In het voorjaar wordt bijvoorbeeld een bier geproduceerd met vlierbloesem (Euleteul, de oud-Steinse benaming voor de vlierstruik). Dit bier wordt alleen gebrouwen als de bloesem bloeit en op is op. Daarna komt het volgende ingrediënt, bijvoorbeeld frambozen, waardoor de bierkaart continue wisselt. Voor onze bezoekers en afnemers was dit in het begin erg wennen. We zijn gewend alles het hele jaar door te kunnen kopen, aardbeien op het kerstmenu is geen uitzondering meer. Wij proberen een aantal van onze producten juist weer in de bijbehorende seizoenen te plaatsen.
                         </p>
                         </Col>
                         <Col sm={6} className="green-title">
                         <h3>Duurzaamheid zit in ons bedrijf gebakken</h3>
                         <p>
                         Dit zat al hoog in het vaandel tijdens onze studie aan de HAS Den Bosch en Wageningen Universiteit en dit gedachtengoed passen wij op bescheiden schaal toe. Voor een kleine onderneming is duurzaam werken altijd een uitdaging door de beperkte schaalgrootte. Het is echter niet onmogelijk, zeker niet wanneer het breed benaderd wordt.
<br/><br/>Duurzaamheid wordt vaak alleen maar geassocieerd met zo min mogelijk gebruiken van fossiele brandstoffen of water. Daar zijn wij natuurlijk ook alert op, maar wij zoeken het duurzaam werken ook in werken met korte ketens met betrekking tot de ingrediënten/eindproducten en het werken met ingrediënten in het seizoen en uit de streek, vaak letterlijk uit eigen tuin. 

                         </p>

                         <p>
                         We zoeken ook altijd naar partners in de regio waarbij we elkaar kunnen ondersteunen. Dat kan in ons geval twee kanten op. We werken waar kan met kleinschalige producenten waarbij wij weer ingrediënten of producten inkopen uit de streek. Onze producten die wij maken zetten we dan vervolgens ook vooral in de eigen streek weg.
                         </p>
                         </Col>
                         <Col sm={6}>
                         <img className="blog-image-full mobile-margin" src={require('../../assets/images/fontein-extra.jpeg')}/>
                         </Col>
                         <Col sm={12} className="recept-getting-ready green-title">
                         <h3>Toekomstplannen</h3>
                         <p>
                         De Fontein heeft toekomstplannen waarbij de cultuurhistorie van de monumentale gebouwen verbonden worden met educatie. Wat groeit hier, in welk seizoen en hoe wordt het gebruikt? Onze fruitboomgaard heeft een hoge waarde toegekend gekregen in het nieuwe Erfgoedbeleid van de gemeente Stein. Deze boomgaard willen we gaan versterken en uitbreiden.
<br/><br/>Tevens is een nieuw perceel aangekocht in de buurt van de brouwerij. Nu scharrelen daar alleen onze Tamworth varkens, maar op termijn krijgt dit terrein hopelijk een educatieve functie door de ontwikkeling van een voedselbos/pluktuin. Dit plan is nog in ontwikkeling.

                         </p>
                         <p>
                         Na de hongerwinter van 1944 wilde men nooit meer honger meemaken in Nederland. Sicco Mansholt, de toenmalige landbouwminister, mocht dat beleid waarmaken en hij deed dat op succesvolle wijze. Zelfvoorzienende landerijen/boerderijen werden een streven. Ook de innovatieve kracht in Nederland om de voedselproductie juist op te voeren was zeer succesvol. Sicco Mansholt kwam er echter al in de jaren 70/80 achter dat deze productiedrang met betrekking tot voeding te ver was doorgevoerd. Hij zag wat het deed met de natuur en riep het volk op tot matiging. De machine was echter niet meer te stoppen. Nu, jaren later, is het nog veel duidelijker geworden dat de huidige landbouwbeleid doodlopend is en dat een andere koers varen broodnodig is. Kleinschalige, zelfvoorzienende initiatieven, zoals ooit gepromoot in 1944 moeten weer terug komen in het voedsellandschap. Daar willen wij graag een vooruitstrevende rol in spelen.
                         </p>

                         <p>
                         Ons bedrijf poogt zoveel mogelijk duurzame kringlopen toe te passen in de bedrijfsvoering.<br/><br/>
Dit voorbeeld laat de kringloop van onze eigen hop zien.

                            </p>
                         <img className="blog-image-full" src={require('../../assets/images/fontein-circulairjpg.png')}/>
                         <p>
                         Hop wordt verbouwd op eigen terrein. De hopbellen worden gebruikt om bier te maken, de rest van de plant wordt tot compost gemaakt. Na het bierbrouwen blijft bostel (graanresten) over als restproduct. Onze bostel wordt gevoerd aan onze varkens. De mest van de varkens wordt samen met de plantenresten van de hopplanten gecomposteerd. Deze compost wordt weer gebruikt voor het laten groeien van nieuwe hopplanten. Het enige wat deze kringloop verlaat is bier. 
                         </p>
                         
                    </Col>

                    <Col sm={12} className="archive-item-sidebar-mobile">
                        <h5>Ook aan ’t woord</h5>
                        <ul>
                            <Link to={{pathname:'../aan-het-woord/bert-vliex'}}><li>Bert Vliex, Programmamanager LOF</li></Link>
                            <Link to={{pathname:'../aan-het-woord/'}}><li>Paul Debey, Met fruit erop uit</li></Link>
                        </ul>
                    
        </Col>

                </Row>
            </Container>

            
        
            
        </Container>

        );
    }
};

export default BlogDetailstwoIndex;